import React from "react";
import ContentLoader from "react-content-loader";
import { Card, BodyBox, Titles, Section, Line } from "../Regulares/style";
import ReactLoader from "styled-components";

export default function MyLoader({ load }) {
  function Loader() {
    var times = load;
    if (load === undefined) {
      times = 5;
    }

    var loads = [];
    var x = 0;
    while (x < times) {
      loads.push("a");
      x++;
    }

    return (
      <>
        {loads.map((obj) => (
          <Card>
            <Section style={{ transform: "translateY(-1em)" }}>
              <Titles>
                <ContentLoader speed={2} width={800} height={200}>
                  <rect x="26" y="97" rx="5" ry="5" width="440" height="10" />
                  <rect x="26 " y="116" rx="5" ry="5" width="440" height="10" />
                  <rect x="26" y="137" rx="5" ry="5" width="440" height="10" />
                  <rect x="26" y="37" rx="5" ry="5" width="600" height="40" />
                </ContentLoader>
              </Titles>
              <Titles>
                <ContentLoader speed={2} width={800} height={200}>
                  <rect x="26" y="97" rx="5" ry="5" width="440" height="10" />
                  <rect x="26 " y="116" rx="5" ry="5" width="440" height="10" />
                  <rect x="26" y="137" rx="5" ry="5" width="440" height="10" />
                  <rect x="26" y="37" rx="5" ry="5" width="600" height="40" />
                </ContentLoader>
              </Titles>
            </Section>
          </Card>
        ))}
      </>
    );
  }

  return Loader();
}

import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
import { useProjetos } from "./projetos";
import ProjetoProvider from "./projetos";
import getDates from "../utils/getDates";
const NfeContext = createContext();

const uri = process.env.REACT_APP_API_URI;

export default function NfeProvider({ children }) {
  const meses = getDates().months;
  const anos = getDates().years;

  const start = { mes: 0, ano: anos[anos.length - 1] };
  const end = { mes: 11, ano: new Date().getFullYear() };

  const [nfe, setNfe] = useState();
  const [infos, setInfos] = useState();
  const [info, setInfo] = useState();
  const [nfes, setNfes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(100);
  const [hasMore, setHasmore] = useState(true);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState();
  const [filter, setFilter] = useState({ data: { start, end } });
  const [filterStatus, setFilterStatus] = useState(false);
  const [projeto, setProjeto] = useState();
  const [status, setStatus] = useState("regular");
  const [filial, setFilial] = useState();
  const [filial_info, setFilialInfo] = useState();
  const [select, setSelect] = useState("");

  const url = process.env.REACT_APP_API_URI;
  const uri1 = "https://www.ontax.app/pro/nfes";
  const uri2 = "https://www.ontax.app/pro/getinfo";
  const filter_uri = `${url}/pro/nfes`;
  const filter_filial = `${uri}/pro/nfes-filter`;

  function useNFeSearch(projeto, query) {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${localStorage.getItem("app_token")}`,
    };

    var uri = uri1;

    if (
      query !== undefined &&
      query !== "" &&
      !isNaN(parseInt(query)) &&
      query.length < 14
    ) {
      uri = uri2;
    }
    if (status === "nfe_filter") {
      uri = uri2;
    }
    if (filial !== undefined) {
      uri = uri2;
    }
    if (filter !== undefined && select === "nfe_filter") {
      uri = filter_uri;

      if (status === "irregular") {
        filter.check = false;
      }
    }
    if (filter !== undefined && select === "filial_filter") {
      uri = filter_filial;
    }

    const requestBody = {
      filter,
      page,
      limit,
      search: query,
      filial,
    };
    useEffect(() => {
      if (nfes.length === 0) {
        setLoading(true);
      }
      let cancel;
      if (status !== "canceladas") {
        axios({
          method: "POST",
          url: `${uri}/${projeto}`,
          data: requestBody,
          cancelToken: new axios.CancelToken((c) => (cancel = c)),
        })
          .then((res) => {
            setNfes(nfes.concat(res.data.data.data));
            setInfo(res.data.info);
            setHasmore(res.data.data.data.length < 90 ? false : true);
            setLoading(false);
          })
          .catch((e) => {
            if (axios.isCancel(e)) return;
          });
        return () => cancel();
      } else {
        axios.post(`${url}/pro/canceladas/${projeto}`).then((res) => {
          setNfes(nfes.concat(res.data.data.data));
          setInfo(res.data.info);
          setHasmore(res.data.data.data.length < 90 ? false : true);
          setLoading(false);
        });
      }
    }, [filter, page, query, projeto, status, filial]);
  }

  return (
    <NfeContext.Provider
      value={{
        setNfe,
        nfe,
        infos,
        setInfos,
        info,
        setInfo,
        setPage,
        page,
        setLoading,
        loading,
        nfes,
        setNfes,
        useNFeSearch,
        query,
        setQuery,
        hasMore,
        setHasmore,
        setProjeto,
        projeto,
        setStatus,
        status,
        filter,
        setFilter,
        filterStatus,
        setFilterStatus,
        filial,
        setFilial,
        filial_info,
        setFilialInfo,
        select,
        setSelect,
      }}
    >
      {children}
    </NfeContext.Provider>
  );
}

export function useNfe() {
  const context = useContext(NfeContext);
  const {
    setNfe,
    nfe,
    infos,
    setInfos,
    info,
    setInfo,
    setPage,
    page,
    setLoading,
    loading,
    nfes,
    setNfes,
    useNFeSearch,
    query,
    setQuery,
    hasMore,
    setHasmore,
    setProjeto,
    projeto,
    setStatus,
    status,
    filter,
    setFilter,
    filterStatus,
    setFilterStatus,
    filial,
    setFilial,
    filial_info,
    setFilialInfo,
    select,
    setSelect,
  } = context;
  return {
    setNfe,
    nfe,
    infos,
    setInfos,
    info,
    setInfo,
    setPage,
    page,
    setLoading,
    loading,
    nfes,
    setNfes,
    useNFeSearch,
    query,
    setQuery,
    hasMore,
    setHasmore,
    setProjeto,
    projeto,
    setStatus,
    status,
    filter,
    setFilter,
    filterStatus,
    setFilterStatus,
    filial,
    setFilial,
    filial_info,
    setFilialInfo,
    select,
    setSelect,
  };
}

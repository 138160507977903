import "./App.css";
import axios from "axios";
import Header from "./components/Header/Header";
import Dashboard from "./components/Dashboard";
import BlackScreen from "./components/BlackScreen";
import React, { useState } from "react";
import { useEffect } from "react";
import Filter from "./components/Filter";
import User from "./components/User";
import { useUsers } from "./hooks/user";
import { useProjetos } from "./hooks/projetos";
import { useNfe } from "./hooks/nfe";
import Alerta from "../src/utils/alerta/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const uri = process.env.REACT_APP_API_URI;

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const { login, setLogin } = useUsers();
  const { setProjeto: setProjetoNfe } = useNfe();
  const { setProjeto } = useProjetos();
  const { setInfos, setUser } = useUsers();

  useEffect(() => {
    axios
      .post(
        `${uri}/auth/check`,
        {
          user: localStorage.getItem("user_id"),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setInfos(res.data);
          setLogin(true);
          setProjeto(localStorage.getItem("projeto"));
          setUser(localStorage.getItem("user_id"));
        }
      })
      .catch((e) => {
        window.location.href = process.env.REACT_APP_ONTAX;
      });
  }, [login]);

  return (
    login && (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <User />
        <Filter />
        <Alerta />
        <BlackScreen />
        <Header />
        <Dashboard />
      </>
    )
  );
}

export default App;

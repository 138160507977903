import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: calc(100% - 5em);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Block = styled.div`
  width: 98%;
  height: 10em;
  display: flex;
  margin-top: 1em;
  border-radius: 20px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1.5);
  .thumb {
    width: 10em;
    height: 100%;
    border-radius: 20px 0 0 20px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .infos {
    width: 30em;
    height: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding-left: 1em;
    gap: 0.5em;
    .inf {
        font-size: 0.8em;
        font-weight: bold;
        opacity: 0.8;
    }
  }
`;

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 600px;
  max-height: 50em;
  overflow-y: overlay;
  overflow-x: hidden;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-family: Arial, sans-serif;
  background: #ffffff;

  .header {
    width: 100%;
    height: 2.5em;
    background: rgb(79, 79, 79);
    background: linear-gradient(
      90deg,
      rgb(29, 29, 29) 0%,
      rgba(37, 37, 37, 1) 35%,
      rgba(0, 0, 0, 1) 100%
    );
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header h1 {
    font-size: 0.9em;
  }

  .logo {
    width: 100%;
    height: 4em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
    }
  }

  .title {
    width: 100%;
    height: 4em;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-size: 2.2em;
      font-weight: 500;
      opacity: 0.7;
    }
  }

  .content .image-container {
    width: 100%; /* ou a largura que desejar */
    height: 200px; /* ou a altura que desejar */
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content .image-container img {
    width: 100%; /* ou a largura que desejar */
    height: 100%; /* ou a altura que desejar */
    object-fit: cover; /* Ajusta a imagem ao tamanho do contêiner */
  }

  .saiba-mais {
    width: 100%;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
    
    a {
      width: 10em;
      height: 3em;
      font-size: 0.8em;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      border-radius: 7px;
      background-color: black;
      font-weight: 500;
    }
  }

  .text-content {
    display: flex;
    gap: 0.2em;
    flex-direction: column;
    margin: 1em 3em;
    text-decoration: none;

    p {
      font-size: 0.9em;
    }

    .description {
      opacity: 0.9;
    }
  }

  .footer {
    background-color: #f4f4f4;
    color: #666666;
    text-align: center;
    padding: 10px;
    font-size: 12px;
  }

  .btn {
    width: 100%;
    height: 4em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn button {
    width: 9em;
    height: 3em;
    border: none;
    cursor: pointer;
    background: rgb(79, 79, 79);
    background: linear-gradient(
      90deg,
      rgb(43, 43, 43) 0%,
      rgb(54, 54, 54) 35%,
      rgb(22, 22, 22) 100%
    );
    color: white;
    font-weight: 600;
  }

  .footer {
    width: 100%;
    height: 3em;
    background: linear-gradient(
      90deg,
      rgb(43, 43, 43) 0%,
      rgb(54, 54, 54) 35%,
      rgb(22, 22, 22) 100%
    );
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

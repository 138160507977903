import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
const ChatContext = createContext();

const uri = process.env.REACT_APP_API_URI;

export default function ChatProvider({ children }) {
  const [currentFrom, setCurrentFrom] = useState("");
  const [users, setUsers] = useState([]);
  const [chat, setChat] = useState();
  const [messages, setMessages] = useState([]);
  const [data, setData] = useState();
  const [chatId, setChatId] = useState();
  const [notifications, setNotifications] = useState({});
  const [miniChatId, setMiniChatId] = useState();

  return (
    <ChatContext.Provider
      value={{
        currentFrom,
        setCurrentFrom,
        users,
        setUsers,
        chat,
        setChat,
        chatId,
        setChatId,
        messages,
        setMessages,
        notifications,
        setNotifications,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}

export function useChat() {
  const context = useContext(ChatContext);
  const {
    userEmail,
    setUserEmail,
    users,
    setUsers,
    chat,
    setChat,
    chatId,
    setChatId,
    messages,
    setMessages,
    notifications,
    setNotifications,
  } = context;
  return {
    userEmail,
    setUserEmail,
    users,
    setUsers,
    chat,
    setChat,
    chatId,
    setChatId,
    messages,
    setMessages,
    notifications,
    setNotifications,
  };
}

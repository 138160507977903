import { Container } from "./style";

import { useNfe } from "../../../../../../hooks/nfe";

import { RiFilter2Fill } from "react-icons/ri";
import getDates from "../../../../../../utils/getDates";

function InfoBar() {
  const {
    info,
    loading,
    status,
    setStatus,
    setNfes,
    setLoading,
    setPage,
    setFilter,
  } = useNfe();

 

  function checkClass(str) {
    if (status === str) {
      return "btn ativ";
    }
    return "btn";
  }

  function handleChoose(str) {
    const meses = getDates().months;
    const anos = getDates().years;

    const start = { mes: 0, ano: anos[anos.length - 1] };
    const end = { mes: 11, ano: new Date().getFullYear() };
    setNfes([]);
    setLoading(true);
    setStatus(str);
    if (str === "regular") {
      setFilter({ data: { start, end } });
    }
  }

  return (
    <Container>
      <div className="infos">
        <div
          className={checkClass("regular")}
          onClick={() => handleChoose("regular")}
        >
          <div className="content">
            <div className="thumb"></div>
            <p>Todos os Documentos</p>
          </div>
        </div>

        <div
          className={`${checkClass("canceladas")} red`}
          onClick={() => handleChoose("canceladas")}
        >
          <div className="content">
            <div className="thumb"></div>
            <p>Documentos cancelados</p>
          </div>
        </div>
      </div>
      <div className="infos">
        {!loading && info !== undefined && (
          <>
            <p className="info">{info.toLocaleString()} arquivos encontrados</p>
            
          </>
        )}
      </div>
    </Container>
  );
}
export default InfoBar;

import React, { createContext, useState, useContext } from "react";

const BlackScreenContext = createContext();

export default function BlackScreenProvider({ children }) {
  const [status, setStatus] = useState("download");

  function openBlackScreen(str) {
    setStatus(str);
    document.getElementById("blackscreen").classList.toggle("ativ");
  }

  return (
    <BlackScreenContext.Provider
      value={{
        status,
        setStatus,
        openBlackScreen,
      }}
    >
      {children}
    </BlackScreenContext.Provider>
  );
}

export function useBlackScreen() {
  const context = useContext(BlackScreenContext);
  const { status, setStatus, openBlackScreen } = context;
  return {
    status,
    setStatus,
    openBlackScreen,
  };
}

import { useState, useEffect, useRef, useCallback } from "react";
import { Filter, Minidiv, OuterBox, PinBar, SearchBar } from "../../style";
import ContentLoader from "react-content-loader";

import { AiOutlineSearch } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";

import { RxCube } from "react-icons/rx";
import axios from "axios";
import { useProjetos } from "../../../../hooks/projetos";
const uri = process.env.REACT_APP_API_URI;

export default function ParticipantesBox({ setEmit, setDest, emit, dest }) {
  const { projeto } = useProjetos();
  const [option, setOption] = useState("dest");

  function switchOption(str) {
    setOption(str);
  }
  function checkClass(str) {
    if (str === option) {
      return "option ativ";
    }
    return "option";
  }

  function Display({ ide }) {
    const [participantes, setParticipantes] = useState([]);
    const [limit, setLimit] = useState(100);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [search, setSearch] = useState();

    const observer = useRef();

    const last_element = useCallback((node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (hasMore) {
            setPage((prevPageNumber) => prevPageNumber + 1);
          }
        }
      });
      if (node) observer.current.observe(node);
    }, []);

    useEffect(() => {
      
      axios
        .post(
          `${uri}/pro/participantes/${projeto}?page=${page}&limit=${limit}`,
          {
            search,
          }
        )
        .then((res) => {
          if (res.data && res.data.data && res.data.data.length < limit) {
            setHasMore(false);
          }

          var result = participantes.concat(res.data);
          setParticipantes(result);
        })
        .catch((err) => {
          console.log("erro", err);
        });
    }, [page, search]);

    function LoadItem() {
      return (
        <PinBar>
          <div className="thumb">
            <ContentLoader speed={2} width={400} height={150}>
              <circle cx="25" cy="75" r="8" />
            </ContentLoader>
          </div>
          <OuterBox>
            <ContentLoader speed={2} width={400} height={150}>
              <rect x="3" y="60" rx="5" ry="5" width="45" height="6" />

              <rect x="3" y="75" rx="5" ry="5" width="139" height="6" />
            </ContentLoader>
          </OuterBox>
        </PinBar>
      );
    }
    function Loader() {
      var load = 10;
      var times = load;
      if (load === undefined) {
        times = 5;
      }

      var loads = [];
      var x = 0;
      while (x < times) {
        loads.push("a");
        x++;
      }

      return (
        <>
          {loads.map((obj) => (
            <LoadItem />
          ))}
        </>
      );
    }

    function choosePart(obj) {
      if (option === "dest") {
        setDest(obj);
      } else {
        setEmit(obj);
      }
      setOption();
    }

    function handleSearch(ide) {
      if (ide.includes(option)) {
        var input = document.getElementById(ide);
        var value = input.value;
        setSearch(value);
        setParticipantes([]);
        setPage(1);
      }
    }

    return (
      <div className="option_display">
        <SearchBar>
          <input
            id={ide}
            placeholder="Buscar participante"
            onChange={() => handleSearch(ide)}
          />
          <AiOutlineSearch />
        </SearchBar>
        <div className="display">
          {participantes.length > 0 ? (
            participantes.map((obj) => (
              <PinBar ref={last_element} onClick={() => choosePart(obj)}>
                <div className="thumb">
                  <RxCube />
                </div>
                <OuterBox>
                  <h2>{obj.nome}</h2>
                  <p>{obj.doc}</p>
                </OuterBox>
              </PinBar>
            ))
          ) : (
            <Loader />
          )}
          {hasMore && <LoadItem />}
        </div>
      </div>
    );
  }
  function Part(obj, func) {
    function handleReset() {
      func();
    }
    return (
      <PinBar>
        <div className="thumb">
          <RxCube />
        </div>
        <OuterBox className="cnpj">
          <h2>{obj.nome}</h2>
          <p>{obj.doc}</p>
        </OuterBox>
        <div className="thumb btn" onClick={handleReset}>
          <IoMdClose />
        </div>
      </PinBar>
    );
  }

  return (
    <>
      <Minidiv className="options">
        <div
          className={checkClass("emit")}
          onClick={() => switchOption("emit")}
        >
          <p>Emitente</p>
        </div>

        {emit !== undefined && Part(emit, setEmit)}
        {option === "emit" && <Display ide="update_emit" />}

        <div
          className={checkClass("dest")}
          onClick={() => switchOption("dest")}
        >
          <p>Destinatário</p>
        </div>

        {dest !== undefined && Part(dest, setDest)}
        {option === "dest" && <Display ide="update_dest" />}
      </Minidiv>
    </>
  );
}

import React from "react";
import { Container, Block, Image, Title, Date } from "./style";
import { useUpdates } from "../../../../../../hooks/updates";
import { useEffect } from "react";
import axios from "axios";
import { VscTrash } from "react-icons/vsc";
import alerta from "../../../../../../utils/alerta";
import { useBlackScreen } from "../../../../../../hooks/blackscreen";

import { IoAddSharp } from "react-icons/io5";
import { IoMdShareAlt } from "react-icons/io";

const uri = process.env.REACT_APP_API_URI;
export default function UpdatesDisplay() {
  const { setStatus, setPost, setPosts, posts } = useUpdates();
  const { openBlackScreen } = useBlackScreen();
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URI}/pro/updates`).then((res) => {
      setPosts(res.data);
    });
  }, []);

  function handleChoose(post) {
    setPost(post);
    setStatus("update");
  }

  function deleteUpdate(ide) {
    function trigger() {
      axios.post(`${process.env.REACT_APP_API_URI}/pro/update/delete/${ide}`);
    }

    alerta(
      "Tem certeza que deseja deletar essa postagem",
      true,
      "err",
      trigger
    );
  }

  function formatDate(obj) {
    var ano = obj.split("-")[0];
    var mes = obj.split("-")[1];
    var dia = obj.split("-")[2].slice(0, 2);

    return `${dia}/${mes}/${ano}`;
  }
  function Item(obj) {
    return (
      <Block>
        <Image style={{ backgroundImage: `url(${obj.imagem})` }} />
        <Title onClick={() => handleChoose(obj)}>
          <div className="content">
            <h1>{obj.titulo}</h1>
            <p>{obj.desc}</p>
          </div>
        </Title>
        <Date>
          <p>{formatDate(obj.created_at)}</p>
          {["6441b3aa317fcc3efdddadb4"].includes(
            localStorage.getItem("user_id")
          ) && (
            <div className="btn-container">
              <div
                className="btns share"
                onClick={() => openBlackScreen("new_email")}
              >
                <IoMdShareAlt />
              </div>
              <div className="btns trash" onClick={() => deleteUpdate(obj._id)}>
                <VscTrash />
              </div>
            </div>
          )}
        </Date>
      </Block>
    );
  }

  return (
    <Container>
      {["6441b3aa317fcc3efdddadb4"].includes(
        localStorage.getItem("user_id")
      ) && (
        <div className="btn" onClick={() => openBlackScreen("new_update")}>
          <IoAddSharp />
          <p>Novo Update</p>
        </div>
      )}
      <div className="content">
        {posts.length > 0 && posts.map((obj) => <>{Item(obj)}</>)}
      </div>
    </Container>
  );
}

module.exports = (index) => {
  const colors = [
    "#46b000",
    "#2344b0",
    "#6723b0",
    "#b02359",
    "#23a0b0",
    "#9fde0d",
    "#de6b0d",
    "#0029b0",
    "#b00058",
    "#e9fa00",
    "#64cf00",
    "#64cf00",
    "#12bfde",
    "#46b000",
    "#2344b0",
    "#6723b0",
    "#b02359",
    "#23a0b0",
    "#9fde0d",
    "#de6b0d",
    "#0029b0",
    "#b00058",
    "#e9fa00",
    "#64cf00",
    "#64cf00",
    "#12bfde",
    "#46b000",
    "#2344b0",
    "#6723b0",
    "#b02359",
    "#23a0b0",
    "#9fde0d",
    "#de6b0d",
    "#0029b0",
    "#b00058",
    "#e9fa00",
    "#64cf00",
    "#64cf00",
    "#12bfde",
  ];
  if (index > colors.length) {
    return colors[parseInt(String(index).slice(0, 1))];
  } else {
    return colors[index];
  }
};

import { Container, Block } from './style.js'

export default function Sql() {
    return (
      <Container>
        <Block>
          <div className="thumb">
            <p>thumb</p>
          </div>
          <div className="infos">
            <div>
              <p className="inf">ESTABELECIMENTO:</p>
              <p></p>
            </div>
            <div>
              <p className="inf">CNPJ:</p>
              <p></p>
            </div>
            <div>
              <p className="inf">DATA DE EMISSÂO:</p>
              <p></p>
            </div>
            <div>
              <p className="inf">INFO:</p>
              <p></p>
            </div>
          </div>
          <div className="infos">
            <div>
              <p className="inf">ESTABELECIMENTO:</p>
              <p></p>
            </div>
            <div>
              <p className="inf">CNPJ:</p>
              <p></p>
            </div>
            <div>
              <p className="inf">DATA DE EMISSÂO:</p>
              <p></p>
            </div>
            <div>
              <p className="inf">INFO:</p>
              <p></p>
            </div>
          </div>
        </Block>
      </Container>
    );
   
}

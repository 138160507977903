import React, { useState, useEffect } from "react";

import { Container, Block, Users, ChatBox } from "./style";

import { BsFillChatSquareDotsFill } from "react-icons/bs";
import { MdSend } from "react-icons/md";

import send from "./sounds/send.mp3";
import receive from "./sounds/receive.mp3";

import axios from "axios";
import io from "socket.io-client";

import { useChat } from "../../../../hooks/chat";
import { useUsers } from "../../../../hooks/user";

function Admin() {
  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState({});
  const [inputMessage, setInputMessage] = useState("");

  const uri = process.env.REACT_APP_API_URI;

  const { user } = useUsers();

  const {
    users,
    setUsers,
    setChat,
    chat,
    setChatId,
    chatId,
    messages,
    setMessages,
    notifications,
    setNotifications,
  } = useChat();

  function getColor(index) {
    const colors = [
      "#46b000",
      "#2344b0",
      "#6723b0",
      "#b02359",
      "#23a0b0",
      "#9fde0d",
      "#de6b0d",
      "#0029b0",
      "#b00058",
      "#e9fa00",
      "#64cf00",
      "#64cf00",
      "#12bfde",
      "#46b000",
      "#2344b0",
      "#6723b0",
      "#b02359",
      "#23a0b0",
      "#9fde0d",
      "#de6b0d",
      "#0029b0",
      "#b00058",
      "#e9fa00",
      "#64cf00",
      "#64cf00",
      "#12bfde",
      "#46b000",
      "#2344b0",
      "#6723b0",
      "#b02359",
      "#23a0b0",
      "#9fde0d",
      "#de6b0d",
      "#0029b0",
      "#b00058",
      "#e9fa00",
      "#64cf00",
      "#64cf00",
      "#12bfde",
    ];

    if (index > colors.length) {
      return colors[parseInt(String(index).slice(0, 1))];
    } else {
      return colors[index];
    }
  }

  const sendMessage = () => {
    if (inputMessage.trim()) {
      const newMessage = {
        chatId: chat?._id,
        from: { _id: user?._id }, // Garanta que user seja definido corretamente
        message: inputMessage,
        createdAt: new Date(),
        sentByCurrentUser: true,
        tempId: Date.now(),
      };

      socket.emit("chat-message", newMessage);
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setInputMessage(""); // Limpar o campo de input após enviar
      // Tocar som de envio, se necessário
    }
  };

  function scrollToBottom() {
    const element = document.getElementById("chat-box");
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }

  function getCurrentTime(dateString) {
    if (isNaN(Date.parse(dateString))) {
      return "Data inválida";
    }

    let now = new Date(dateString);
    let hours = now.getHours();
    let minutes = now.getMinutes();

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return `${hours}:${minutes}`;
  }

  useEffect(() => {
    axios.get(`${uri}/admin/users`).then((res) => {
      if (res.status === 200) {
        setUsers(res.data);
      }
    });

    console.log("users", users);
  }, []);

  /*   useEffect(() => {
    // Garante que 'chat' e 'user' existem antes de fazer a chamada
    if (chat && chat._id && user) {
      console.log("user", user, "chat_id", chat._id);
      axios
        .get(`${uri}/pro/conversations/${chat._id}`)
        .then((response) => {
          // Se a resposta for bem-sucedida e houver dados, atualize o estado
          if (response.status === 200 && response.data) {
            const { _id, messages } = response.data;
            setChatId(_id);
            setMessages(messages);
            console.log("messages");
          } else {
            console.log("Nenhuma mensagem encontrada para este chat");
            setMessages([]);
          }
        })
        .catch((error) => {
          console.error(
            "Erro ao buscar mensagens do chat:",
            error.response ? error.response.data : error.message
          );
          setMessages([]);
        });
    } else {
      console.log(
        "O get na rota /pro/conversations não está sendo realizado porque 'chat' ou 'user' é indefinido."
      );
    }
  }, [chat, user, uri]); */

  /*  useEffect(() => {
    if (socket) {
      // Verifica se o socket foi inicializado
      socket.on("chat-message", (message) => {
        console.log(message);
        // Lógica para tratar a mensagem recebida
      });

      // Outros listeners podem ser configurados aqui

      // Limpeza: Remove os listeners quando o componente é desmontado ou se o socket mudar
      return () => {
        socket.off("chat-message");
        // Remova outros listeners aqui, se necessário
      };
    }
  }, [socket]); */

  return (
    <Container>
      <audio src="../sounds/send.ogg" id="audio" />
      <Block>
        <Users>
          <div className="header">
            <BsFillChatSquareDotsFill />
            <p>CONVERSAS</p>
          </div>
          <div className="display">
            <div className="notifications">
              {Object.entries(notifications).map(([chatId, count]) => (
                <div key={chatId} className="notification">
                  Você tem {count} nova(s) mensagem(ns) no chat {chatId}
                </div>
              ))}
            </div>
            {users.map((obj) => (
              <div
                className={`user ${obj === chat && "ativ"}`}
                onClick={() => {
                  setChat(obj);
                  console.log("mensagem", messages);
                }}
              >
                <div className="thumb">
                  <div
                    className="circle"
                    style={{ backgroundColor: getColor(users.indexOf(obj)) }}
                  >
                    <h3>{obj.nome.toUpperCase().slice(0, 1)}</h3>
                  </div>
                </div>
                <div className="user-info">
                  <p>{obj.nome}</p>
                </div>
              </div>
            ))}
          </div>
        </Users>
        {chat !== undefined && (
          <ChatBox>
            <div className="header">
              <div className="thumb">
                <div
                  className="circle"
                  style={{
                    backgroundColor: getColor(
                      users.indexOf(users.find((obj) => obj._id === chat._id))
                    ),
                  }}
                >
                  <h3>{chat.nome.toUpperCase().slice(0, 1)}</h3>
                </div>
              </div>
              <h2>{chat.nome}</h2>
            </div>
            <div className="chat" id="chat-box">
              {messages !== undefined &&
                messages.length > 0 &&
                messages.map((msg) => (
                  <div
                    key={msg._id || msg.tempId}
                    className={`chat-align ${
                      msg.from._id === user._id ? "right" : "left"
                    }`}
                  >
                    <div
                      className="message"
                      style={{
                        backgroundColor:
                          msg.from._id === user._id
                            ? "white"
                            : "rgba(230, 230, 230)",
                      }}
                    >
                      <p className="msg">{msg.message}</p>
                      <div className="time">
                        <p>{getCurrentTime(new Date(msg.createdAt))}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            <div className="send">
              <input
                id="msg"
                placeholder="Digite sua mensagem..."
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    sendMessage();
                  }
                }}
                autoComplete="off"
              />
              <div className="send-btn" onClick={sendMessage}>
                <MdSend />
              </div>
            </div>
          </ChatBox>
        )}
      </Block>
    </Container>
  );
}

export default Admin;

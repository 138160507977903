import React, { useState, useEffect } from "react";
import {
  Container,
  DropArea,
  Filter,
  Selector,
  Header,
  DropZone,
  Content,
  FilterHeader,
} from "./style";

import DownloadInfos from "./components/downloadInfos";
import SearchSelector from "./components/searchSelector";
import FilterSelector from "./components/filterSelector";

import axios from "axios";
import Dropzone, { useDropzone } from "react-dropzone";

import { useProjetos } from "../../../../hooks/projetos";

import { CiWarning } from "react-icons/ci";
import { MdCheck, MdClose } from "react-icons/md";
import { BsFillTrashFill } from "react-icons/bs";
import { useDownload } from "../../../../hooks/download";
import { useBlackScreen } from "../../../../hooks/blackscreen";

const uri = process.env.REACT_APP_API_URI;

export default function Download() {
  const {
    cnpjs,
    setCnpjs,
    filterDate,
    setFilterDate,
    type,
    setType,
    filter,
    setFilter,
    infos,
    setInfos,
  } = useDownload();

  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [date, setDate] = useState(false);
  const [search, setSearch] = useState(false);

  const checkBox = document.getElementById("check-box");
  const checkBoxDate = document.getElementById("date");

  const { projeto } = useProjetos();
  const { status } = useBlackScreen();

  function formatBytes(a, b = 2) {
    if (!+a) return "0 Bytes";
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
      ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
    }`;
  }

  function handleDelete(fileToDelete) {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
  }

  function resetFilter() {
    setFiles([]);
    setFilter("");
    setType("");
    setCnpjs([]);
    setSearch(false);
    setOpen(false);
    setDate(false);
  }

  function handleClose() {
    document.getElementById("blackscreen").classList.remove("ativ");
    setFilter("");

    resetFilter();
  }

  async function sendFile() {
    function getCnpj() {
      if (cnpjs.length !== 0) {
        return cnpjs[0].doc;
      } else {
        return undefined;
      }
    }

    const query = {
      anoInicial: parseFloat(String(filterDate.start.ano).slice(2, 4)),
      mesInicial: filterDate.start.mes + 1,
      anoFinal: parseInt(String(filterDate.end.ano).slice(2, 4)),
      mesFinal: filterDate.end.mes + 1,
      cnpjs: getCnpj(),
      nfeOp: type,
    };

    if (files.length !== 0) {
      const data = new FormData();

      for (var i = 0; i < files.length; i++) {
        const file = files[i];
        data.append("file", file);
      }

      setLoading(true);

      await axios
        .post(`${uri}/pro/nfes-upload/${projeto}`, data, {
          onUploadProgress: (e) => {
            const prog = parseInt(Math.round((e.loaded * 100) / e.total));
            setProgress(prog);
          },
        })
        .then((res) => {
          setInfos(res.data);
          setOpen(true);
        });
    }

    await axios
      .post(`${uri}/pro/nfes-zip/${projeto}`, query, {
        onUploadProgress: (e) => {
          const prog = parseInt(Math.round((e.loaded * 100) / e.total));
          setProgress(prog);
        },
      })
      .then((res) => {
        setInfos(res.data);
        setOpen(true);
      });
  }

  function fixDropBox() {
    if (filter !== "") {
      console.log("Data");
      return { height: `calc(36em - ${files.length} * 3em)` };
    } else {
      console.log("sem data");
      return { height: `calc(18em - ${files.length} * 5em)` };
    }
  }

  function DropContainer(props) {
    const { getRootProps, getInputProps, isDragActive, isDragReject } =
      useDropzone({
        accept: {
          "text/plain": [],
        },
        onDropAccepted: (obj) => {
          setFiles(files.concat(obj));
          console.log(files);
        },
      });
    return (
      <div className="drop-box">
        <DropArea
          {...getRootProps()}
          isDragActive={isDragActive}
          isDragReject={isDragReject}
          style={filter && !date ? fixDropBox() : {}}
        >
          <input {...getInputProps()} type="file" name="file" multiple />
          {renderDragMessage(isDragActive, isDragReject)}
        </DropArea>
        {files !== undefined && (
          <div className="files-container">
            <div className="files-list">
              {files.map((file) => (
                <div className="file">
                  <div className="thumb">
                    <img
                      src="https://cdn-icons-png.flaticon.com/128/2991/2991108.png"
                      alt="documento"
                    />
                  </div>
                  <div className="text">
                    <h2>{file.path}</h2>
                    <p>{formatBytes(file.size)}</p>
                  </div>
                  {progress === 0 && (
                    <div
                      className="delete-btn"
                      onClick={() => handleDelete(file)}
                    >
                      <BsFillTrashFill />
                    </div>
                  )}
                </div>
              ))}
            </div>
            {progress > 0 && (
              <div className="progress-bar">
                <div className="load" style={{ width: `${progress}%` }}></div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  function renderDragMessage(isDragActive, isDragReject) {
    if (!isDragActive) {
      return (
        <div className="text-container">
          <div className="thumb">
            <div className="circle">
              <p>+</p>
            </div>
            <div className="text">
              <h2>Carregar Lista de Certidões</h2>
              <p>Arraste aqui os arquivos que deseja carregar</p>
            </div>
          </div>
        </div>
      );
    }
    if (isDragReject) {
      return (
        <div className="text-container">
          <div className="thumb">
            <CiWarning color="rgba(200, 0, 0)" size={"25px"} />
            <div className="text">
              <h2>Formato nao suportado</h2>
              <p>O arquivo deve estar no formato ".txt"</p>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="text-container">
        <div className="thumb">
          <div className="circle" style={{ borderColor: "rgba(0, 200, 100)" }}>
            <MdCheck color="rgba(0, 200, 100)" />
          </div>
          <div className="text">
            <h2>Solte o arquivo aqui</h2>
            <p>Formato suportado</p>
          </div>
        </div>
      </div>
    );
  }

  function handleCheck(type) {
    if (type === "empresas") {
      setSearch(!search);
      if (filter === "" && files.length === 0) {
        setFilter("start");
        checkBox.classList.add("ativ");
        checkBoxDate.classList.remove("ativ");
      } else {
        setFilter("");
      }
    } else {
      if (date) {
        setDate(false);
      } else {
        setDate(true);
      }
    }
  }

  function handletype(name) {
    if (type === name) {
      setType("");
    } else {
      setType(name);
    }
  }

  useEffect(() => {
    // Limpar os estados

    resetFilter();
    // Função para realizar a chamada API
    const fetchData = async () => {
      try {
        const response = await axios.get(`${uri}/pro/nfes-zip/${projeto}`);
        if (response.data && response.data.task !== null) {
          setInfos(response.data);
          setOpen(true);
        }
      } catch (error) {
        console.error("Erro na chamada axios:", error);
        // Você pode definir um estado de erro aqui, se necessário
      }
    };
    // Chamada da função fetchData
    fetchData();
  }, [status]); // Dependências vazias significam que este efeito será executado apenas uma vez após a montagem do componente

  return (
    <Container>
      <Header>
        <div className="close" onClick={handleClose}>
          <MdClose />
        </div>
        <div className="text">
          <p>{open ? "Informações Sobre os Arquivos" : "Downloads"}</p>
        </div>
        {/* <div
              className="arrow"
              onClick={() => progress === 100 && setOpen(true)}
            >
              <AiOutlineArrowRight />
            </div> */}
      </Header>
      {open ? (
        <DownloadInfos
          setOpen={setOpen}
          setProgress={setProgress}
          progress={progress}
        />
      ) : (
        <Content>
          {search && <SearchSelector files={files} date={date} />}
          <div className="drop-filter">
            <FilterHeader>
              <div
                className="toggle-filter"
                onClick={() => handleCheck("empresas")}
              >
                <div
                  className="check-box"
                  id="check-box"
                  style={
                    filter
                      ? {
                          backgroundColor: "rgba(0, 110, 240, 0.4)",
                          border: "1px solid rgba(0, 110, 240, 0.5)",
                        }
                      : {}
                  }
                ></div>
                <div className="text">
                  <p>Filtrar empresas</p>
                </div>
              </div>
              <div
                className="toggle-filter"
                onClick={() => handleCheck("datas")}
              >
                <div
                  className="check-box"
                  id="date"
                  style={
                    date
                      ? {
                          backgroundColor: "rgba(0, 110, 240, 0.4)",
                          border: "1px solid rgba(0, 110, 240, 0.5)",
                        }
                      : {}
                  }
                ></div>
                <div className="text">
                  <p>Filtrar Datas</p>
                </div>
              </div>

              <div className="general-filter">
                <div
                  className="general-box"
                  onClick={() => type !== "" && setType("")}
                  style={
                    type !== ""
                      ? {
                          backgroundColor: "rgba(0, 110, 240, 0.4)",
                          border: "1px solid rgba(0, 110, 240, 0.5)",
                        }
                      : {}
                  }
                ></div>
                <div className="buttons">
                  <div
                    style={
                      type === "cte"
                        ? { backgroundColor: "rgb(240, 240, 240)" }
                        : {}
                    }
                    onClick={() => handletype("cte")}
                  >
                    <p>CTE</p>
                  </div>
                  <div
                    style={
                      type === "nfe"
                        ? { backgroundColor: "rgb(240, 240, 240)" }
                        : {}
                    }
                    onClick={() => handletype("nfe")}
                  >
                    <p>NFE</p>
                  </div>
                </div>
              </div>
            </FilterHeader>
            {date && <FilterSelector filter={filter} setFilter={setFilter} />}
            <DropContainer>
              <DropZone>
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragReject,
                }) => <DropContainer />}
              </DropZone>
            </DropContainer>
            <div className="send-files">
              <div
                className="btn"
                onClick={async () => await sendFile()}
                style={
                  files.length === 0 && filter === "" && !date
                    ? { opacity: "0.4", cursor: "auto", userSelect: "none" }
                    : {}
                }
              >
                <h2>ENVIAR</h2>
              </div>
            </div>
          </div>
        </Content>
      )}
    </Container>
  );
}

import { useState, useEffect } from "react";
import { UserContainer, Selector, Display } from "./style";

import InfosContent from "./components/infos/infos";
import Task from "./components/task";

import { RiCloseFill } from "react-icons/ri";
import { useUsers } from "../../hooks/user";
import { useProjetos } from "../../hooks/projetos";

const uri = process.env.REACT_APP_API_URI;
export default function User() {
  const { infos, status } = useUsers();
  const { userPage, setUserPage } = useProjetos();

  const [selector, setSelector] = useState("info");

  const stages = [
    { tipo: "info", comp: <InfosContent /> },
    { tipo: "task", comp: <Task /> },
  ];

  function checkClass(str) {
    if (str === selector) {
      return "ativ";
    }
  }

  useEffect(() => {
    if (infos !== undefined) {
      document.getElementById("user-nome").value = infos.nome;
      document.getElementById("user-email").value = infos.email;
    }
  }, [infos]);

  return (
    <UserContainer
      id="user-container"
      className={`${status !== "nfe" ? "unset" : ""} ${userPage ? "ativ" : ""}`}
    >
      {infos !== undefined && (
        <>
          <div className="header">
            <div
              className="btn"
              onClick={() => {
                setUserPage(false);
              }}
            >
              <RiCloseFill />
            </div>
            <div className="thumb">
              <div className="circle">
                <h2>{infos.nome.slice(0, 1)}</h2>
              </div>
            </div>
            <div className="content">
              <p>{infos.nome}</p>
              <p className="cnpj">{infos !== undefined && infos.cnpj}</p>
            </div>
          </div>
          <Selector>
            <div
              id="info"
              className={`box end ${checkClass("info")}`}
              onClick={() => setSelector("info")}
            >
              <p>Infos</p>
            </div>
            <div
              id="task"
              className={`box end ${checkClass("task")}`}
              onClick={() => setSelector("task")}
            >
              <p>Tarefas</p>
            </div>
          </Selector>
          <Display>{stages.find((obj) => selector === obj.tipo).comp}</Display>
        </>
      )}
    </UserContainer>
  );
}

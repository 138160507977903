import React, { useEffect } from "react";
import {
  Container,
  Header,
  Box,
  DateRangePickerContainer,
  StyledDatePicker,
  StyledDatePickerWrapper,
} from "./style";
import { useProjetos } from "../../../../hooks/projetos";
import { useState } from "react";
import { useUsers } from "../../../../hooks/user";
import axios from "axios";
import { useNfe } from "../../../../hooks/nfe";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  AreaChart,
  Area,
} from "recharts";

registerLocale("pt-BR", pt);

export default function DashBoard() {
  const date = new Date();
  const firstDayOfLastMonth = new Date(
    date.getFullYear(),
    date.getMonth() - 1,
    1
  );
  const lastDayOfLastMonth = new Date(date.getFullYear(), date.getMonth(), 0);
  const uri = process.env.REACT_APP_API_URI;
  const { projeto } = useProjetos();
  const { infos } = useUsers();
  const [filter, setFilter] = useState();
  const [startDate, setStartDate] = useState(firstDayOfLastMonth);
  const [endDate, setEndDate] = useState(lastDayOfLastMonth);
  const [donut, setDonut] = useState([]);
  const [totalValue, setTotalValue] = useState(0);
  const [reportData, setReportData] = useState([]);
  const [reportInfo, setReportInfo] = useState({});
  const [nfesInfo, setNfesInfo] = useState({});
  const [datas2, setDatas2] = useState([]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  console.log(startDate, endDate);

  const handleSearch = () => {
    const searchData = {
      startDate: startDate ? startDate.toISOString() : null,
      endDate: endDate ? endDate.toISOString() : null,
    };
    console.log(searchData);

    // Fetching Nfes and Ctes data
    axios
      .post(`${uri}/pro/dashboard/infos/cfes/${projeto}`, searchData)
      .then((response) => {
        console.log(response.data);
        if (response.data && Array.isArray(response.data.data)) {
          const data = response.data.data;
          setDonut(data);

          // Calcular totalValue
          const total = data.reduce((sum, item) => sum + item.value, 0);
          setTotalValue(total);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      })
      .catch((error) => {
        console.error("Erro na requisição:", error);
      });

    // Fetching report data
    axios
      .post(`${uri}/pro/dashboard/infos/reports/${projeto}`, searchData)
      .then((response) => {
        console.log(response.data);
        setReportInfo(response.data);
        if (response.data && Array.isArray(response.data.data)) {
          setReportData(response.data.data);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      })
      .catch((error) => {
        console.error("Erro na requisição:", error);
      });

    // Fetching Nfes per month data
    axios
      .post(`${uri}/pro/dashboard/infos/${projeto}`, searchData)
      .then((response) => {
        console.log(response.data);
        setNfesInfo(response.data);
        if (response.data && Array.isArray(response.data.data)) {
          const nfesData = response.data.data.map((item) => ({
            Mes: item.Periodo,
            Nfes: item.Total,
          }));
          setDatas2(nfesData);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      })
      .catch((error) => {
        console.error("Erro na requisição:", error);
      });
  };
  useEffect(() => {
    handleSearch();
  }, []);
  function getColor(index) {
    const colors = [
      "#46b000",
      "#2344b0",
      "#6723b0",
      "#b02359",
      "#23a0b0",
      "#9fde0d",
      "#de6b0d",
      "#0029b0",
      "#b00058",
      "#e9fa00",
      "#64cf00",
      "#12bfde",
    ];

    return colors[index % colors.length];
  }

  const variableColors = {
    Baixadas: getColor(0),
    Total: getColor(1),
    Faltantes: getColor(2),
    Canceladas: getColor(3),
  };

  const datas3 = [
    { Mes: "Jan", icms: 10000 },
    { Mes: "Fev", icms: 11000 },
    { Mes: "Mar", icms: 7503 },
  ];

  const infosHeader = {
    nfes: 70000,
    icms: 750140,
    reports: 9032,
    canceladas: 200,
  };

  return (
    <>
      <Header>
        <div className="time-header">
          <p>Selecione o período:</p>
        </div>
        <DateRangePickerContainer>
          <p>De:</p>
          <StyledDatePickerWrapper>
            <StyledDatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat="dd/MM/yyyy"
              placeholderText="Data de início"
              locale="pt-BR"
              monthsShown={1}
            />
          </StyledDatePickerWrapper>

          <p>Até:</p>
          <StyledDatePickerWrapper>
            <StyledDatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              dateFormat="dd/MM/yyyy"
              placeholderText="Data final"
              locale="pt-BR"
              monthsShown={1}
            />
          </StyledDatePickerWrapper>
        </DateRangePickerContainer>
        <div className="btn" onClick={handleSearch}>
          Buscar
        </div>
      </Header>
      <Container>
        <Box></Box>
        <div className="text">
          <p>Boa tarde, {infos.nome}</p>
        </div>
        <div className="infos">
          <div className="infos-content">
            <div className="infos-text">
              <p>Notas Capturadas</p>
            </div>
            {nfesInfo.totalNfes !== undefined && (
              <div className="infos-value">
                <p>{nfesInfo.totalNfes.toLocaleString("pt-BR")}</p>
              </div>
            )}
          </div>
          <div className="infos-content">
            <div className="infos-text">
              <p>Total de Imposto ICMS</p>
            </div>
            <div className="infos-value">
              <p>R${infosHeader.icms.toLocaleString("pt-BR")}</p>
            </div>
          </div>
          <div className="infos-content">
            <div className="infos-text">
              <p>Total de Reports</p>
            </div>
            {reportInfo.totalReports !== undefined && (
              <div className="infos-value">
                <p>{reportInfo.totalReports.toLocaleString("pt-BR")}</p>
              </div>
            )}
          </div>
          <div className="infos-content">
            <div className="infos-text">
              <p>Notas Canceladas</p>
            </div>
            {reportInfo.totalCanceledReports !== undefined && (
              <div className="infos-value">
                <p>{reportInfo.totalCanceledReports.toLocaleString("pt-BR")}</p>
              </div>
            )}
          </div>
        </div>
        <div className="charts">
          <div className="donut">
            <div className="chart-title">
              <p>Total de Nfes e Ctes</p>
            </div>
            <ResponsiveContainer width="100%" height={400}>
              <PieChart width={400} height={400}>
                <Pie
                  data={donut}
                  dataKey="value"
                  cx="50%"
                  cy="30%"
                  innerRadius={40}
                  outerRadius={80}
                  fill="#8884d8"
                  label={({ name, value }) => `${name}: ${value}`}
                >
                  {Array.isArray(donut) &&
                    donut.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={getColor(index)} />
                    ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>

          <div className="chart">
            <div className="chart-title">
              <p>Quantidade de reports no período</p>
            </div>
            <ResponsiveContainer width="100%" height="80%">
              <BarChart
                width={500}
                height={300}
                data={reportData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Periodo" />
                <YAxis />
                <Tooltip />
                <Legend
                  iconType="square"
                  formatter={(value, entry, index) => (
                    <span
                      style={{
                        color: variableColors[value],
                        marginRight: "10px",
                      }}
                    >
                      {value}
                    </span>
                  )}
                  payload={[
                    {
                      value: "Baixadas",
                      type: "square",
                      color: variableColors["Baixadas"],
                    },
                    {
                      value: "Total",
                      type: "square",
                      color: variableColors["Total"],
                    },
                    {
                      value: "Faltantes",
                      type: "square",
                      color: variableColors["Faltantes"],
                    },
                    {
                      value: "Canceladas",
                      type: "square",
                      color: variableColors["Canceladas"],
                    },
                  ]}
                />
                <Bar dataKey="Baixadas" opacity={0.7} barSize={20}>
                  {reportData.map((entry, index) => (
                    <Cell
                      key={`cell-baixadas-${index}`}
                      fill={variableColors["Baixadas"]}
                    />
                  ))}
                </Bar>
                <Bar dataKey="Total" opacity={0.7} barSize={20}>
                  {reportData.map((entry, index) => (
                    <Cell
                      key={`cell-total-${index}`}
                      fill={variableColors["Total"]}
                    />
                  ))}
                </Bar>
                <Bar dataKey="Faltantes" opacity={0.7} barSize={20}>
                  {reportData.map((entry, index) => (
                    <Cell
                      key={`cell-faltantes-${index}`}
                      fill={variableColors["Faltantes"]}
                    />
                  ))}
                </Bar>
                <Bar dataKey="Canceladas" opacity={0.7} barSize={20}>
                  {reportData.map((entry, index) => (
                    <Cell
                      key={`cell-canceladas-${index}`}
                      fill={variableColors["Canceladas"]}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>

          <div className="chart">
            <div className="chart-title">
              <p>Quantidade de notas capturadas no período</p>
            </div>
            <ResponsiveContainer width="100%" height="80%">
              <BarChart
                width={500}
                height={300}
                data={datas2}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Mes" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="Nfes" opacity={0.7} barSize={20}>
                  {datas2.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={getColor(index)} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="charts">
          <div className="donut">
            <div className="chart-title">
              <p>Porcentagem de Nfes e Ctes</p>
            </div>
            <ResponsiveContainer width="100%" height={400}>
              <PieChart width={400} height={400}>
                <Pie
                  data={donut}
                  dataKey="value"
                  cx="50%"
                  cy="30%"
                  innerRadius={40}
                  outerRadius={80}
                  fill="#8884d8"
                  label={({ name, value }) => {
                    const percentage = ((value / totalValue) * 100).toFixed(2);
                    return `${name}: ${percentage}%`;
                  }}
                >
                  {Array.isArray(donut) &&
                    donut.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={getColor(index)} />
                    ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="chart">
            <div className="chart-title">
              <p>Quantidade de imposto ICMS</p>
            </div>
            <ResponsiveContainer width="100%" height="80%">
              <BarChart
                width={500}
                height={300}
                data={datas3}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Mes" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="icms" opacity={0.7} barSize={20}>
                  {datas3.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={getColor(index)} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="chart">
            <div className="chart-title">
              <p>Quantidade de notas capturadas no período</p>
            </div>
            <ResponsiveContainer width="100%" height="80%">
              <AreaChart
                width={500}
                height={400}
                data={datas2} // Utilizando os dados do datas2
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Mes" />
                <YAxis />
                <Tooltip />
                <Area
                  type="linear"
                  dataKey="Nfes"
                  stroke={getColor(1)}
                  fill={getColor(1)}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      </Container>
    </>
  );
}

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 26;
  color: black;
  font-size: 1.1em;
`;

export const Block = styled.div`
  width: 65em;
  height: 35em;
  display: flex;
  background-color: rgba(240, 240, 240);
  border-radius: 5px;
  flex-direction: column;
  overflow: hidden;
`;

export const Header = styled.div`
  width: 100%;
  height: 2em;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(250, 250, 250);

  .close {
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &:hover {
      background-color: white;
    }

    svg {
      opacity: 0.5;
      font-size: 0.8em;
    }
  }

  .text {
    width: calc(100% - 2em);
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-size: 0.8em;
      font-weight: 600;
      opacity: 0.6;
      transform: translateX(-1.8em);
    }
  }
`;

export const Display = styled.div`
  width: 100%;
  height: calc(100% - 2em);
  flex-direction: column;
  overflow: hidden;

  .thumb {
    width: 2.4em;
    height: 2.4em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    svg {
      font-size: 0.8em;
      opacity: 0.8;
    }
  }

  .info {
    width: 30em;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: 1em !important;
    p {
      font-size: 0.7em;
      line-height: 15px;

      &.cnpj {
        font-size: 0.7em;
        opacity: 0.7;
      }
    }
  }

  .data {
    width: calc(calc(100% - 30em) / 4);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    p {
      font-size: 0.7em;
    }
  }

  .head {
    width: 100%;
    height: 1.2em;
    display: flex;
    background-color: rgba(230, 230, 230);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .data {
      width: calc(calc(100% - 30em) / 4);

      p {
        font-size: 0.6em;
        opacity: 0.7;
        margin-left: 1em !important;
      }

      &.empresa {
        justify-content: flex-start;
        width: 31.2em;
      }
    }
  }

  .display {
    width: 100%;
    height: calc(100% - 1.2em);
    display: flex;
    flex-direction: column;
    overflow: overlay;
    overflow-x: hidden;

    .item {
      width: 100%;
      height: 2.4em;
      display: flex;
      margin: 1px 0 !important;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background-color: white;
      opacity: 0.7;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
  }

  .info-box {
    width: 100%;
    height: 0em;
    min-height: 0em;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    transition: all 0.2s;

    &.ativ {
      height: 20em;
      min-height: 20em;
    }

    .item-content {
      width: calc(calc(100% - 30em) / 4);
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      &.thumb {
        width: 1.8em;
      }

      &.arquivo {
        width: 30em;
      }

      p {
        font-size: 0.6em;
        opacity: 0.7;
        margin-left: 1em !important;
      }
    }

    .info-header {
      width: 100%;
      height: 1em;
      display: flex;
      background-color: rgba(230, 230, 230);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .info-display {
      width: 100%;
      height: calc(100% - 1em);
      display: flex;
      flex-direction: column;
      overflow: overlay;
      overflow-x: hidden;

      .info-item {
        width: 100%;
        height: 1.8em;
        display: flex;
        background-color: white;
        margin: 1px 0 !important;

        &.death {
          opacity: 0.5;
        }

        .thumb-info {
          width: 1.8em;
          height: 1.8em;
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 1px solid rgba(0, 0, 0, 0.1);

          svg {
            font-size: 0.7em;
            opacity: 0.6;
          }
        }

        .item-content {
          display: flex;
          align-items: center;
          justify-content: center;
          h2 {
            font-size: 0.7em;
            font-weight: 400;
          }
        }
      }
    }
  }
`;

import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
import alerta from "../utils/alerta";
const UserContext = createContext();

const uri = process.env.REACT_APP_API_URI;

export default function UserProvider({ children }) {
  const [user, setUser] = useState();
  const [infos, setInfos] = useState();
  const [login, setLogin] = useState(false);
  const [status, setStatus] = useState("nfe");

  function Logout() {
    setLogin();
    setUser();
    localStorage.clear();
    window.location.reload();
  }
  function editUser(user, func) {
    axios
      .post(`${uri}/admin/edituser`, user)
      .then((res) => {
        if (res.status === 200) {
          alerta(res.data.msg, false, "success", () => {});
          if (func) {
            func();
          }
        }
      })
      .catch((e) => {
        alerta(e, false, "err", () => {});
      });
  }

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        infos,
        setInfos,
        setLogin,
        login,
        status,
        setStatus,
        Logout,
        editUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUsers() {
  const context = useContext(UserContext);
  const {
    setUser,
    user,
    infos,
    setInfos,
    setLogin,
    login,
    status,
    setStatus,
    Logout,
    editUser,
  } = context;
  return {
    user,
    setUser,
    infos,
    setInfos,
    setLogin,
    login,
    status,
    setStatus,
    Logout,
    editUser,
  };
}

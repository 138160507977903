import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
const UpdateContext = createContext();

const uri = process.env.REACT_APP_API_URI;

export default function UpdateProvider({ children }) {
  const [status, setStatus] = useState("updates");
  const [post, setPost] = useState();
  const [posts, setPosts] = useState([])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URI}/pro/updates`).then((res) => {
      setPosts(res.data);
    });
  }, []);

  return (
    <UpdateContext.Provider
      value={{
        status,
        setStatus,
        post,
        setPost,
        posts,
        setPosts,
      }}
    >
      {children}
    </UpdateContext.Provider>
  );
}

export function useUpdates() {
  const context = useContext(UpdateContext);
  const { status, setStatus, post, setPost, posts, setPosts } = context;
  

  return {
    status,
    setStatus,
    post,
    setPost,
    posts,
    setPosts,
  };
}

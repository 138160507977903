import React from "react";
import { Container, Header, Table, TableContainer, HeaderInfo } from "./style";
import { BsCalendar, BsEye, BsSearch } from "react-icons/bs";
import { HiOutlineReceiptTax, HiDownload } from "react-icons/hi";
import { AiOutlineInfoCircle, AiOutlineIdcard } from "react-icons/ai";
import { TbReportSearch } from "react-icons/tb";
import { GiCircle, GiPlainCircle } from "react-icons/gi";
import { useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { useProjetos } from "../../../../hooks/projetos";
import { useState } from "react";
import { useUsers } from "../../../../hooks/user";
import { useNfe } from "../../../../hooks/nfe";
import { useBlackScreen } from "../../../../hooks/blackscreen";
import ContentLoader from "react-content-loader";
import { IoCloudDoneSharp } from "react-icons/io5";
import { IoCloudDoneOutline } from "react-icons/io5";

const uri = process.env.REACT_APP_API_URI;

export default function Report() {
  const { projeto } = useProjetos();
  const [reports, setReports] = useState([]);
  const [infos, setInfos] = useState([]);
  const { setStatus } = useUsers();
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [filter, setFilter] = useState("todas");
  const [type, setType] = useState("todas");
  const [filterStatus, setFilterStatus] = useState("");
  const [update, setUpdate] = useState(true);
  const [query, setQuery] = useState();
  const [info, setInfo] = useState([]);
  const [headerLoading, setHeaderLoading] = useState(true);

  const { setNfes, nfes, setNfe, filial } = useNfe();
  const { openBlackScreen } = useBlackScreen();

  const observer = useRef();

  const last_element = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (hasMore) {
          setUpdate(true);
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      }
    });
    if (node) observer.current.observe(node);
  }, []);
  useEffect(() => {
    axios.get(`${uri}/pro/reports/infos/${projeto}`).then((res) => {
      setInfo(res.data);
    });
  }, [projeto]);

  useEffect(() => {
    setPage(1);
    setHasMore(true);
    setReports([]);
    setUpdate(true);
  }, [filter, type]);

  useEffect(() => {
    if (filial !== undefined) {
      setHeaderLoading(true);
      axios
        .post(`${uri}/pro/reports-info/${projeto}`, {
          page,
          limit,
          filter,
          type,
          query,
          filial,
        })
        .then((res) => {
          setUpdate(false);
          setReports(res.data.data.data);
          setInfos(res.data.infos);
          setHeaderLoading(false);
        });
    } else {
      if (update) {
        setHeaderLoading(true);
        axios
          .post(`${uri}/pro/reports-info/${projeto}`, {
            page,
            limit,
            filter,
            type,
            query,
          })
          .then((res) => {
            setUpdate(false);
            setReports(reports.concat(res.data.data.data));
            setInfos(res.data.infos);
            setHeaderLoading(false);
          });
      }
    }
  }, [projeto, page, update, query, filial, filter, type]);

  function handleSearch(str) {
    setPage(1);
    setUpdate(true);
    console.log("str", str);
    if (str === "") {
      setQuery();
    } else {
      setReports([]);
      setQuery(str);
    }
  }

  function checkClass(hook, str) {
    if (hook === str) {
      return `${str} ativ`;
    }
    return str;
  }

  function handleChoose(obj) {
    console.log(obj);
    var check = obj.check;
    if (check) {
      openBlackScreen("nfe");
      setNfe(obj.nfe);
    }
    console.log("obj", obj);
  }

  function HeaderLoader() {
    console.log("renderizou o loader");
    return (
      <div className="infobar">
        {Array.from({ length: 4 }, (_, i) => (
          <div className="box-info one" key={i}>
            <div className="head"></div>
            <div className="info">
              <ContentLoader
                speed={2}
                width={150}
                height={10}
                viewBox="0 0 200 20"
              >
                <rect x="10" y="0" rx="5" ry="5" width="200" height="20" />
              </ContentLoader>
            </div>
          </div>
        ))}
      </div>
    );
  }

  function Loader() {
    return (
      <>
        {Array.from({ length: 15 }, (_, i) => (
          <div className="items" key={i}>
            <ContentLoader speed={2} width={1550} height={27}>
              <rect x="60" y="15" rx="5" ry="5" width="155" height="10" />
              <rect x="300" y="15" rx="5" ry="5" width="150" height="10" />
              <rect x="550" y="15" rx="5" ry="5" width="250" height="10" />
              <rect x="1100" y="15" rx="5" ry="5" width="110" height="10" />
              <rect x="1350" y="15" rx="5" ry="5" width="130" height="10" />
            </ContentLoader>
          </div>
        ))}
      </>
    );
  }

  useEffect(() => {
    console.log("info", info);
    console.log("header", headerLoading);
  }, []);

  return (
    <>
      <HeaderInfo id="test">
        {info.length === 0 ? (
          <HeaderLoader />
        ) : (
          <div className="infobar">
            <div className="box-info one">
              <div className="head">
                <p>TOTAL</p>
              </div>
              <div className="info">
                <p>{info.total}</p>
              </div>
            </div>

            <div className="box-info">
              <div className="head">
                <p>BAIXADAS</p>
              </div>
              <div className="info">
                <p>{info.baixadas}</p>
              </div>
            </div>
            <div className="box-info">
              <div className="head">
                <p>FALTANTES</p>
              </div>
              <div className="info">
                <p>{info.faltantes}</p>
              </div>
            </div>
            <div className="box-info">
              <div className="head">
                <p>CANCELADAS</p>
              </div>
              <div className="info">
                <p> {info.canceladas}</p>
              </div>
            </div>
          </div>
        )}
      </HeaderInfo>
      <Container>
        <TableContainer>
          <Table>
            <Header>
              <div className="head-btns">
                <div
                  className={`head-btn ${checkClass(filter, "todas")}`}
                  onClick={() => setFilter("todas")}
                >
                  <p>Todas</p>
                </div>
                <div
                  className={`head-btn green ${checkClass(
                    filter,
                    "regulares"
                  )}`}
                  onClick={() => setFilter("regulares")}
                >
                  <p>Regulares</p>
                </div>
                <div
                  className={`head-btn red ${checkClass(filter, "canceladas")}`}
                  onClick={() => setFilter("canceladas")}
                >
                  <p>Canceladas</p>
                </div>
              </div>
              <div className="filter-header">
                <div
                  id="todas"
                  className={checkClass(type, "todas")}
                  onClick={() => setType("todas")}
                >
                  <p>Todas</p>
                </div>
                <div
                  id="nfe"
                  className={checkClass(type, "nfe")}
                  onClick={() => setType("nfe")}
                >
                  <p>NFe's</p>
                </div>
                <div
                  id="cte"
                  className={checkClass(type, "cte")}
                  onClick={() => setType("cte")}
                >
                  <p>CTe's</p>
                </div>
              </div>
              <div className="input-header">
                <div className="btn" onClick={() => openBlackScreen("report")}>
                  <TbReportSearch />
                </div>
                <div className="btn" onClick={() => openBlackScreen("via_nuvem")}>
                <IoCloudDoneOutline />


                </div>

                <input
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder="Digite a chave do documento"
                />
                <div className="search-btn">
                  <BsSearch />
                </div>
              </div>
            </Header>
            <div className="head">
              <div className="item ico">
                <GiCircle />
              </div>

              <div className="item data">
                <BsCalendar />
                <p>Data de emissão</p>
              </div>
              <div className="item data">
                <AiOutlineIdcard />
                <p>CNPJ</p>
              </div>
              <div className="item nfe">
                <HiOutlineReceiptTax />
                <p>Chave do documento</p>
              </div>
              <div className="item data">
                <AiOutlineInfoCircle />
                <p>Status do documento</p>
              </div>
              <div className="item data">
                <BsEye />
                <p>Manifestação</p>
              </div>
            </div>
            <div className="display">
              {reports.length === 0 ? (
                <Loader />
              ) : (
                <>
                  {reports.map((obj) => (
                    <div
                      className="items"
                      ref={last_element}
                      onClick={() => handleChoose(obj)}
                    >
                      <div className="item ico">
                        {obj.check !== false ? (
                          <GiPlainCircle color="rgba(0, 200, 50)" />
                        ) : (
                          <GiPlainCircle style={{ opacity: 0.5 }} />
                        )}
                      </div>
                      <div className="item data">
                        <p>
                          {new Date(obj.dhEvento).toLocaleString("pt-BR", {
                            timeZone: "America/Sao_Paulo",
                            dateStyle: "short",
                            timeStyle: "medium",
                          })}
                        </p>
                      </div>

                      <div className="item data">
                        <p>
                          {obj.cnpj === undefined
                            ? projeto === "639903d1e193693c0d03e01f"
                              ? "71444475000115"
                              : obj.cnpj
                            : obj.cnpj}
                        </p>
                      </div>
                      <div className="item nfe">
                        <p>{obj.nfe}</p>
                      </div>

                      <div className="item data">
                        <p>{obj.status}</p>
                      </div>

                      <div className="item data">
                        <p>{obj.manifest}</p>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
}

import { useEffect, useState } from "react";
import { Container, WindowContent, Black } from "./style";
import { IoIosClose } from "react-icons/io";
import { useBlackScreen } from "../../../../hooks/blackscreen";
import { ToastContainer, toast } from "react-toastify";

import Template from "./components/template";

import { IoReload } from "react-icons/io5";
import { FaCaretRight } from "react-icons/fa";

import axios from "axios";

import alerta from "../../../../utils/alerta";

const uri = process.env.REACT_APP_API_URI;

export default function NewEmail() {
  const { openBlackScreen } = useBlackScreen();

  const [post, setPost] = useState({
    title: "",
    picture: "",
    atualizacao: "",
    description: "",
    to: "",
    link: "",
  });

  const [open, setOpen] = useState(false);
  const [test, setTest] = useState(false);
  const [confirm, setConfirm] = useState(true);

  function validateFields(data) {
    // Check if any of the fields are empty
    for (let key in data) {
      if (data[key].trim() === "") {
        return false;
      }
    }
    return true;
  }

  async function createEmail() {
    if (!validateFields(post)) {
      toast.error("Por favor, preencha todos os campos.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    try {
      console.log("Sending email with data:", post); // Debug: Print the post object
      const response = await axios.post(`${uri}/pro/email`, post);
      console.log(response.data);
    } catch (error) {
      console.error("Error creating email:", error);
      setOpen(false);
    }
    setConfirm(false);
  }

  async function sendTestEmail() {
    var inputEmail = document.getElementById("update_to").value; // Get the value, not the element

    if (!validateFields(post)) {
      toast.error("Por favor, preencha todos os campos.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const { title, picture, atualizacao, description, link } = post;

    try {
      const response = await axios.post(`${uri}/pro/email/test`, {
        title,
        picture,
        atualizacao,
        description,
        link,
        to: inputEmail,
      });
      console.log(response.data);
    } catch (error) {
      console.error("Error sending a test email:", error);
      setOpen(false);
    }
    setTest(false);
  }

  useEffect(() => {
    setOpen(false);
    setConfirm(false);
    setTest(false);
  }, []);

  return (
    <Container className="modal">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="head">
        <div className="btn" onClick={() => openBlackScreen("new_update")}>
          <IoIosClose />
        </div>
        <div className="head-info">
          <h2>ENVIAR TEMPLATE</h2>
        </div>
        {open && (
          <div className="btn" onClick={() => setTest(true)}>
            <FaCaretRight />
          </div>
        )}
      </div>
      {open ? (
        <>
          {" "}
          <Template
            title={post.title}
            atualizacao={post.atualizacao}
            post={post.description}
            picture={post.picture}
            link={post.link}
          ></Template>{" "}
          <div className="template-btn">
            <div
              className="btn-confirm"
              onClick={() => {
                setConfirm(true);
              }}
            >
              <p>CONFIRMAR ENVIO</p>
            </div>
            <div
              className="reload"
              onClick={() => {
                setOpen(false);
                setPost("");
              }}
            >
              <IoReload />
            </div>
          </div>{" "}
          {confirm && (
            <>
              <Black></Black>
              <WindowContent>
                <div className="header">
                  <div className="btn" onClick={() => setConfirm(false)}>
                    <IoIosClose />
                  </div>
                </div>
                <div className="content">
                  <div className="description">
                    <p>Confirma o envio do e-mail para a lista de usuários?</p>
                  </div>
                  <div className="btn-container">
                    <div className="btn" onClick={createEmail}>
                      <p>ENVIAR</p>
                    </div>
                  </div>
                </div>
              </WindowContent>
            </>
          )}
        </>
      ) : (
        <>
          <div className="infos">
            <div className="info">
              <div className="content">
                <div className="tag">
                  <p>TÍTULO DA ATUALIZAÇÃO</p>
                </div>
                <div className="input-container">
                  <input
                    id="update_title"
                    value={post.title}
                    onChange={(e) =>
                      setPost({ ...post, title: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="info">
              <div className="content">
                <div className="tag">
                  <p>LINK DA IMAGEM</p>
                </div>
                <div className="input-container">
                  <input
                    id="update_pic"
                    value={post.picture}
                    onChange={(e) =>
                      setPost({ ...post, picture: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="info">
              <div className="content">
                <div className="tag">
                  <p>LINK DA PÁGINA</p>
                </div>
                <div className="input-container">
                  <input
                    id="update_atualizacao"
                    value={post.atualizacao}
                    onChange={(e) =>
                      setPost({ ...post, atualizacao: e.target.value })
                    } // Update link state on change
                  />
                </div>
              </div>
            </div>
            <div className="info">
              <div className="content">
                <div className="tag">
                  <p>LINK DO VÍDEO</p>
                </div>
                <div className="input-container">
                  <input
                    id="update_link"
                    value={post.link}
                    onChange={(e) => setPost({ ...post, link: e.target.value })} // Update link state on change
                  />
                </div>
              </div>
            </div>
            <div className="info">
              <div className="content">
                <div className="tag">
                  <p>DIGITE O CONTEÚDO INSERIDO NO TEMPLATE</p>
                </div>
                <div className="input-container">
                  <textarea
                    id="update_content"
                    value={post.description}
                    onChange={(e) =>
                      setPost({ ...post, description: e.target.value })
                    } // Update post state on change
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="send">
            <div className="btn" onClick={() => setOpen(true)}>
              <h2>CRIAR POSTAGEM</h2>
            </div>
          </div>
        </>
      )}

      {test && (
        <>
          <Black></Black>
          <WindowContent>
            <div className="header">
              <div className="btn" onClick={() => setTest(false)}>
                <IoIosClose />
              </div>
            </div>
            <div className="content">
              <div className="description">
                <p>Insira seu e-mail para receber um exemplo:</p>
              </div>
              <input id="update_to" />
              <div className="btn-container">
                <div className="btn" onClick={() => sendTestEmail()}>
                  <p>ENVIAR</p>
                </div>
              </div>
            </div>
          </WindowContent>
        </>
      )}
    </Container>
  );
}

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 3em);
  display: flex;
  flex-direction: column;
`;
export const HeaderInfo = styled.div`
  position: absolute;
  right: 13em;
  top: 0em;
  height: 3em;
  width: 30em;
  display: flex;
  flex-direction: column;
  z-index: 25;

  .infobar {
    display: flex;
    justify-content: center;

    align-content: center;
    height: 100%;

    .box-info {
      flex-direction: column;

      justify-content: center;
      align-content: center;
      align-items: center;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      height: 100%;
      display: flex;
      width: 25%;
      &.one {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
      }

      .info {
        height: 65%;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
        width: 100%;
        display: flex;
        background-color: rgba(250, 250, 250);
        p {
          margin-left: 0.7em;
          font-size: 0.85em;
          opacity: 0.8;
        }
      }
      .head {
        background-color: white;
        height: 35%;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
        width: 100%;

        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        p {
          opacity: 0.7;
          margin-left: 0.5em;
          font-size: 0.6em;
          font-weight: 600;
        }
      }
    }
  }
`;
export const Header = styled.div`
  width: 100%;
  height: 2.5em;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  justify-content: space-between;

  .head-btns {
    width: max-content;
    height: 100%;
    display: flex;

    .head-btn {
      width: 10em;
      height: calc(100% - 2px);
      border-bottom: 2px solid rgba(0, 150, 200);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
      opacity: 0.5;
      &:hover,
      &.ativ {
        opacity: 1;
      }
      &.green {
        border-bottom: 2px solid green;
      }
      &.red {
        border-bottom: 2px solid red;
      }

      p {
        padding: 0 85px;
        font-size: 0.8em;
      }
    }
  }

  .filter-header {
    width: max-content;
    height: 100%;
    display: flex;
    align-items: center;

    > div {
      width: 8em;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      border-right: 1px solid rgba(0, 0, 0, 0.1);

      cursor: pointer;
      opacity: 0.6;
      border-left: 1px solid rgba(0, 0, 0, 0.1);

      &.ativ {
        opacity: 1;
        background-color: white;
        border-bottom: 2px solid rgba(0, 150, 200);
      }

      p {
        font-size: 0.85em;
      }
    }
  }

  .input-header {
    width: max-content;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(240, 240, 240);

    .btn {
      width: 2em;
      height: 2em;
      display: flex;
      border: 1px solid rgba(0, 0, 0, 0.1);
      margin-right: 0.5em;
      cursor: pointer;
      opacity: 0.5;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      &:hover {
        opacity: 1;
      }
    }
    .search-btn {
      width: 2em;
      height: 2em;
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      background-color: white;
      font-size: 0.8em;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-left: none;
    }
    input {
      width: 20em;
      height: 2em;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-right: none;
    }
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Table = styled.div`
  width: max-content;
  height: 90%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  .head {
    width: 100%;
    height: 2em;
    display: flex;
    background-color: rgba(255, 255, 255, 0.7);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    p,
    svg {
      opacity: 0.8;
    }
  }

  .item {
    width: max-content;
    height: 100%;
    display: flex;
    align-items: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    &.ico {
      width: 3em;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin: 0;
        font-size: 0.8em;
      }
    }

    &.data {
      width: 15em;
    }

    &.nfe {
      width: 35em;
    }

    svg {
      margin-left: 1em;
    }

    p {
      margin-left: 0.5em;
      font-size: 0.9em;
    }
  }

  .display {
    width: 100%;
    height: calc(100% - 2em);
    overflow: overlay;

    .items {
      width: 100%;
      height: 3em;
      background-color: white;
      display: flex;
      opacity: 0.7;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;

      p {
        margin-left: 1em;
      }

      &:hover {
        opacity: 1;
        border-bottom: 1px solid rgba(0, 150, 200);
      }
    }
  }
`;

import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
import { useProjetos } from "./projetos";

const FilesContext = createContext();

const uri = process.env.REACT_APP_API_URI;

export default function FilesProvider({ children }) {
  const [files, setFiles] = useState([]);

  const { projeto } = useProjetos();

  function getFiles() {
    if (projeto !== undefined) {
      axios.get(`${uri}/pro/reports/zip-files/${projeto}`).then((res) => {
        setFiles(res.data);
      });
    }
  }

  useEffect(() => {
    getFiles();
  }, [projeto]);

  return (
    <FilesContext.Provider value={{ files, getFiles }}>
      {children}
    </FilesContext.Provider>
  );
}

export function useFiles() {
  const context = useContext(FilesContext);
  const { files, getFiles } = context;
  return { files, getFiles };
}

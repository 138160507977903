import { useEffect } from "react";
import { Container, Danfe } from "./style";
import { IoIosClose } from "react-icons/io";
import { useNfe } from "../../../../hooks/nfe";
import Loading from "../../../Dashboard/components/Loading";
import { useState } from "react";
import axios from "axios";
import { useProjetos } from "../../../../hooks/projetos";



const uri = process.env.REACT_APP_API_URI;

export default function Filtro() {
  const { nfe, infos } = useNfe();
  const { projeto } = useProjetos();
  const [loading, setLoading] = useState(true);
  const [embed, setEmbed] = useState("");
  useEffect(() => {
    if (nfe !== undefined) {

      setLoading(true);

      axios.get(`${uri}/pro/danfe/${projeto}/${nfe}`).then((res) => {

        setLoading(false)
        setEmbed(res.data)
        
      });
    }
  }, [nfe]);

  function handleClose() {
    document.getElementById("blackscreen").classList.remove("ativ");
  }


  return (
    <Container className="modal">
      <div className="head">
        <div className="btn">
          <IoIosClose className="modalclose" onClick={handleClose} />
        </div>
      </div>
      {loading ? (
        <div className="loader">
          <Loading height={"5"} />
        </div>
      ) : (
        <embed src={`data:application/pdf;base64,${embed}`}></embed>
      )}
    </Container>
  );
}

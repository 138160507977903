import styled from "styled-components";

export const Container = styled.div`
  width: 77em;
  height: 80%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  embed {
    width: 100%;
    height: 100%;
  }

  p {
    line-heigth: 5px;
  }

  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;

  .modalclose {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    width: 3rem;
    height: 3rem;
  }
  .loader {
    width: 100%;
    height: calc(100% - 2em);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .head {
    height: 2em;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    width: 100%;

    .btn {
      width: 2em;
      height: 2em;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      overflow: hidden;
      cursor: pointer;
      svg {
        font-size: 0.8em;
      }
    }

    .head-info {
      width: calc(100% - 3em);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .all-info {
    display: flex;
    width: 100%;

    .info {
      width: 40%;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;

      &.bigger {
        width: 60%;
      }

      .line {
        width: 100%;
        height: 2em;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        p {
          margin-left: 0.5em;
        }

        h3 {
          font-size: 0.8em;
          margin-left: 0.5em;
        }

        .thumb {
          width: 2em;
          height: 2em;
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 1px solid rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
`;

import React from "react";
import { useEffect, useState, useContext, useRef, useCallback } from "react";
import { CardContainer, Card, Header, Content, Head, Infos } from "./style";
import axios from "axios";
import img from "../../../Dashboard/components/Loading/image.png";
import { BsCloudDownload } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import FileDownload from "js-file-download";
import { useNfe } from "../../../../hooks/nfe";
import { useBlackScreen } from "../../../../hooks/blackscreen";
import { useProjetos } from "../../../../hooks/projetos";
import ContentLoader from "react-content-loader";

const uri = process.env.REACT_APP_API_URI;

export default function NFe() {
  const { openBlackScreen } = useBlackScreen();
  const { projeto } = useProjetos();
  const { nfe, setNfe, setInfos, nfes } = useNfe();
  const [item, setItem] = useState();
  const [info, setInfo] = useState();
  const [loading, setLoading] = useState(true);

  function handleClose() {
    openBlackScreen("nfe");
  }

  function downloadFile(file) {
    axios
      .post(`${uri}/icms-download/${projeto}/${file}`, {
        responseType: "blob",
      })
      .then((res) => {
        FileDownload(res.data, `${file}.xml`);
      });
  }

  function reduzir(str, n) {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  }

  function openModal(item) {
    openBlackScreen("modal");
    setTimeout(() => {
      document.getElementById("blackscreen").classList.add("ativ");
    }, 100);
    setNfe(item.chv);
    setInfos(item);
  }

  function formatNum(num) {
    return `R$ ${parseFloat(num).toLocaleString("br")}`;
  }

  useEffect(() => {
    console.log("item", item);

    setItem();
    if (nfe !== undefined) {
      axios.get(`${uri}/pro/nfe-info/${nfe}/${projeto}`).then((res) => {
        if (res.data !== null) {
          setItem(res.data.data);
          console.log("item", res.data.data);
          setInfo(res.data.info);
          console.log("status", res.data.info);
        }
      });
    }
  }, [nfe]);

  function Loader() {
    return (
      <Card>
        <ContentLoader speed={2} width={800} height={200}>
          <rect x="26" y="97" rx="5" ry="5" width="668" height="10" />
          <rect x="26 " y="116" rx="5" ry="5" width="668" height="10" />
          <rect x="26" y="137" rx="5" ry="5" width="668" height="10" />
          <rect x="26" y="158" rx="5" ry="5" width="668" height="10" />
          <rect x="26" y="179" rx="5" ry="5" width="668" height="10" />
          <rect x="26" y="47" rx="5" ry="5" width="250" height="30" />
        </ContentLoader>
        <ContentLoader speed={2} width={800} height={200}>
          <rect x="26" y="97" rx="5" ry="5" width="668" height="10" />
          <rect x="26 " y="116" rx="5" ry="5" width="668" height="10" />
          <rect x="26" y="137" rx="5" ry="5" width="668" height="10" />
          <rect x="26" y="158" rx="5" ry="5" width="668" height="10" />
          <rect x="26" y="179" rx="5" ry="5" width="668" height="10" />
          <rect x="26" y="47" rx="5" ry="5" width="250" height="30" />
        </ContentLoader>
      </Card>
    );
  }

  return (
    <CardContainer>
      <Header>
        <div className="btn-close" onClick={handleClose}>
          <AiOutlineClose />
        </div>
        <div className="header-text">
          <p>Informações sobre a nota</p>
        </div>
      </Header>
      {item !== undefined && item !== null ? (
        <Card key={item.chv}>
          <Content>
            <div className="content">
              <Head>
                <div className="infos">
                  <h2>
                    {item.modelo === "55" ? "NFe" : "CTe"} - {item.num}{" "}
                  </h2>
                  {!item.check && (
                    <div className="tags">
                      <div className="tag">
                        <p>Não escriturada</p>
                      </div>
                      {info.status === "Cancelada" && (
                        <div className="tag-cancelada">
                          <p>Cancelada</p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="infos">
                  <div
                    className="tag"
                    style={{ cursor: "pointer" }}
                    onClick={() => openModal(item)}
                  >
                    <p>Gerar Danfe</p>
                  </div>
                  <div className="btn" onClick={() => downloadFile(item.chv)}>
                    <BsCloudDownload />
                  </div>
                </div>
              </Head>
              <Infos>
                <div className="info">
                  <div className="tag">
                    <p>Emitente</p>
                  </div>
                  <div className="line">
                    <h5>ESTABELECIMENTO:</h5>
                    <p>{reduzir(item.emi.nome, 30)}</p>
                  </div>
                  <div className="line">
                    <h5>CNPJ:</h5>
                    <p>{item.emi.cnpj}</p>
                  </div>
                  <div className="line">
                    <h5>DATA EMISSÃO:</h5>
                    <p>
                      {new Date(item.data).toLocaleDateString("pt-BR", {
                        timeZone: "UTC",
                      })}
                    </p>
                  </div>
                </div>
                <div className="info">
                  <div className="tag">
                    <p>Destinatário</p>
                  </div>
                  <div className="line">
                    <h5>NOME:</h5>
                    <p>{item.dest.nome}</p>
                  </div>
                  <div className="line">
                    <h5>CNPJ:</h5>
                    <p>{item.dest.cnpj}</p>
                  </div>
                  <div className="line">
                    <h5>VALOR:</h5>
                    <p>{formatNum(item.valor)}</p>
                  </div>
                </div>
                <div className="info">
                  <div className="tag">
                    <p>Informações da nota</p>
                  </div>
                  <div className="line">
                    <h5>CHAVE:</h5>
                    <p>{item.chv}</p>
                  </div>
                  <div className="line">
                    <h5>QUANTIDADE DE ITENS:</h5>
                    <p>{item.itens}</p>
                  </div>
                  <div className="line">
                    <h5>CFOP:</h5>
                    <p>{item.cfop}</p>
                  </div>
                  <div className="line">
                    <h5>NAT. OP:</h5>
                    <p>{item.natOp}</p>
                  </div>
                </div>
                <div className="info small">
                  <div className="tag">
                    <p>Impostos</p>
                  </div>
                  <div className="line">
                    <h5>ICMS:</h5>
                    <p>{formatNum(item.impostos.icms)}</p>
                  </div>
                  <div className="line">
                    <h5>ICMS ST:</h5>
                    <p>{formatNum(item.impostos.st)}</p>
                  </div>
                  <div className="line">
                    <h5>PIS:</h5>
                    <p>{formatNum(item.impostos.ipi)}</p>
                  </div>
                  <div className="line">
                    <h5>COFINS:</h5>
                    <p>{formatNum(item.impostos.cofins)}</p>
                  </div>
                </div>
              </Infos>
            </div>
          </Content>
        </Card>
      ) : (
        <Loader />
      )}
    </CardContainer>
  );
}

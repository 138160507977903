import styled from "styled-components";

export const Search = styled.div`
  width: 20em;
  max-height: 54em;
  background-color: white;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: overlay;

  .search-bar {
    width: 100%;
    min-height: 2em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;

    input {
      width: calc(100% - 2em);
      min-height: 100%;
      padding: 0 15px;
      border: none;
    }

    .icon {
      width: 2em;
      min-height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
      opacity: 0.6;
      transition: all 0.2s;

      &:hover {
        opacity: 1;
      }

      svg {
        opacity: 0.6;
      }
    }
  }

  .header-list {
    width: 100%;
    height: 1em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(240, 240, 240, 0.8);

    p {
      font-size: 0.7em;
    }
  }

  .cnpjs-list {
    .header-list {
      width: 100%;
      height: 1em;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(240, 240, 240, 0.8);

      p {
        font-size: 0.7em;
      }
    }
  }

  .search-display {
    width: 100%;
    flex-direction: column;
    overflow: overlay;
  }

  .search-item {
    width: 100%;
    height: 3em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    opacity: 0.6;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      opacity: 1;
    }

    .icon {
      min-width: 3em;
      height: 100%;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 1.1em;
        opacity: 0.5;
      }
    }

    .text {
      max-width: calc(100% - 3em);
      height: 100%;
      display: flex;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;

      p {
        margin: 0 10px;
        font-size: 0.9em;
        opacity: 0.8;
      }
    }

    .selected-text {
      width: calc(100% - 5em);
    }

    .delete {
      width: 2em;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        font-size: 0.8em;
        color: rgba(245, 80, 100, 0.8);
      }
    }
  }
`;

import React, { useState, useEffect } from "react";
import axios from "axios";

import {
  ChartContainer,
  Container,
  Cards,
  Grafico,
  Tabela,
  InfoBlock,
} from "./styles";

import { IoCubeOutline } from "react-icons/io5";

import { MdKeyboardBackspace } from "react-icons/md";
import { BsInfoCircle } from "react-icons/bs";

import { useProjetos } from "../../../../hooks/projetos";

import ContentLoader, { Facebook } from "react-content-loader";

import Infos from "./components/Infos";

const uri = process.env.REACT_APP_API_URI;

export default function Charts() {
  const [data, setData] = useState();
  const [cnpj, setCnpj] = useState();
  const [ano, setAno] = useState();

  const { projeto, empresas } = useProjetos();

  useEffect(() => {
    setData();
    axios
      .post(`${uri}/projetos/files/${projeto}`, { cnpjx: cnpj, ano })
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        }
      });
  }, [cnpj, ano, projeto]);

  useEffect(() => {
    if (empresas.length === 1) {
      setCnpj(empresas[0].cnpj);
    }
  }, [empresas]);

  function handleChoose(obj) {
    if (cnpj === undefined) {
      setCnpj(obj.nome);
    }

    if (cnpj !== undefined && ano === undefined) {
      setAno(obj.nome);
    }
  }

  function handleBack() {
    if (cnpj !== undefined) {
      setCnpj();
      setAno();
    }

    if (ano !== undefined) {
      setAno();
    }
  }

  function LoadPanel() {
    var loads = [];

    var x = 4;
    while (x !== 10) {
      loads.push("a");
      x++;
    }

    function randomInt(min, max) {
      // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    }
    return (
      <>
        {" "}
        <Cards>
          {loads.slice(0, 5).map((obj) => (
            <div className="card">
              <div className="head">
                <ContentLoader viewBox="0 0 380 70">
                  <rect
                    x="15"
                    y="32"
                    rx="3"
                    ry="3"
                    width={randomInt(150, 250)}
                    height="15"
                  />
                </ContentLoader>{" "}
              </div>
              <div className="content">
                <ContentLoader viewBox="0 0 380 70">
                  <rect
                    x="15"
                    y="12"
                    rx="3"
                    ry="3"
                    width={randomInt(150, 300)}
                    height="35"
                  />
                </ContentLoader>
              </div>
            </div>
          ))}
        </Cards>
        <Grafico>
          <div className="index">
            <div className="head">
              <ContentLoader viewBox="0 0 380 70">
                <rect
                  x="15"
                  y="31"
                  rx="3"
                  ry="3"
                  width={randomInt(40, 100)}
                  height="10"
                />
              </ContentLoader>{" "}
            </div>
            <div className="display">
              {loads.slice(0, 4).map((obj) =>
                
                  <div className="item" >
                    <ContentLoader speed={2} width={650} height={60}>
                      <rect
                        x="50"
                        y="20"
                        rx="3"
                        ry="3"
                        width={randomInt(150, 270)}
                        height="12"
                      />
                    </ContentLoader>
                  </div>
                
              )}
            </div>
          </div>
          <div className="grafico">
            <div className="head">
              <ContentLoader viewBox="0 0 380 70">
                <rect
                  x="10"
                  y="31"
                  rx="3"
                  ry="3"
                  width={randomInt(40, 100)}
                  height="7"
                />
              </ContentLoader>{" "}
            </div>
            <div className="charts"></div>
          </div>
        </Grafico>
        <Tabela>
          <div className="head table">
            {Array.from({ length: 6 }, (_, i) => (
              <div className="item cnpj" key={i}>
                <div className="item ">
                  <ContentLoader viewBox="0 0 380 70">
                    <rect
                      x="15"
                      y="31"
                      rx="3"
                      ry="3"
                      width={randomInt(80, 200)}
                      height="10"
                    />
                  </ContentLoader>
                </div>
              </div>
            ))}
          </div>
          <div className="display">
            {Array.from({ length: 12 }, (_, i) => (
              <div className="items" key={i}>
                <ContentLoader speed={2} width={680} height={35}>
                  <rect
                    x="15"
                    y="10"
                    rx="3"
                    ry="3"
                    width={randomInt(120, 150)}
                    height="10"
                  />
                </ContentLoader>
                {Array.from({ length: 5 }, (_, i) => (
                  <ContentLoader speed={2} width={680} height={35} key={i}>
                    <rect
                      x="15"
                      y="10"
                      rx="3"
                      ry="3"
                      width={randomInt(50, 80)}
                      height="10"
                    />
                  </ContentLoader>
                ))}
              </div>
            ))}
          </div>
        </Tabela>
      </>
    );
  }

  return (
    <ChartContainer>
      <Container>
        {data !== undefined ? (
          <>
            {" "}
            <Cards>
              {data.cards.map((obj) => (
                <div className="card">
                  <div className="head">
                    <p>{obj.tag}</p>
                    <Infos msg={obj.info} />
                  </div>
                  <div className="content">
                    <h1>{obj.value.toLocaleString()}</h1>
                  </div>
                </div>
              ))}
            </Cards>
            <Grafico>
              {data.infos !== undefined && cnpj !== undefined && (
                <div className="index search">
                  <div className="head">
                    {empresas.length > 1 && (
                      <div className="back-btn" onClick={handleBack}>
                        <MdKeyboardBackspace />
                      </div>
                    )}
                    <p>Filtro selecionado</p>
                  </div>
                  <div className="infos">
                    <div className="tag">
                      <p>Nome</p>
                    </div>
                    <div className="text">
                      <p>{data.infos.nome}</p>
                    </div>

                    <div className="tag">
                      <p>CNPJ</p>
                    </div>
                    <div className="text">
                      <p>{data.infos.cnpj}</p>
                    </div>

                    {ano !== undefined && (
                      <>
                        {" "}
                        <div className="tag">
                          <p>Ano</p>
                        </div>
                        <div className="text">
                          <p>{data.infos.ano}</p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
              <div
                className="index"
                style={{
                  width: `${
                    String(
                      data.grafico.index.sort((a, b) => {
                        return String(b.nome).length - String(a.nome).length;
                      })[0].nome
                    ).length
                  }em`,
                }}
              >
                <div className="head">
                  <p>Index</p>
                </div>
                <div className="display">
                  {data.grafico.index.map((obj) => (
                    <div className="item">
                      <div className="thumb">
                        <IoCubeOutline style={{ color: obj.color }} />
                      </div>
                      <div className="content">
                        <p>{obj.nome}</p>
                        {obj.valor !== undefined && (
                          <p className="cnpj">{obj.value}</p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="grafico"
                style={{ width: `${data.tabela.values.length * 2}em` }}
              >
                <div className="head">
                  <p>
                    Faltantes por{" "}
                    {cnpj === undefined
                      ? "CNPJ"
                      : ano === undefined
                      ? "ano"
                      : "mês"}
                  </p>
                </div>
                <div className="charts">
                  {data.tabela.values.map((obj) => (
                    <div
                      className="column"
                      style={{
                        width: `calc(100% / ${data.tabela.values.length})`,
                        maxWidth: "2em",
                      }}
                    >
                      <div
                        className="bar"
                        style={{
                          height: `${obj.perc}%`,
                          backgroundColor: `${obj.color}`,
                          opacity: ".7",
                        }}
                      >
                        <div className="tag">
                          <p>
                            {obj.perc}% | {obj.faltantes} | {obj.nome}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Grafico>
            <Tabela>
              <div className="head table">
                {data.tabela.tags.map((obj) => (
                  <div className={`item ${obj.toLowerCase()}`}>
                    <p>{obj}</p>
                  </div>
                ))}
              </div>
              <div className="display">
                {data.tabela.values.map((obj) => (
                  <div className="items" onClick={() => handleChoose(obj)}>
                    <div className="item cnpj">
                      <div className="thumb">
                        <IoCubeOutline style={{ color: obj.color }} />
                      </div>
                      <p>{obj.nome}</p>
                    </div>
                    <div className="item ">
                      <p>{obj.nfes.toLocaleString()}</p>
                    </div>
                    <div className="item ">
                      <p>{obj.entradas.toLocaleString()}</p>
                    </div>
                    <div className="item ">
                      <p>{obj.saidas.toLocaleString()}</p>
                    </div>
                    <div className="item ">
                      <p>{obj.faltantes.toLocaleString()}</p>
                    </div>
                  </div>
                ))}
              </div>
            </Tabela>
          </>
        ) : (
          <LoadPanel />
        )}
      </Container>
    </ChartContainer>
  );
}

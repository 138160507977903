import { useEffect, useState, useRef } from "react";
import { Download, Head, ProgressCard, Project, Container } from "./style";
import logoheader from "../../assets/logo-header.png";
import { MdOutlineDashboard } from "react-icons/md";

import { useBlackScreen } from "../../hooks/blackscreen";
import axios from "axios";

import { AiOutlineHome, AiOutlineConsoleSql } from "react-icons/ai";
import { HiOutlineCube, HiDownload } from "react-icons/hi";
import { BiSolidDownload } from "react-icons/bi";
import { BsBarChartLine, BsFileZip, BsCloudDownload } from "react-icons/bs";
import { useNfe } from "../../hooks/nfe";
import { useUsers } from "../../hooks/user";
import { TbClipboardList } from "react-icons/tb";
import {
  MdOutlineAdminPanelSettings,
  MdUpdate,
  MdClose,
  MdLogout,
} from "react-icons/md";

import { useProjetos } from "../../hooks/projetos";

import getColor from "../../utils/getColor";
import { useFiles } from "../../hooks/files";

const uri = process.env.REACT_APP_API_URI;

function Header() {
  const {
    infos: projeto,
    projetos,
    projeto: projeto_info,
    filiais,
    setFiliais,
    userPage,
    setUserPage,
  } = useProjetos();

  const { openBlackScreen } = useBlackScreen();
  const [processIsLoading, setProcessIsLoading] = useState(false);
  const { setPage, setQuery, setNfes, filial_info, setSelect } = useNfe();
  const { setStatus, status, infos, Logout } = useUsers();
  const { files, getFiles } = useFiles();

  const [progress, setProgress] = useState();

  const userContainer = document.getElementById("user-container");
  const filter = document.getElementById("filter");
  const downloadContainer = document.getElementById("download-container");

  function download(obj) {
    document.getElementById(`bar-${obj.fileName}`).style.display = "flex";

    axios
      .get(`${uri}/pro/reports/download-zip/${obj.id}`, {
        responseType: "blob",
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          document.getElementById(
            obj.fileName
          ).style.width = `${percentCompleted}%`;
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.zip");
        document.body.appendChild(link);
        link.click();
        getFiles();
      })
      .catch((err) => {
        console.error("Error downloading the file:", err);
      });

    setTimeout(() => {
      document.getElementById(`bar-${obj.fileName}`).style.display = "none";
    }, 2000);
  }

  function formatMonth(monthNum) {
    return monthNum.toString().padStart(2, "0");
  }

  function formatBytes(bytes, decimals = 1) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    let result =
      parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];

    return String(result).replace(".", ",");
  }

  function toggleAtiv() {
    setUserPage(!userPage);
    userContainer.classList.remove("ativ");
  }

  function handleSearch(e) {
    setNfes([]);
    setQuery(e.target.value);
    setPage(1);
  }

  function checkClass(str) {
    if (str === status) {
      return "ativ";
    }
  }

  function handleChoose(str) {
    filter.classList.remove("ativ");
    setStatus(str);
    setQuery();
    setPage(1);
    setUserPage(false);
  }

  function openUserSettings() {
    setUserPage(!userPage);

    // Oculta os outros elementos da interface se eles existem
    if (filter) filter.classList.remove("ativ");
    if (downloadContainer) downloadContainer.classList.remove("ativ");
  }

  return (
    <Container>
      <Head>
        <div className="icons">
          <img
            className="logo"
            src={logoheader}
            onClick={() => handleChoose("nfe")}
          />
          {["6441b3aa317fcc3efdddadb4"].includes(
            localStorage.getItem("user_id")
          ) && (
            <div
              onClick={() => handleChoose("admin")}
              className={`btn ${checkClass("admin")}`}
              style={{ display: "none" }}
            >
              <MdOutlineAdminPanelSettings />
            </div>
          )}
          <div 
            onClick={() => handleChoose("dashboard")}
            className={`btn ${checkClass("dashboard")}`}
           style={{ display: "none" }}
          >
            <MdOutlineDashboard size="18" />
          </div>
          <div
            onClick={() => handleChoose("nfe")}
            className={`btn ${checkClass("nfe")}`}
          >
            <AiOutlineHome />
          </div>
          <div
            onClick={() => handleChoose("report")}
            className={`btn ${checkClass("report")}`}
          >
            <TbClipboardList size="18" />
          </div>

          <div
            onClick={() => handleChoose("charts")}
            className={`btn ${checkClass("charts")}`}
          >
            <BsBarChartLine />
          </div>

          <div
            /*  style={{ display: "none" }} */
            onClick={() => handleChoose("update")}
            className={`btn ${checkClass("update")}`}
          >
            <MdUpdate />
          </div>
          <div
            style={{ display: "none" }}
            onClick={() => handleChoose("charts-data")}
            className={`btn ${checkClass("charts-data")}`}
          >
            <BsBarChartLine />
          </div>
          <div
            onClick={() => handleChoose("sql")}
            className={`btn ${checkClass("sql")}`}
            style={{ display: "none" }}
          >
            <AiOutlineConsoleSql />
          </div>
        </div>
        {projeto !== undefined &&
          (filial_info === undefined ? (
            <Project>
              <div className="thumb">
                <HiOutlineCube
                  color={getColor(
                    projetos.indexOf(
                      projetos.find((obj) => obj._id === projeto_info)
                    )
                  )}
                />
              </div>
              <div className="content">
                <h2>{projeto.nome}</h2>
                <p>{projeto.cnpj}</p>
              </div>
            </Project>
          ) : (
            <Project>
              <div className="thumb">
                <HiOutlineCube color={getColor(filiais.indexOf(filial_info))} />
              </div>
              <div className="content">
                <h2>{filial_info.nome}</h2>
                <p>{filial_info.cnpj}</p>
              </div>
            </Project>
          ))}

        <div className="icons">
          {status.includes("nfe") && (
            <input
              id="search-nfe"
              placeholder="Busca"
              onChange={(e) => {
                handleSearch(e);
                setSelect("nfe_filter");
              }}
            ></input>
          )}

          {files.length > 0 && (
            <div className="btn-download" onClick={toggleAtiv}>
              <HiDownload />
            </div>
          )}

          <div className="user-icon" onClick={openUserSettings}>
            {infos !== undefined && (
              <p>{infos.nome.slice(0, 1).toUpperCase()}</p>
            )}
          </div>

          <div className="user-icon logout" onClick={Logout}>
            <MdLogout />
          </div>
        </div>
      </Head>
      <Download className="download-container" id="download-container">
        <div className="header">
          <div className="btn" onClick={toggleAtiv}>
            <MdClose />
          </div>
          <div className="text">
            <p>Arquivos para download</p>
          </div>
        </div>
        <div className="display">
          {files.length > 0 &&
            files.map((obj) => (
              <div className="download-line">
                <div className="thumb">
                  <BsFileZip />
                </div>
                <div className="infos">
                  <div className="name">
                    <p>
                      Notas Fiscais - {formatMonth(obj.mes + 1)}/{obj.ano}
                    </p>
                  </div>
                  <div className="status">
                    <p>{formatBytes(obj.fileSize)}</p>
                  </div>
                  <div>
                    <div className="progress-bar" id={`bar-${obj.fileName}`}>
                      <div className="progress" id={obj.fileName}></div>
                    </div>
                  </div>
                </div>
                <div className="btn" onClick={() => download(obj)}>
                  <BsCloudDownload />
                </div>
              </div>
            ))}
        </div>
      </Download>
    </Container>
  );
}

export default Header;

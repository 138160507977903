import React from "react";
import { Container } from "./style";

export default function Template({ title, atualizacao, post, picture, link }) {
  return (
    <Container>
      <div class="header">
        <h1>NOVA ATUALIZAÇÃO</h1>
      </div>
      <div className="logo">
        <a href="https://www.ontax-sped.com" class="btn">
          <img
            src="https://www.ontax-sped.com/static/media/ontax.9316d3de8d0bc505a0ab.png"
            width="180"
            height="50"
          />
        </a>
      </div>
      <div class="title">
        <p>{title}</p>
      </div>
      <a class="content" href={`${link}`}>
        {picture && (
          <div className="image-container">
            {" "}
            <img src={`${picture}`} width="550" height="300" />
          </div>
        )}
      </a>
      <div className="saiba-mais">
        <a href={`${atualizacao}`}>SAIBA MAIS</a>
      </div>
      <div className="text-content">
        <p>Prezado(a) usuário(a),</p>
        <p>
          Estamos felizes em informar que realizamos algumas atualizações
          importantes no nosso serviço:
        </p>
        <div className="description">{post}</div>
        <p>
          Para mais detalhes, visite a{" "}
          <a href="https://www.ontax-sped.com">página</a>.
        </p>
        <p>Atenciosamente,</p>
        <p>Sua Equipe</p>
      </div>

      <div class="footer">
        <p>&copy; 2024 Ontax. Todos os direitos reservados.</p>
      </div>
    </Container>
  );
}

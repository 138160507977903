import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
`;

const Head = styled.header`
  width: 100%;

  height: 3em;

  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(245, 245, 245, 1);

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 24;

  .logo {
    height: 2em;
    margin-left: 1em;
    margin-right: 2em;
  }

  .icons {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      cursor: pointer;
    }

    .btn {
      width: 3em;
      height: 3em;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        background-color: rgba(255, 255, 255, 0.8);
      }

      svg {
        font-size: 1em;
      }

      &.ativ {
        border-bottom: 2px solid rgba(0, 200, 200);
        background-color: white;
        color: rgba(0, 150, 200);
      }
    }
  }

  .process {
    width: 2em;
  }

  .filter {
    width: 1.5em;
  }

  input {
    width: 20em;
    height: 2em;
    border-radius: 100px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0 1em;
  }

  .btn-download {
    width: 2em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 1em;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 100%;

    svg {
      opacity: 0.6;
      font-size: 1.1em;
    }
  }

  .user-icon {
    border-radius: 100%;
    width: 2em;
    height: 2em;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1em;
    user-select: none;
    border: 1px solid rgba(0, 150, 200, 0.3);
    cursor: pointer;

    &.logout {
      height: 3em;
      width: 3em;
      margin-left: 0.5em;
      cursor: pointer;
      border-radius: 0;
      border: none;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      opacity: 0.5;
      margin-right: 0;

      svg {
        margin: 0;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
`;

const Project = styled.div`
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  width: max-content;
  height: 3em;
  left: 40%;
  position: fixed;

  .thumb {
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    justify-content: center;
    display: flex;
    align-items: center;
    width: 3em;
    height: 100%;
  }
  .content {
    margin-left: 20px;
    width: calc(100%-2em);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      margin-right: 1em;
      font-size: 0.8em;
    }
    p {
      line-height: 15px;
      font-size: 0.6em;
      opacity: 0.8;
    }
  }
  .btn-speds {
    width: 3em;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    svg {
      opacity: 0.7;
    }
  }
`;
const ProgressCard = styled.div`
  position: absolute;
  display: flex;
  gap: 0.5em;
  flex-direction: column;
  width: 20rem;
  background-color: #ffffff;
  height: 3rem;
  border-radius: 10px;
  z-index: 1;
  margin-top: 10em;
  padding: 1.3em;
  box-shadow: 3px 3px #dddee0;
  justify-content: center;

  h1,
  h2 {
    font-size: 0.8rem;
  }

  span {
    font-size: 0.48rem;
    font-weight: bold;
  }

  .up,
  .down {
    display: flex;
    align-items: center;
    gap: 0.8em;
    width: 100%;
  }

  .icon-download,
  .icon-process {
    color: #0069f2;
    font-weight: bold;
    width: 1rem;
  }
`;

export { Head, ProgressCard, Project };

export const Download = styled.div`
  right: 0;
  width: 18em;
  height: max-content;
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white;
  transform: translateX(-7em) translateY(-20.2em);
  z-index: 22;
  transition: all 0.1s;
  user-select: none;

  .header {
    width: 100%;
    height: 1.3em;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .btn {
      width: 1.3em;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      opacity: 0.8;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        opacity: 1;
      }

      svg {
        opacity: 0.8;
        font-size: 0.9em;
      }
    }

    .text {
      width: calc(100% - 2.6em);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        font-size: 0.75em;
        opacity: 0.8;
      }
    }
  }

  .display {
    width: 100%;
    height: max-content;
    max-height: 10em;
    overflow: overlay;
    display: flex;
    flex-direction: column;

    .download-line {
      width: 100%;
      height: 3.2em;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      overflow: hidden;

      &:last-child {
        border-bottom: none;
      }

      .thumb {
        width: 3em;
        height: 100%;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          font-size: 1.2em;
          opacity: 0.4;
        }
      }

      .infos {
        width: calc(100% - 5em);
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;

        div {
          width: 100%;
          height: 30%;
          display: flex;
          align-items: center;
          overflow: hidden;
          transform: translateY(0.5em);

          p {
            font-size: 0.8em;
            margin-left: 5px;
            font-weight: 500;
            opacity: 0.6;
          }

          &:last-child {
            height: 30%;
            display: flex;
            align-items: end;
            justify-content: center;
            .progress-bar {
              height: 3px;
              width: 80%;
              background-color: rgba(235, 235, 235);
              display: none;
              justify-content: start;
              .progress {
                width: 0;
                height: 100%;
                background-color: rgba(150, 200, 250, 1);
                display: flex;
              }
            }
          }
        }
        .status {
          p {
            font-size: 0.6em;
          }
        }
      }

      .btn {
        width: 2em;
        height: 2em;
        display: flex;
        justify-content: start;
        align-items: center;
        cursor: pointer;

        svg {
          opacity: 0.6;
        }
      }
    }
  }

  &.ativ {
    transform: translateX(-7em) translateY(3em);
  }
`;

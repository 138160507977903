import React, { useEffect, useState, useRef, useCallback } from "react";
import { Search } from "./style";

import axios from "axios";

import { MdOutlineSearch } from "react-icons/md";
import { IoCubeOutline } from "react-icons/io5";
import { BsFillTrashFill } from "react-icons/bs";

import { useProjetos } from "../../../../../../hooks/projetos";
import { useDownload } from "../../../../../../hooks/download";

import getColor from "../../../../../../utils/getColor";

import ContentLoader from "react-content-loader";

const uri = process.env.REACT_APP_API_URI;
export default function SearchSelector({ files }) {
  const { cnpjs, setCnpjs, filterDate, setFilterDate, type, setType } =
    useDownload();

  const [search, setSearch] = useState();
  const [loading, setLoading] = useState(false);
  const [from, setFrom] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [empresas, setEmpresas] = useState([]);

  const size = 10;

  const observer = useRef();

  const debouncedSearch = useDebounce(search, 500);

  const { projeto } = useProjetos();

  function randomInt(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const addItemToEmpresas = (newItem) => {
    // Criar uma nova cópia do array com o item existente
    const updatedCnpjs = [...cnpjs, newItem];

    // Atualizar o estado com o novo array
    setCnpjs(updatedCnpjs);
  };

  const removerCnpj = (index) => {
    const novosCnpjs = [...cnpjs];
    novosCnpjs.splice(index, 1);
    setCnpjs(novosCnpjs); // Atualiza o estado com o novo array
  };

  const loadEmpresas = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${uri}/pro/empresas/search`);
      setEmpresas([...empresas, ...response.data]);
      if (hasMore) {
        setFrom(from + size); // Prepara o 'from' para a próxima carga
      }
    } catch (error) {
      console.error("Erro ao carregar empresas:", error);
    }
    setLoading(false);
  };

  const lastEmpresaElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadEmpresas();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading]
  );

  function Loader() {
    return Array.from({ length: 13 }, (_, i) => (
      <div className="search-item">
        <ContentLoader key={i} speed={2} width={600} height={58}>
          <rect x="15" y="22" rx="5" ry="5" width="15" height="15" />
          <rect
            x="60"
            y="22"
            rx="5"
            ry="5"
            width={randomInt(180, 210)}
            height="15"
          />
        </ContentLoader>
      </div>
    ));
  }

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  useEffect(() => {
    loadEmpresas();
  }, []);

  useEffect(() => {
    if (debouncedSearch) {
      axios
        .post(`${uri}/pro/empresas/search`, {
          search: debouncedSearch,
          from: from,
        })
        .then((response) => {
          if (response.data.length < 20) {
            setHasMore(false);
          }
          setEmpresas(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Erro ao enviar dados:", error);
        });
    }
  }, [debouncedSearch, from]);

  return (
    <Search style={{ height: `calc(45em + ${files.length} * 0.05em)` }}>
      <div className="search-bar">
        <input
          placeholder="Pesquisar empresas..."
          onChange={(event) => {
            setSearch(event.target.value);
            setFrom(0);
          }}
        />
        <div className="icon">
          <MdOutlineSearch />
        </div>
      </div>
      <div className="cnpjs-list">
        {cnpjs.length !== 0 && (
          <div className="header-list">
            <p>CNPJ'S Selecionados</p>
          </div>
        )}
        {cnpjs.length !== 0 &&
          cnpjs.map((obj, i) => (
            <div key={i} className="search-item">
              <div className="icon">
                <IoCubeOutline
                  style={{ color: getColor(empresas.indexOf(obj)) }}
                />
              </div>
              <div className="text selected-text">
                <p>{obj.nome}</p>
              </div>
              <div className="delete" onClick={() => removerCnpj(i)}>
                <BsFillTrashFill />
              </div>
            </div>
          ))}
      </div>
      <div className="header-list">
        <p>Lista de Empresas</p>
      </div>
      <div
        className="search-display"
        style={{
          height: `calc(44em - ${cnpjs.length} * 3.1em + ${files.length} * 3em)`,
        }}
      >
        {empresas.length !== 0 ? (
          empresas.map((obj, i) => (
            <div
              key={i}
              ref={empresas.length === i + 1 ? lastEmpresaElementRef : null}
              className="search-item"
              onClick={() => addItemToEmpresas(obj)}
            >
              <div className="icon">
                <IoCubeOutline
                  style={{ color: getColor(empresas.indexOf(obj)) }}
                />
              </div>
              <div className="text">
                <p>{obj.nome}</p>
              </div>
            </div>
          ))
        ) : (
          <Loader />
        )}
      </div>
    </Search>
  );
}

import styled from "styled-components";

export const CardContainer = styled.div`
  width: 45em;
  height: max-content;
  min-height: 30em;
  display: flex;
  flex-direction: column;
  border-left: 0.5em solid green;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
`;
export const Card = styled.div`
  width: 45em;
  min-height: 30em;
  padding-bottom: 2em;
  background-color: white;
  display: flex;
  flex-direction: column;
  &:first-child {
    margin-top: 0.5em;
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 2em;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white;
  .btn-close {
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  .header-text {
    width: calc(100% - 2em);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-weight: 600;
      transform: translateX(-1em);
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .content {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
  }
`;

export const Infos = styled.div`
  width: 100%;
  height: calc(100% - 3em);
  display: flex;
  flex-direction: column;
  overflow: overlay;
  overflow-x: hidden;
  padding-bottom: 1em;

  .thumb {
    width: 6em;
    height: 6em;
    display: flex;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    opacity: 0.5;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .info {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: 1em;

    &.small {
      width: 10em;
    }

    &:last-child {
      border: none;
    }

    .tag {
      width: 100%;
      height: 1.5em;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 1em;
      p {
        font-size: 0.7em;
        opacity: 0.5;
      }
    }

    .line {
      width: 100%;
      height: 1.5em;
      display: flex;
      align-items: center;

      h5 {
        font-size: 0.7em;
        margin-right: 10px;
      }
      p {
        font-size: 0.8em;
      }
    }
  }
`;

export const Head = styled.div`
  width: 100%;
  height: 3em;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: space-between;

  input {
    width: 1em;

    border: 1px solid rgba(0, 0, 0, 0.5);
  }

  .infos {
    width: max-content;
    height: 100%;
    display: flex;
    align-items: center;
    .tag {
      width: max-content;
      height: max-content;
      border-radius: 50px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      opacity: 0.7;
      font-size: 0.8em;

      margin-left: 2em;
      p {
        font-size: 1em;
        padding: 0px 25px;
      }
    }
    .tags {
      display: flex;
      flex: row;

      .tag-cancelada {
        width: max-content;
        height: max-content;
        border-radius: 50px;
        border: 1px solid rgba(230, 0, 0, 0.3);
        opacity: 0.7;
        font-size: 0.8em;

        margin-left: 2em;
        p {
          color: rgba(230, 0, 0, 1);
          font-size: 1em;
          padding: 0px 25px;
        }
      }
    }

    h2 {
      font-size: 1em;
      margin-left: 1em;
    }

    .btn {
      width: 3em;
      height: 3em;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      opacity: 0.5;
      transition: all 0.2s;

      &:hover {
        opacity: 1;
      }
    }
  }
`;

export const Section = styled.div`
  display: flex;
`;
export const Titles = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 100%;
  overflow: hidden;
  align-items: flex-start;
  justify-content: space-around;

  h1 {
    margin-left: 2rem;
    margin-top: 0.4em;
    margin-bottom: 2rem;
    font-size: 1.4rem;
  }

  h2 {
    margin-left: 1.5rem;
    margin-top: 0.8em;
    font-size: 1rem;
  }

  h3 {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 0.8em;
    margin-left: 0.3rem;
  }
`;
export const ReactLoader = styled.div`
  backgroundcolor: #ffffff;
`;

export const Line = styled.div`
  display: flex;
`;

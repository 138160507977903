import styled from "styled-components";

export const Filter = styled.div`
  margin-top: 5.6em;
  background-color: rgba(245, 245, 245, 0.7);
  width: 20em;
  height: 100vh;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(0, 0, 0, 0.03);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transform: translateX(26em);
  transition: all 0.2s;
  position: fixed;
  z-index: 3;
  right: 0;

  &.ativ {
    transform: translateX(0em);
  }

  .search-bar {
    width: 100%;
    height: 2.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 1px solid rgba(0, 0, 0, 0.03);

    input {
      width: calc(100% - 2em);
      border-radius: 0px;
      height: 100%;
      padding: 0em;
      background-color: rgba(255, 255, 255, 1);
      color: black;
    }

    .btn {
      width: 2.2em;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgb(255, 255, 255);

      svg {
        font-size: 0.9em;
        opacity: 0.4;
      }
    }
  }

  .display {
    width: 100%;
    min-height: 8em;
    max-height: 8em;
    display: flex;
    flex-direction: column;
    overflow: overlay;
    overflow-x: hidden;
    background-color: rgba(240, 240, 240, 0.7);
  }

  .btn-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;

    .btns {
      display: flex;
      height: 3em;
      width: max-content;
      align-items: center;
      position: fixed;
      bottom: 0;
      transform: translateY(-8em) translateX(-1em);
      justify-content: center;
      svg {
        padding: 0.5em;
        margin-right: 1em;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background-color: white;
        cursor: pointer;
        opacity: 0.8;
        &:hover {
          opacity: 1;
        }
      }
      .btn-apply {
        width: max-content;

        height: 2em;
        background-color: rgb(15, 15, 15);
        cursor: pointer;
        display: flex;
        border-radius: 5px;
        justify-content: center;

        align-items: center;
        p {
          padding: 0 30px;
          font-size: 1.2em;
          color: white;
          font-weight: 600;
        }
      }
    }
  }

  .infos-box {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.7);
    margin-bottom: 0.1em !important;

    .box {
      width: calc(100% - 1em);
      height: max-content;

      display: flex;
      flex-direction: column;
      width: calc(100% -1em);
      margin-left: 1em !important;

      .tag {
        width: 100%;
        height: 1.5em;
        display: flex;
        border-bottom: 1px solid rgba(0, 0, 0, 0.03);
        align-items: center;
        background-color: rgba(255, 255, 255, 1);
        padding-left: 1em !important;
        transform: translateX(-1em);

        p {
          font-size: 0.7em;
          opacity: 0.5;
        }
      }

      .text {
        width: 100%;
        height: max-content;
        padding: 10px 0 !important;
        display: flex;
        align-items: center;

        &.desc {
          padding: 15px 0 !important;
        }

        p {
          font-size: 0.8em;
          width: 95%;
          line-height: 17px;
          opacity: 0.8;
        }
      }

      .infos {
        width: 100%;
        height: max-content;
        padding: 5px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.03);
        border-top: 1px solid rgba(0, 0, 0, 0.03);
        display: flex;
        background-color: rgba(255, 255, 255, 0.5);
        padding-left: 1em !important;
        transform: translateX(-1em);

        .info {
          width: 50%;
          height: 100%;
          display: flex;
          align-items: center;

          svg {
            margin-right: 0.5em;
            opacity: 0.5;
          }

          .name {
            font-size: 0.7em;
            opacity: 0.5;
            margin-right: 0.7em !important;
          }

          .number {
            font-size: 0.8em;
            opacity: 0.8;
          }
        }
      }
    }
  }
`;

export const Minidiv = styled.div`
  height: 2em;
  width: 20em;
  background-color: rgba(255, 255, 255, 0.5);
  margin-top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;

  p {
    margin-left: 0.5rem;
    font-size: 0.7em;
    opacity: 0.5;
  }
  &.options {
    flex-direction: column;
    height: max-content;
    .option {
      cursor: pointer;
      background-color: white;

      align-items: center;
      display: flex;
      height: 2em;
      width: 100%;
      justify-content: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      &.ativ {
        border: 1px solid rgba(0, 100, 250, 0.3);
        opacity: 1;
        background-color: white;
      }
      &:hover {
        opacity: 0.8;
      }
    }
    .option_display {
      width: 100%;
      height: max-content;
    }
  }
`;
export const SearchBar = styled.div`
  height: 2em;
  width: 20em;
  background-color: rgba(255, 255, 255, 0.5);
  margin-top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  margin: 0, 2rem, 0, 2rem;
  p {
    margin-left: 0.5rem;
    font-size: 0.7em;
    opacity: 0.5;
  }
  .input-container {
    width: 100%;
    height: calc(100% - 1.5em);
    display: flex;
    align-items: center;
  }
`;
export const PinBar = styled.div`
  display: flex;
  height: 3em;
  width: 20em;
  background-color: rgba(255, 255, 255, 0.5);

  margin-top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.03);

  align-items: center;
  /*  margin: 0, 1rem, 0, 1rem; */

  input {
    width: 1.5rem;
    border-radius: 50%;
  }

  .thumb {
    width: 3em;
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    svg {
      font-size: 0.8em;
      opacity: 0.5;
    }
    &.btn {
      border: none;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }
  }
`;

export const OuterBox = styled.div`
  display: flex;

  background-color: rgba(255, 255, 255, 0.5);
  margin-left: 10px;
  margin-top: 10px;

  flex-direction: column;
  align-items: center;

  align-items: flex-start;
  justify-content: center;
  input {
    width: 1.5rem;
    border-radius: 50%;
  }

  h2 {
    font-size: 0.8em;
    font-family: roboto;
  }
  p {
    font-size: 0.7em;
    opacity: 0.5;
  }
  &.cnpj {
    width: calc(100% - 6em);
  }
`;

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 3em);
  display: flex;
  flex-direction: column;
`;


export const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

`

export const Table = styled.div`
  width: max-content;
  height: 90%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  .head {
    width: 100%;
    height: 2em;
    display: flex;
    background-color: rgba(255, 255, 255, 0.7);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    p,
    svg {
      opacity: 0.8;
    }
  }

  .item {
    width: max-content;
    height: 100%;
    display: flex;
    align-items: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    &.ico {
      width: 3em;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin: 0;
        font-size: 0.8em;
      }
    }

    &.data {
      width: 15em;
    }

    &.nfe {
      width: 35em;
    }

    svg {
      margin-left: 1em;
    }

    p {
      margin-left: 0.5em;
      font-size: 0.9em;
    }
  }

  .display {
    width: 100%;
    height: calc(100% - 2em);
    overflow: overlay;

    .items {
      width: 100%;
      height: 3em;
      background-color: white;
      display: flex;
      opacity: 0.7;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;

      p {
        margin-left: 1em;
      }

      &:hover {
        opacity: 1;
        border-bottom: 1px solid rgba(0, 150, 200);
      }
    }
  }
`;
import React from "react";
import { useEffect } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Link,
  useParams,
} from "react-router-dom";

import App from "./App";
import { useProjetos } from "./hooks/projetos";
import { useUsers } from "./hooks/user";
import { useNfe } from "./hooks/nfe";
import { useUpdates } from "./hooks/updates";

const uri = process.env.REACT_APP_API_URI;

export default function Rotas() {
  return (
    <Router>
      <Routes>
        <Route path="" element={<App />} />
        <Route path="/:token/:id/:projeto" element={<Child />} />
        <Route path="/update/:id" element={<Update />} />
      </Routes>
    </Router>
  );
}

function Update() {
  const { status, setStatus } = useUsers();
  const { setStatus: setUpdate, setPost, posts } = useUpdates();

  const { id } = useParams();

  useEffect(() => {
    axios.interceptors.request.use(function (config) {
      config.headers.Authorization = `Bearer ${localStorage.getItem(
        "app_token"
      )}`;

      return config;
    });

    axios
      .get(`${process.env.REACT_APP_API_URI}/pro/update/${id}`)
      .then((res) => {
        setPost(res.data);
      });

    setStatus("update");
    setUpdate("update");
    console.log(posts);
    console.log(
      "post",
      posts.find((obj) => obj._id === id)
    );
    setPost(posts.find((obj) => obj._id === id));
  }, []);
  return <App />;
}
function Child() {
  const { setProjeto: setProjetoNfe } = useNfe();
  const { setProjeto } = useProjetos();
  const { token, id, projeto } = useParams();
  const { setLogin, status, setStatus } = useUsers();

  useEffect(() => {
    (async () => {
      axios.interceptors.request.use(function (config) {
        config.headers.Authorization = token;

        return config;
      });

      setProjeto(projeto);
      setProjetoNfe(projeto);
      setLogin(true);

      localStorage.setItem("app_token", token);
      localStorage.setItem("user_id", id);
      localStorage.setItem("projeto", projeto);

      window.location.href = "/";
    })();
  }, []);
}

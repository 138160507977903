import styled from "styled-components";

const Container = styled.div``;

const ChatStyle = styled.div`
  position: fixed;
  width: 5em;
  height: 5em;
  background-color: #404040;
  border-radius: 100%;
  bottom: 2em;
  right: 2em;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  opacity: 0.9;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
  }

  .head {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .notification {
      position: absolute;
      width: 1.5em;
      height: 1.5em;
      border-radius: 100%;
      background-color: rgba(0, 125, 250, 1);
      display: none;
      justify-content: center;
      align-items: center;
      bottom: 0;
      right: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

      p {
        color: white;
        font-weight: 600;
      }
    }

    .title {
      display: flex;
      justify-content: center;
      align-items: center;

      size: 0.8em;

      .icon {
        width: 30px;
      }
    }

    .open {
      font-size: 1em;
      margin-left: 5px;
      color: white;
    }
  }
`;

const OpenChat = styled.div`
  width: 18em;
  height: 25em;
  position: fixed;
  bottom: 0px;
  right: 10px;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  transition: all 0.2s;

  .header {
    background-color: #404040;
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    height: 3.8em;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
  }

  .icon-down {
    color: #ffffff;
    font-size: 1em;
    margin-right: 0.3em;
    margin-top: 0.5em;
  }

  img {
    width: 1.4em;
    margin-top: 0.5em;
    margin-left: 0.5em;
  }

  .status-icon {
    background-color: lime;
    border-radius: 100%;
    margin-left: 0.7em;
    width: 0.4em;
    height: 0.4em;
  }

  h1 {
    color: #ffffff;
    font-size: 0.6em;
    font-weight: 400;
  }

  .status {
    display: none;
    align-items: center;
    gap: 0.3em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    background-color: #000000;
  }

  .chat {
    width: 100%;
    height: 100%;
    overflow: scroll;
    background-color: #ededed;

    .chat-align {
      width: 100%;
      min-height: 3em;
      max-height: 9em;
      display: flex;
      align-items: center;

      .message {
        width: max-content;
        height: 1em;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.3em 0.5em;
        margin: 0.4em 0.5em;
        border-radius: 10px;
        background-color: #ffffff;

        p {
          font-size: 0.9em;
        }

        .time {
          width: max-content;
          margin-left: 0.5em;
          height: 100%;
          display: flex;
          justify-content: end;
          font-size: 0.7em;
          transform: translatey(0.2em);

          p {
            font-size: 0.9em;
          }
        }
      }
    }
    .chat-align.right {
      justify-content: flex-end;
    }

    .chat-align.left {
      justify-content: flex-start;
    }
  }

  hr {
    width: 100%;
    border: 1px solid black;
  }

  h2 {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-size: 0.8em;
    text-align: center;
    margin-top: 0.8em;
    color: #404040;
    font-weight: 600;
  }

  .ipt {
    height: 3em;
    width: 160px;
  }

  .text {
    display: flex;
    width: 100%;
    height: 4em;
    background-color: white;
    align-items: center;
    justify-content: space-between;
  }

  .icon-send {
    width: 2.3em;
    margin-right: 0.3em;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      opacity: 1;
    }
  }

  input[type="text" i] {
    font-style: italic;
  }
`;

export { ChatStyle, OpenChat, Container };

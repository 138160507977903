import React, { useEffect, useState } from "react";

import { Filter, Selector } from "./style";
import { useDownload } from "../../../../../../hooks/download";

export default function FilterSelector() {
  const years = getYearsArray(12);

  const months = [
    { nome: "Janeiro", abrv: "Jan", value: 0 },
    { nome: "Fevereiro", abrv: "Fev", value: 1 },
    { nome: "Março", abrv: "Mar", value: 2 },
    { nome: "Abril", abrv: "Abr", value: 3 },
    { nome: "Maio", abrv: "Mai", value: 4 },
    { nome: "Junho", abrv: "Jun", value: 5 },
    { nome: "Julho", abrv: "Jul", value: 6 },
    { nome: "Agosto", abrv: "Ago", value: 7 },
    { nome: "Setembro", abrv: "Set", value: 8 },
    { nome: "Outubro", abrv: "Out", value: 9 },
    { nome: "Novembro", abrv: "Nov", value: 10 },
    { nome: "Dezembro", abrv: "Dez", value: 11 },
  ];

  const { filterDate, setFilterDate, filter, setFilter } = useDownload();

  function handleChooseDate(type, value) {
    setFilterDate((prevState) => {
      const newState = {
        ...prevState,
        [filter]: {
          ...prevState[filter],
          [type]: value,
        },
      };

      return newState;
    });
  }

  function getYearsArray(length) {
    const currentYear = new Date().getFullYear();
    return Array.from({ length }, (_, index) => currentYear - index);
  }

  function checkClass(type, value) {
    if (filterDate[filter] && filterDate[filter][type] === value) {
      return "ativ";
    }
  }

  return (
    <>
      <Filter>
        <div
          className="start"
          style={
            filter === "start" ? { backgroundColor: "rgb(240, 240, 240)" } : {}
          }
          onClick={() => setFilter("start")}
        >
          <p>início</p>
          <p>{`${
            months.find((obj) => obj.value === filterDate.start.mes).nome
          } - ${filterDate.start.ano}`}</p>
        </div>
        <div
          className="end"
          style={
            filter === "end" ? { backgroundColor: "rgb(240, 240, 240)" } : {}
          }
          onClick={() => setFilter("end")}
        >
          <p>Final</p>
          <p>{`${
            months.find((obj) => obj.value === filterDate.end.mes).nome
          } - ${filterDate.end.ano}`}</p>
        </div>
      </Filter>
      <Selector>
        {years.map((year) => (
          <div
            className={`item ${checkClass("ano", year)}`}
            onClick={() => handleChooseDate("ano", year)}
          >
            <p>{year}</p>
          </div>
        ))}
        {months.map((month) => (
          <div
            className={`item ${checkClass("mes", month.value)}`}
            onClick={() => handleChooseDate("mes", month.value)}
          >
            <p>{month.abrv}</p>
          </div>
        ))}
      </Selector>
    </>
  );
}

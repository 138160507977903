import React from "react";
import { WideBox, TopInfo, Graphs, DetailedInfo, Container } from "./style";
import percent from "../../../../utils/percent";
import getColor from "../../../../utils/getColor";
import { useEffect } from "react";
import { BiCube } from "react-icons/bi";
import { BsInfoCircle } from "react-icons/bs";
import { useState } from "react";
export default function ChartsData() {
  const [total, setTotal] = useState(0);
  const data = [
    { nome: "AUTOMEC", valor: 2189 },
    { nome: "AUTOMEC", valor: 1234 },
    { nome: "AUTOMEC", valor: 3456 },
    { nome: "AUTOMEC", valor: 2146 },
    { nome: "AUTOMEC", valor: 1000 },
    { nome: "AUTOMEC", valor: 4000 },
    { nome: "AUTOMEC", valor: 3143 },
    { nome: "AUTOMEC", valor: 5453 },
    { nome: "AUTOMEC", valor: 5435 },
    { nome: "AUTOMEC", valor: 9000 },
    { nome: "POSTO", valor: 1233 },
    { nome: "POSTO", valor: 1111 },
    { nome: "POSTO", valor: 7777 },
    { nome: "POSTO", valor: 2313 },
  ];
  useEffect(() => {
    var final = 0;
    for (var i in data) {
      var valor = data[i].valor;
      final += valor;
    }
    setTotal(final);
  }, []);
  return (
    <Container>
      <WideBox>
        <TopInfo>
          <div className="infos">
            <div className="head">
              <div className="name">
                <h1>Valor</h1>
              </div>
              <div className="info-thumb">
                <div className="btn-thumb">
                  <BsInfoCircle />
                </div>
                <div className="btn-text">
                  <p>
                    Contagem da quantidade de NFe's registradas nos SPED's EFD
                    ICMS IPI inseridos no sistema.
                  </p>
                </div>
              </div>
            </div>
            <div className="value">
              <p>R$ 15000,00</p>
            </div>
          </div>
          <div className="infos">
            <div className="head">
              <div className="name">
                <h1>Valor</h1>
              </div>
              <div className="info-thumb">
                <div className="btn-thumb">
                  <BsInfoCircle />
                </div>
                <div className="btn-text">
                  <p>
                    Contagem da quantidade de NFe's registradas nos SPED's EFD
                    ICMS IPI inseridos no sistema.
                  </p>
                </div>
              </div>
            </div>
            <div className="value">
              <p>R$ 15000,00</p>
            </div>
          </div>
          <div className="infos">
            <div className="head">
              <div className="name">
                <h1>Valor</h1>
              </div>
              <div className="info-thumb">
                <div className="btn-thumb">
                  <BsInfoCircle />
                </div>
                <div className="btn-text">
                  <p>
                    Contagem da quantidade de NFe's registradas nos SPED's EFD
                    ICMS IPI inseridos no sistema.
                  </p>
                </div>
              </div>
            </div>
            <div className="value">
              <p>R$ 15000,00</p>
            </div>
          </div>
          <div className="infos">
            <div className="head">
              <div className="name">
                <h1>Valor</h1>
              </div>
              <div className="info-thumb">
                <div className="btn-thumb">
                  <BsInfoCircle />
                </div>
                <div className="btn-text">
                  <p>
                    Contagem da quantidade de NFe's registradas nos SPED's EFD
                    ICMS IPI inseridos no sistema.
                  </p>
                </div>
              </div>
            </div>
            <div className="value">
              <p>R$ 15000,00</p>
            </div>
          </div>
          <div className="infos">
            <div className="head">
              <div className="name">
                <h1>Valor</h1>
              </div>
              <div className="info-thumb">
                <div className="btn-thumb">
                  <BsInfoCircle />
                </div>
                <div className="btn-text">
                  <p>
                    Contagem da quantidade de NFe's registradas nos SPED's EFD
                    ICMS IPI inseridos no sistema.
                  </p>
                </div>
              </div>
            </div>
            <div className="value">
              <p>R$ 15000,00</p>
            </div>
          </div>
        </TopInfo>
        <Graphs>
          <div className="project">
            <div className="head">
              <div className="header">
                <p>Taxa</p>
              </div>
            </div>
            <div className="items">
              <div className="item">
                <div className="thumb">
                  <BiCube />
                </div>
                <div className="data">
                  <p>AUTOMEC FILIAL SOROCABA</p>
                </div>
              </div>
              <div className="item">
                <div className="thumb">
                  <BiCube />
                </div>
                <div className="data">
                  <p>AUTOMEC</p>
                </div>
              </div>
              <div className="item">
                <div className="thumb">
                  <BiCube />
                </div>
                <div className="data">
                  <p>AUTOMEC</p>
                </div>
              </div>
              <div className="item">
                <div className="thumb">
                  <BiCube />
                </div>
                <div className="data">
                  <p>AUTOMEC</p>
                </div>
              </div>
              <div className="item">
                <div className="thumb">
                  <BiCube />
                </div>
                <div className="data">
                  <p>AUTOMEC</p>
                </div>
              </div>
              <div className="item">
                <div className="thumb">
                  <BiCube />
                </div>
                <div className="data">
                  <p>AUTOMEC</p>
                </div>
              </div>
            </div>
          </div>
          <div className="graph">
            <div className="head">
              <p>Valor</p>
            </div>
            <div className="bars">
              {data.map((obj) => (
                <div
                  className="bar"
                  style={{ width: `calc(100% / ${data.length})` }}
                >
                  <div
                    className="content-bar"
                    style={{
                      height: `${percent(obj.valor, total)}%`,
                      backgroundColor: `${getColor(data.indexOf(obj))}`,
                    }}
                  >
                    <div className="tag">
                      <p>
                        {obj.nome} | {obj.valor} | {percent(obj.valor, total)}%
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Graphs>
        <DetailedInfo>
          <div className="header">
            <div className="item">
              <p>CNPJ</p>
            </div>
            <div className="item">
              <p>Valor</p>
            </div>
            <div className="item">
              <p>CNPJ</p>
            </div>
            <div className="item">
              <p>CNPJ</p>
            </div>
            <div className="item">
              <p>CNPJ</p>
            </div>
          </div>
          <div className="display">
            <div className="items">
              <div className="item">
                <p>0129381209</p>
              </div>
              <div className="item">
                <p>0129381209</p>
              </div>
              <div className="item">
                <p>0129381209</p>
              </div>
              <div className="item">
                <p>0129381209</p>
              </div>
              <div className="item">
                <p>0129381209</p>
              </div>
            </div>
          </div>
        </DetailedInfo>
      </WideBox>
    </Container>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { Container } from "./styles";
import { useTask } from "../../../../../../hooks/tasks";
import percent from "../../../../../../utils/percent";
export default function DownPop() {
  const stages = [
     {
      nome: "transf_to_compress",
      text: "Transferindo notas fiscais para compressão",
      titulo: "Transferindo",
    },
    {
      nome: "compress_nfes",
      text: "Comprimindo notas fiscais",
      titulo: "Comprimindo",
    },
    {
      nome: "downloading",
      text: "Baixando arquivo comprimido",
      titulo: "Baixando",
    },
  ];

  const { status, setStatus, progress, tasks } = useTask();
  const [estado, setEstado] = useState();
  const [goal, setGoal] = useState();

  useEffect(() => {
    if (status !== "off") {
      setEstado(stages.find((obj) => obj.nome === status));
    }
  }, [status]);

  useEffect(() => {

      if (tasks.length > 0) {
     

        setGoal(tasks.find((obj) => obj.status === "doing").goal);
      } 
    
  }, [tasks]);

  useEffect(() => {
    if(status === 'downloading') {
      setGoal(100)
    }
  }, [status])

  function DownloadContainer(estado) {
    return (
      <>
        <div className="header">
          <h1>{estado.titulo}</h1>
        </div>
        <div className="content">
          <div className="loadContainer">
            <div className="loadTag">
              <p>{estado.text}</p>
              <p>
                {progress} / {goal}
              </p>
            </div>
            <div className="loadBar">
              <div
                className="progress"
                style={{ width: `${percent(progress, goal)}%` }}
              ></div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <Container style={{ display: `${status === "off" ? "none" : "flex"}` }}>
      {status !== "off" && estado !== undefined && DownloadContainer(estado, 0)}
    </Container>
  );
}

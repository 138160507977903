import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eceff4;
`;

export const Block = styled.div`
  width: 90%;
  height: 95%;
  display: flex;
  margin-top: 2em;
  background-color: rgba(245, 245, 245);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
`;

export const Users = styled.div`
  min-width: 22em;
  height: 100%;
  display: flex;
  background-color: rgba(245, 245, 245);
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  flex-direction: column;

  .header {
    width: 100%;
    height: 3em;
    display: flex;
    background-color: white;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    svg {
      margin-right: 1em;
      margin-left: 2em;
      font-size: 0.9em;
      opacity: 0.7;
    }
    p {
      opacity: 0.8;
      font-size: 0.8em;
    }

    
  }
  .display {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    overflow: overlay;

    .user {
      width: 100%;
      height: 4em;
      background-color: white;
      opacity: 0.5;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: all 0.3s;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      &:hover,
      &.ativ {
        opacity: 0.8;
      }
      .thumb {
        width: 4em;
        height: 4em;
        display: flex;
        align-items: center;
        justify-content: center;
        .circle {
          width: 60%;
          height: 60%;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
        }
      }

      .user-info {
        display: flex;
        align-items: center;
        .notification {
          width: 1.3em;
          height: 1.3em;
          top: 3em;
          left: 1em;
          border-radius: 100%;
          background-color: red;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1;
          opacity: 1;

          p {
            color: white;
            font-weight: 600;
          }
        }
      }

      p {
        padding: 1em;
      }
    }
  }
`;

export const ChatBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgba(245, 245, 245);
  box-sizing: 0px 0px 10px rgba(0, 0, 0, 0.15);
  background-color: rgba(230, 230, 230);

  .header {
    width: 100%;
    height: 4em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    background-color: white;
    display: flex;
    align-items: center;
    opacity: 0.8;
    h2 {
      font-size: 1.1em;
    }
    .thumb {
      width: 4em;
      height: 4em;
      display: flex;
      align-items: center;
      justify-content: center;
      .circle {
        width: 60%;
        height: 60%;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        opacity: 0.8;
      }
    }
  }
  .chat {
    max-width: 100%;
    height: calc(100% - 8em);
    background-color: rgba(255, 255, 255, 0.3);
    display: flex;
    flex-direction: column;
    padding: 1em;
    overflow: overlay;
    .chat-align {
      width: 100%;
      min-height: 3em;
      max-height: 9em;
      display: flex;
      align-items: center;

      .message {
        min-width: 4em;
        max-width: 30em;
        min-height: 2em;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.1em 0.5em;

        border-radius: 10px;
        .time {
          width: 3em;
          height: 100%;
          display: flex;
          justify-content: end;
          font-size: 0.7em;
          transform: translatey(0.6em);
        }
      }
    }
    .chat-align.right {
      justify-content: flex-end;
    }

    .chat-align.left {
      justify-content: flex-start;
    }
  }
  .send {
    width: 100%;
    height: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    input {
      width: 95%;
      height: 1.8em;
      font-size: 1.1em;
      outline: none;
      border: none;
      padding: 5px 10px;
      padding-left: 20px;
      border-radius: 50px;
      border: 1px solid rgba(0, 0, 0, 0.05);
      padding-right: 2em;
      transform: translatex(1em);
      background-color: rgba(0, 0, 0, 0.05);
    }
    .send-btn {
      width: 2em;
      height: 2em;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translatex(-1.5em);
      cursor: pointer;
      svg {
      }
    }
  }
`;

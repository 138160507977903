import styled from "styled-components";

export const CardContainer = styled.div`
  width: 50em;
  height: max-content;
  min-height: 45em;
  display: flex;
  flex-direction: column;
  background-color: rgba(240, 240, 240);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
`;

export const Header = styled.div`
  width: 100%;
  height: 2em;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white;
  .btn-close {
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  .header-text {
    width: calc(100% - 2em);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-weight: 600;
      transform: translateX(-1em);
    }
  }

  .btn-download {
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;

    svg {
      opacity: 0.8;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    height: 2em;
    display: flex;
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    align-items: center;
    justify-content: center;
    .filter-header {
      width: max-content;
      height: 100%;
      display: flex;
      align-items: center;
    }

    .filter-item {
      cursor: pointer;
    }

    .filter-item.selected {
      opacity: 1;
      background-color: white;
      border-bottom: 2px solid rgba(0, 150, 200);
    }

    .filter-item {
      width: 8em;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
      opacity: 0.6;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
    }

    .filter-item.ativ {
      opacity: 1;
      background-color: white;
      border-bottom: 2px solid rgba(0, 150, 200, 0.8); /* Borda azul clara */
    }

    .filter-item p {
      font-size: 0.85em;
    }

    .filter-item {
      width: 8em;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
      opacity: 0.6;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
    }

    .filter-item.ativ {
      opacity: 1;
      background-color: white;
      border-bottom: 2px solid rgba(0, 150, 200, 0.8); /* Borda azul clara */
    }

    .filter-item p {
      font-size: 0.85em;
    }

    h2 {
      font-size: 1em;
      font-weight: 500;
      padding: 0 20px;
    }

    .btn {
      width: 2em;
      height: 2em;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }

  .display {
    width: 100%;
    height: 36em;
    overflow: overlay;
    overflow-y: auto;
    .items {
      width: 100%;
      height: 1.8em;
      display: flex;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      background-color: white;
      .status-icon {
        width: 3em;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        opacity: 0.7;
        p {
          font-size: 0.8em;
          margin-left: 0.5em;
        }

        svg {
          font-size: 0.8em;
        }
      }
      .data {
        width: 25%;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        opacity: 0.7;

        p {
          font-size: 0.8em;
          margin-left: 10px;
        }

        svg {
          margin-left: 0.5em;
          font-size: 0.9em;
        }
      }
      .key {
        width: 50%;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        opacity: 0.7;

        p {
          font-size: 0.8em;
          margin-left: 10px;
        }

        svg {
          margin-left: 0.5em;
          font-size: 0.9em;
        }
      }
      .cnpj {
        width: 20%;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        opacity: 0.7;

        p {
          font-size: 0.8em;
          margin-left: 10px;
        }

        svg {
          margin-left: 0.5em;
          font-size: 0.9em;
        }
      }

      /*   .item {
        width: calc(100% / 4);
        height: 100%;
        display: flex;
        align-items: center;
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
        p {
          font-size: 0.8em;
          margin-left: 0.5em;
          opacity: 0.8;
        }
      } */
    }
  }

  .table-header {
    width: calc(100% - 6px);
    height: 1.5em;
    background-color: rgba(250, 250, 250);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    .status-icon {
      width: 3em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid rgba(0, 0, 0, 0.1);

      p {
        font-size: 0.8em;
        margin-left: 0.5em;
      }

      svg {
        font-size: 0.8em;
        opacity: 0.5;
      }
    }
    .data {
      width: 25%;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      p {
        font-size: 0.8em;
        margin-left: 10px;
      }

      svg {
        margin-left: 0.5em;
        font-size: 0.9em;
      }
    }
    .key {
      width: 50%;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      p {
        font-size: 0.8em;
        margin-left: 10px;
      }

      svg {
        margin-left: 0.5em;
        font-size: 0.9em;
      }
    }
    .cnpj {
      width: 20%;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      p {
        font-size: 0.8em;
        margin-left: 10px;
      }

      svg {
        margin-left: 0.5em;
        font-size: 0.9em;
      }
    }

    /*    .table-item {
      width: calc(100% / 4);
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      height: 100%;
      display: flex;
      align-items: center;

      p {
        font-size: 0.8em;
        margin-left: 0.5em;
      }

      svg {
        margin-left: 0.5em;
        font-size: 0.9em;
      }
    } */
  }
`;

export const Infos = styled.div`
  width: 100%;
  height: 5em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const Card = styled.div`
  width: 10em;
  height: 4em;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white;
  margin: 0.5em;
  flex-direction: column;

  .head {
    width: 100%;
    height: 1.2em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;

    svg {
      font-size: 0.8em;
      margin-left: 10px;
    }

    p {
      font-size: 0.8em;
      opacity: 0.8;
      margin-left: 5px;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 1.2em);
    display: flex;
    align-items: center;

    h2 {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      margin-left: 10px;
      font-size: 1.2em;
      font-weight: 500;
    }
  }
`;

import styled from "styled-components";

export const DaterContainer = styled.div`
  .dater {
    width: 100%;
    height: max-content;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);

    .dates {
      width: 100%;
      height: 5em;
      display: flex;
      background-color: rgba(255, 255, 255, 0.5);
      cursor: pointer;

      .date {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.5;
        transition: opacity 0.2s;
        outline: none;

        &.ativ {
          opacity: 1;
          border-top: 1px solid rgba(0, 200, 250, 0.3);
          border-right: 1px solid rgba(0, 200, 250, 0.3);
          border-left: 1px solid rgba(0, 200, 250, 0.3);
          background-color: white;
          transform: translateY(1px);
        }

        &:hover {
          opacity: 1;
        }

        &.bar {
          border-right: 1px solid rgba(0, 0, 0, 0.03);
        }

        .content {
          width: 8em;
          height: 3.5em;
          display: flex;
          align-items: center;

          img {
            width: 1em;
            opacity: 0.5;
            margin-right: 1em !important;
          }

          .infos {
            width: max-content;
            height: 4em;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .info {
              font-size: 0.7em;
              height: 1.4em;
            }

            .data {
              font-size: 0.8em;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  .date-picker {
    width: 100%;
    height: max-content;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    flex-direction: column;
    border: 1px solid rgba(0, 200, 250, 0.3);

    .date-line {
      width: 100%;
      height: max-content;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1px;

      background-color: rgba(0, 0, 0, 0.1);
      .date-item {
        width: calc(100% / 6 - 1px);
        height: 2em;
        display: flex;
        background-color: white;
        border-left: 1px solid rgba(0, 0, 0, 0.03);
        border-bottom: 1px solid rgba(0, 0, 0, 0.03);
        justify-content: center;
        align-items: center;
        opacity: 0.7;
        cursor: pointer;
        &:hover,
        &.ativ {
          opacity: 1;
        }

        p {
          font-size: 0.8em;
          opacity: 0.8;
        }
      }
    }
  }
`;

import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
const ProjetoContext = createContext();

const uri = process.env.REACT_APP_API_URI;

export default function ProjetoProvider({ children }) {
  const [projeto, setProjeto] = useState();

  const [projetos, setProjetos] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [infos, setInfos] = useState();
  const [filiais, setFiliais] = useState([]);
  const [userPage, setUserPage] = useState(false);

  function getInfos() {
    axios.get(`${uri}/projeto/find/${projeto}`).then((res) => {
      setInfos(res.data);
    });
  }
  function getProjetos() {
    axios
      .get(`${uri}/pro/projeto/${localStorage.getItem("user_id")}`)
      .then((res) => setProjetos(res.data));
  }
  async function getEmpresas(clear) {
    if (clear) {
      setEmpresas([]);
    }
    if (projeto !== undefined) {
      await axios({
        method: "GET",
        url: `${uri}/empresas/${projeto}`,
      }).then((res) => {
        if (res.status === 200 && res.data.data.length > 0) {
          setTimeout(() => {
            const data = res.data;
            setEmpresas(data.data);
          }, [2000]);
        }
      });
    }
  }

  useEffect(() => {
    if (projeto !== undefined) {
      getEmpresas();
      getInfos();
      getProjetos();
    }
  }, [projeto]);

  useEffect(() => {
    axios.get(`${uri}/pro/filiais/${projeto}`).then((res) => {
      setFiliais(res.data);
    });
  }, [projeto]);

  return (
    <ProjetoContext.Provider
      value={{
        projeto,
        setProjeto,
        getEmpresas,
        empresas,
        infos,
        projetos,
        setProjetos,
        filiais,
        setFiliais,
        userPage,
        setUserPage,
      }}
    >
      {children}
    </ProjetoContext.Provider>
  );
}

export function useProjetos() {
  const context = useContext(ProjetoContext);
  const {
    setProjeto,
    projeto,
    getEmpresas,
    empresas,
    infos,
    projetos,
    setProjetos,
    filiais,
    setFiliais,
    userPage,
    setUserPage,
  } = context;
  return {
    projeto,
    setProjeto,
    getEmpresas,
    empresas,
    infos,
    projetos,
    setProjetos,
    filiais,
    setFiliais,
    userPage,
    setUserPage,
  };
}

import React, { useEffect, useState } from "react";
import { Container, Post } from "./style";
import axios from "axios";
import { useUpdates } from "../../../../../../hooks/updates";
import { NotionRenderer } from "react-notion";
import "react-notion/src/styles.css";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import ContentLoader from "react-content-loader";

const uri = process.env.REACT_APP_API_URI;

export default function Update() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const { setStatus, post } = useUpdates();

  useEffect(() => {
    if (post !== undefined) {
      axios.get(`https://www.ontax.app/pro/notion/${post.page}`).then((res) => {
        setData(res.data);
        setLoading(false);
      });
    }
  }, [post]);

  function randomInt(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  function Loader() {
    return (
      <Container>
        <Post>
          <div className="header">
            <div className="btn"></div>
            <div className="content">
              <ContentLoader speed={2} width={680} height={35}>
                <rect
                  x="15"
                  y="12"
                  rx="3"
                  ry="3"
                  width={randomInt(90, 120)}
                  height="15"
                />
              </ContentLoader>
            </div>
          </div>
          <div className="display">
            <div className="post-content">
              <ContentLoader speed={2} width={1400} height={600}>
                <rect x="20" y="20" rx="3" ry="3" width="800" height="15" />
                <rect x="20" y="150" rx="3" ry="3" width="100" height="15" />
                <rect
                  x="20"
                  y="220"
                  rx="3"
                  ry="3"
                  width={randomInt(550, 600)}
                  height="10"
                />
                <rect
                  x="20"
                  y="245"
                  rx="3"
                  ry="3"
                  width={randomInt(550, 600)}
                  height="10"
                />
                <rect
                  x="20"
                  y="270"
                  rx="3"
                  ry="3"
                  width={randomInt(550, 600)}
                  height="10"
                />
                <rect
                  x="20"
                  y="320"
                  rx="3"
                  ry="3"
                  width={randomInt(550, 600)}
                  height="10"
                />
                <rect
                  x="20"
                  y="345"
                  rx="3"
                  ry="3"
                  width={randomInt(550, 600)}
                  height="10"
                />
                <rect
                  x="20"
                  y="370"
                  rx="3"
                  ry="3"
                  width={randomInt(550, 600)}
                  height="10"
                />
                <rect
                  x="20"
                  y="420"
                  rx="3"
                  ry="3"
                  width={randomInt(550, 600)}
                  height="10"
                />
                <rect
                  x="20"
                  y="445"
                  rx="3"
                  ry="3"
                  width={randomInt(550, 600)}
                  height="10"
                />
                <rect
                  x="20"
                  y="470"
                  rx="3"
                  ry="3"
                  width={randomInt(550, 600)}
                  height="10"
                />
                <rect
                  x="20"
                  y="495"
                  rx="3"
                  ry="3"
                  width={randomInt(550, 600)}
                  height="10"
                />
                <rect
                  x="20"
                  y="520"
                  rx="3"
                  ry="3"
                  width={randomInt(550, 600)}
                  height="10"
                />
                <rect
                  x="20"
                  y="545"
                  rx="3"
                  ry="3"
                  width={randomInt(550, 600)}
                  height="10"
                />
                <rect x="720" y="210" rx="3" ry="3" width="600" height="350" />
              </ContentLoader>
              <ContentLoader speed={2} width={1400} height={700}>
                <rect x="15" y="50" rx="3" ry="3" width="300" height="15" />
                <rect
                  x="45"
                  y="110"
                  rx="3"
                  ry="3"
                  width={randomInt(950, 1100)}
                  height="10"
                />
                <rect
                  x="45"
                  y="200"
                  rx="3"
                  ry="3"
                  width={randomInt(950, 1100)}
                  height="10"
                />
                <rect
                  x="45"
                  y="290"
                  rx="3"
                  ry="3"
                  width={randomInt(950, 1100)}
                  height="10"
                />
                <rect
                  x="45"
                  y="380"
                  rx="3"
                  ry="3"
                  width={randomInt(950, 1100)}
                  height="10"
                />
                <rect
                  x="45"
                  y="470"
                  rx="3"
                  ry="3"
                  width={randomInt(950, 1100)}
                  height="10"
                />
                <rect
                  x="45"
                  y="560"
                  rx="3"
                  ry="3"
                  width={randomInt(950, 1100)}
                  height="10"
                />
              </ContentLoader>
            </div>
          </div>
        </Post>
      </Container>
    );
  }

  return !loading && data !== undefined ? (
    <Container>
      <Post>
        <div className="header">
          <div className="btn" onClick={() => setStatus("updates")}>
            <MdOutlineKeyboardBackspace />
          </div>
          <div className="content">
            <h2>{post.titulo}</h2>
          </div>
        </div>
        <div className="display">
          <div className="post-content">
            {data !== undefined && <NotionRenderer blockMap={data} />}
          </div>
        </div>
      </Post>
    </Container>
  ) : (
    <Loader />
  );
}

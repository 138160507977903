import React from "react";
import { Container, Table, TableContainer } from "./style";

import axios from "axios";
import { useState } from "react";
import { useUpdates } from "../../../../hooks/updates";
import UpdatesDisplay from "./components/updates";
import Update from "./components/update";


const uri = process.env.REACT_APP_API_URI;

export default function Updates() {
  const {status} = useUpdates()
  const stages = [
    { tipo: "updates", component: <UpdatesDisplay /> },

    { tipo: "update", component: <Update />},
  ];

  return (
    <Container>
      {stages.find(obj => obj.tipo === status).component}
    </Container>
  );
}

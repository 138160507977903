import styled, { css } from "styled-components";

export const Block = styled.div`
  width: 35em;
  height: 35em;
  display: flex;
  background-color: rgba(240, 240, 240);
  border-radius: 5px;
  flex-direction: column;
  overflow: hidden;
  z-index: 35;

  &.table {
    width: 45em;
    height: 45em;
  }
  .drop-box {
    width: 100%;
    height: calc(100% - 2em);
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;
    .files-container {
      width: 100%;
      min-height: max-content;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      .files-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        .file {
          width: 100%;
          height: 3em;
          display: flex;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          background-color: white;

          .thumb {
            width: 3em;
            height: 3em;
            display: flex;
            justify-content: center;
            align-items: center;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            img {
              width: 1.2em;
            }
          }
          .text {
            width: calc(100% - 6em);
            height: 100%;
            display: flex;
            flex-direction: column;
            margin-left: 1em;
            justify-content: center;
            h2 {
              font-size: 0.9em;
              font-weight: 500;
            }
            p {
              font-size: 0.8em;
              opacity: 0.8;
              line-height: 15px;
            }
          }
          .delete-btn {
            width: 3em;
            height: 3em;
            display: flex;
            justify-content: center;
            align-items: center;
            border-left: 1px solid rgba(0, 0, 0, 0.1);
            opacity: 0.5;

            cursor: pointer;
            &:hover {
              opacity: 0.8;
            }
            svg {
              color: red;
            }
          }
        }
      }
      .progress-bar {
        width: 100%;
        height: 0.5em;
        background-color: rgba(0, 0, 0, 0.2);
        .load {
          width: 0%;
          height: 100%;
          background-color: rgba(0, 180, 100);
        }
      }
      .send-files {
        width: 100%;
        height: 4em;
        display: flex;
        justify-content: center;
        align-items: center;

        .btn {
          width: max-content;
          height: 2em;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(15, 15, 15);
          border-radius: 5px;
          cursor: pointer;
          opacity: 0.8;
          &:hover {
            opacity: 1;
          }
          h2 {
            color: white;
            font-size: 1em;
            padding: 5px 30px;
          }
        }
      }
    }
  }
`;

const DragActive = css`
  border-color: #78e5d5;
`;
const DragReject = css`
  border-color: #e57878;
`;

export const DropContainer = styled.div`
  width: 100%;
  height: calc(100% - 2em);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DropArea = styled.div`
  width: 95%;
  height: 95%;
  border-radius: 10px;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: rgba(250, 250, 250);
  ${(props) => props.isDragActive && DragActive}
  ${(props) => props.isDragReject && DragReject}

    .text-container {
    transform: translateX(2em);
    width: 20em;
    height: max-content;
    display: flex;
    .thumb {
      height: 4em;
      display: flex;
      justify-content: center;
      align-items: center;

      .circle {
        height: 1.5em;
        width: 1.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(0, 150, 200, 0.8);
        border-radius: 100%;
        p {
          color: rgba(0, 150, 200, 0.8);
        }
      }
    }
    .text {
      margin-left: 10px;
      width: calc(100%);
      display: flex;
      flex-direction: column;
      h2 {
        font-weight: 500;
        font-size: 1.1em;
      }
      p {
        width: 70%;
        margin-top: 7px;
        font-size: 0.8em;
        opacity: 0.8;
        line-height: 15px;
      }
    }
  }
`;

export const Choice = styled.div`
  z-index: 3;
  display: flex;
  background-color: white;
  height: 5em;
  width: 15em;
  flex-direction: column;
  position: absolute;
  transform: translateX(45.1em);
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  .thumb {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    height: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    border-right: 1px solid rgba(0, 0, 0, 0.3);
    svg {
      font-size: 0.8em;
    }
  }
  .item {
    justify-content: center;
    flex-direction: row;
    height: 50%;
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    .name {
      align-items: center;

      width: 75%;
      height: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      border-right: 1px solid rgba(0, 0, 0, 0.3);
      display: flex;
      p {
        margin-left: 1em;
        font-size: 0.8em;
        font-weight: 400;
      }
    }
    .btn {
      display: flex;
      align-items: center;
      flex-direction: center;
      justify-content: center;
      width: 25%;
      height: 100%;

      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      opacity: 0.6;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }

      svg {
        font-size: 0.8em;
      }
    }
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(250, 250, 250);

  .close {
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &:hover {
      background-color: white;
    }

    svg {
      opacity: 0.5;
      font-size: 0.8em;
    }
  }

  .arrow {
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &:hover {
      background-color: white;
    }

    svg {
      opacity: 0.5;
      font-size: 0.8em;
    }
  }

  .export {
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &:hover {
      background-color: white;
    }

    svg {
      opacity: 0.7;
      font-size: 0.8em;
    }
  }

  .text {
    width: calc(100% - 2em);
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-size: 0.8em;
      font-weight: 600;
      opacity: 0.6;
      transform: translateX(-1.8em);
    }
  }
`;

export const FalseContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  background-color: rgba(240, 240, 240);

  .headblock {
    height: 4em;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .miniblock {
      display: flex;
      flex-direction: column;
      height: 3.5em;
      width: calc(100% / 3.5);
      background-color: white;

      .tag {
        display: flex;
        align-items: center;
        width: 100%;
        height: 1em;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        p {
          margin-left: 5px;
          font-size: 0.75em;
        }
      }

      .content {
        margin-left: 5px;
        display: flex;
        align-items: center;
        width: 100%;
        height: calc(100% - 1em);
        h1 {
          font-size: 1em;
          font-weight: 400;
        }
      }
    }
  }
  .container {
    width: 95%;
    height: calc(100% - 5.5em);
    margin-top: 0.5em;
    background-color: rgba(250, 250, 250);
    .item {
      display: flex;
      align-items: center;
      height: 100%;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      p {
        margin-left: 10px;
        font-size: 0.8em;
      }
      &.chave {
        width: 55%;
      }
      &.data {
        width: 22.5%;
      }
      &.sit {
        width: 22.5%;
      }
    }

    .table-header {
      width: 100%;
      height: 1.5em;
      background-color: white;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: row;
    }
    .content-info {
      overflow: overlay;
      height: 90%;
      display: flex;
      flex-direction: column;

      .items {
        min-height: 1.8em;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        .item {
          display: flex;
        }
      }
    }
  }
`;
export const RevContainer = styled.div`
  width: max-content;
  height: max-content;
  display: flex;
`;

export const RevBlock = styled.div`
  width: 45em;
  height: max-content;
  min-height: 45em;
  display: flex;
  flex-direction: column;
  background-color: rgba(240, 240, 240);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  &.table {
    width: 45em;
    height: 45em;
  }
  .drop-box {
    width: 100%;
    height: calc(100% - 2em);
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;
    .files-container {
      width: 100%;
      min-height: max-content;
      display: flex;
      flex-direction: column;
      .files-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        .file {
          width: 100%;
          height: 3em;
          display: flex;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          background-color: white;

          .thumb {
            width: 3em;
            height: 3em;
            display: flex;
            justify-content: center;
            align-items: center;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            img {
              width: 1.2em;
            }
          }
          .text {
            width: calc(100% - 6em);
            height: 100%;
            display: flex;
            flex-direction: column;
            margin-left: 1em;
            justify-content: center;
            h2 {
              font-size: 0.9em;
              font-weight: 500;
            }
            p {
              font-size: 0.8em;
              opacity: 0.8;
              line-height: 15px;
            }
          }
          .delete-btn {
            width: 3em;
            height: 3em;
            display: flex;
            justify-content: center;
            align-items: center;
            border-left: 1px solid rgba(0, 0, 0, 0.1);
            opacity: 0.5;

            cursor: pointer;
            &:hover {
              opacity: 0.8;
            }
            svg {
              color: red;
            }
          }
        }
      }
      .progress-bar {
        width: 100%;
        height: 0.5em;
        background-color: rgba(0, 0, 0, 0.2);
        .load {
          width: 0%;
          height: 100%;
          background-color: rgba(0, 180, 100);
        }
      }
      .send-files {
        width: 100%;
        height: 4em;
        display: flex;
        justify-content: center;
        align-items: center;

        .btn {
          width: max-content;
          height: max-content;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(15, 15, 15);
          border-radius: 5px;
          cursor: pointer;
          opacity: 0.8;
          &:hover {
            opacity: 1;
          }
          h2 {
            color: white;
            font-size: 1em;
            padding: 5px 30px;
          }
        }
      }
    }
  }
`;

export const RevHeader = styled.div`
  width: 100%;
  height: 2em;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white;

  .close {
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &:hover {
      background-color: white;
    }

    svg {
      opacity: 0.5;
      font-size: 0.8em;
    }
  }
  .export {
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &:hover {
      background-color: white;
    }

    svg {
      opacity: 0.7;
      font-size: 0.8em;
    }
  }

  .text {
    width: calc(100% - 2em);
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-size: 0.8em;
      font-weight: 600;
      opacity: 0.6;
      transform: translateX(-1.8em);
    }
  }
`;

export const RevValue = styled.div`
  width: 100%;
  height: 4em;
  display: flex;
  justify-content: space-between;

  .toggle {
    width: max-content;
    height: 100%;
    display: flex;

    div {
      width: 11em;
      height: 85%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0 0 5px 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      margin-left: 0.2em;
      cursor: pointer;
      opacity: 0.6;
      transition: all 0.2s;

      &:hover {
        opacity: 1;
      }

      svg {
        font-size: 0.8em;
        opacity: 0.4;
        margin-right: 5px;
      }

      p {
        font-size: 0.8em;
        opacity: 0.8;
      }
    }

    .ativ {
      opacity: 1;
      height: 100%;
    }
  }

  #speds {
    display: none;
  }

  .update {
    width: 2em;
    height: 2em;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4px;
    margin-right: 6px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    opacity: 0.8;
    transition: all 0.2s;

    &:hover {
      opacity: 1;
    }

    svg {
      font-size: 0.8em;
      opacity: 0.8;
    }
  }
`;

export const RevFalseContainer = styled.div`
  width: 100%;
  height: calc(100% - 4em);
  background-color: rgba(240, 240, 240);
  display: flex;
  flex-direction: column;
  justify-content: center;

  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .head {
      width: 100%;
      height: 1.8em;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);

      &.table {
        overflow: hidden;
        flex-wrap: nowrap;
        background-color: rgba(255, 255, 255);
      }

      p {
        font-size: 0.8em;
        opacity: 0.9;
        margin: 0 1.3em !important;
        font-weight: 400;
      }

      &.ico {
        justify-content: space-between;
        p {
          margin: unset !important;
        }

        svg {
          transform: translateX(-10px);
        }

        .head-ico {
          display: flex;
          align-items: center;
          svg {
            margin-left: 10px !important;
            margin-right: 5px !important;
            transform: translateX(0);
            font-size: 0.7em;
            opacity: 0.5;
          }
        }
      }
    }
    .upper-content {
      width: 95%;
      height: 18em;
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
`;

export const RevInfo = styled.div`
  width: 100%;
  height: 5em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const RevCard = styled.div`
  width: 13.5em;
  height: 4em;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white;
  margin: 0.5em;
  flex-direction: column;
  margin-top: 1.5em;

  .head {
    width: 100%;
    height: 1.2em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;

    svg {
      font-size: 0.8em;
      margin-left: 10px;
    }

    p {
      font-size: 0.8em;
      opacity: 0.8;
      margin-left: 5px;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 1.2em);
    display: flex;
    align-items: center;

    h2 {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      margin-left: 30px;
      font-size: 1.2em;
      font-weight: 500;
      opacity: 0.8;
    }
  }
`;

export const RevGrafico = styled.div`
  width: 95%;
  height: 15em;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  align-items: center;
  justify-content: space-between;
  overflow-x: overlay;

  .index {
    width: 60%;
    height: 90%;
    display: flex;
    flex-direction: column;
    background-color: white;

    &.search {
      width: 20em;

      .infos {
        background-color: rgba(250, 250, 250);
        width: 100%;
        height: calc(100% - 1.8em);

        .tag {
          width: 100%;
          height: 1.3em;
          display: flex;
          align-items: center;
          background-color: rgba(255, 255, 255, 0.5);
          border-bottom: 1px solid rgba(0, 0, 0, 0.03);

          p {
            transform: translateX(1em);
            font-size: 0.7em;
            opacity: 0.5;
          }
        }

        .text {
          width: 100%;
          height: 2em;
          display: flex;
          align-items: center;
          background-color: white;

          p {
            transform: translateX(1em);
            font-size: 0.7em;
            opacity: 0.8;
          }
        }
      }
    }

    .back-btn {
      width: 1.5em;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        font-size: 0.8em;
        opacity: 0.5;
      }
    }

    .display {
      width: 100%;
      height: calc(100% - 1.8em);
      display: flex;
      flex-direction: column;
      overflow: hidden;
      overflow-y: overlay;
      background-color: rgba(250, 250, 250);

      .item {
        width: 100%;
        height: 3.5em;
        font-size: 1em;
        display: flex;
        align-items: center;
        background-color: white;
        border-bottom: 1px solid rgba(0, 0, 0, 0.03);
        margin-top: 1px !important;
        opacity: 0.6;
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
          opacity: 1;
          border-bottom: 1px solid rgba(0, 200, 255, 0.1);
        }

        .thumb {
          width: 3.5em;
          height: 3.5em;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            font-size: 0.7em;
          }
        }

        .content {
          width: calc(100% - 2em);
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          p {
            font-size: 0.8em;
            font-weight: 400;
            margin-left: 1em !important;
            line-height: 17px;

            &.cnpj {
              opacity: 0.4;
            }
          }
        }
      }
    }
  }

  .grafico {
    width: 36%;
    height: 90%;
    display: flex;
    flex-direction: column;
    background-color: white;
    overflow: hidden;

    .charts {
      width: 100%;
      height: calc(100% - 1.8em);
      display: flex;
      background-color: rgba(250, 250, 250);
      overflow: hidden;

      .column {
        width: calc(100% / 5 - 1px);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        border-left: 1px solid rgba(0, 0, 0, 0.03);
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 1;
        transition: all 0.2s;
        opacity: 0.8;
        cursor: pointer;

        .bar {
          width: 40%;
          height: 5%;
          min-height: 0.2em;
          display: flex;
          justify-content: center;
          opacity: 0.7;

          .tag {
            width: max-content;
            height: 1.7em;
            z-index: 3;
            display: flex;
            background-color: rgba(10, 10, 10);
            color: white;
            position: fixed;
            transform: translateY(-2.5em);
            border-radius: 5px;
            align-items: center;
            opacity: 0;
            transition: all 0.2s;

            p {
              margin: 0 1em !important;
              font-size: 1em;
              font-weight: 300;
            }
          }
        }

        &:hover {
          opacity: 1;
          background-color: white;
          z-index: 3;

          .tag {
            opacity: 1;
          }
        }
      }
    }
  }
`;

export const RevTabela = styled.div`
  width: 95%;
  height: 20em;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 0.5em;
  margin-bottom: 1em;

  .name {
    min-width: 22em;
  }

  .cnpj {
    min-width: 11em;
  }

  .head-table {
    width: 100%;
    height: 2em;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .header-item {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      border-right: 1px solid rgba(0, 0, 0, 0.1);

      p {
        margin-left: 10px;
        font-size: 0.8em;
      }
    }
  }

  .display {
    width: 100%;
    height: calc(100% - 2em);
    overflow: overlay;
    overflow-x: hidden;

    .items {
      width: 100%;
      height: 3em;
      display: flex;
      opacity: 0.6;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        opacity: 1;
      }

      .item {
        width: 100%;
        height: 100%;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        overflow: hidden;
        display: flex;
        align-items: center;

        svg {
          opacity: 0.4;
        }

        p {
          width: 95%;
          font-size: 0.8em;
          margin-left: 10px;
          line-height: 15px;
        }

        .thumb {
          width: 3em;
          height: 3em;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            font-size: 0.8em;
          }
        }

        .btn-download {
          width: 4em;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0.8;
          transition: all 0.2s;
          border-left: 1px solid rgba(0, 0, 0, 0.1);

          &:hover {
            opacity: 1;
          }

          svg {
            opacity: 0.6;
          }
        }
      }
    }
  }
`;

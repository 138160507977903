import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
import { useProjetos } from "./projetos";
import timer from "../utils/timer";
import fileDownload from "js-file-download";
import alerta from "../utils/alerta";
import { useNfe } from "./nfe";

const TaskContext = createContext();
const uri = process.env.REACT_APP_API_URI;

export default function TaskProvider({ children }) {
  const { projeto } = useProjetos();
  const { filter, status: nfes_status } = useNfe();
  const [status, setStatus] = useState("off");
  const [tasks, setTasks] = useState([]);
  const [progress, setProgress] = useState(0);

  function startCompress() {
    function start() {
      var url = `${uri}/pro/nfes-filter/${projeto}`;
      if (nfes_status === "canceladas") {
        url = `${uri}/pro/canceladas/${projeto}`;
      }
      document.getElementById("alert").style.display = "none";
      var new_filter = filter;
      new_filter.download = true;
      axios.post(url, { filter: new_filter }).then((res) => {
        if (res.status === 200) {
          alerta(res.data.msg, false, "success");
          setStatus("compressing");
        }
      });
    }
    alerta("Deseja compactar as notas filtradas?", true, "err", start);
  }

  async function downloadFiles() {
    setStatus("downloading");
    axios
      .post(
        `${uri}/tools/download/${localStorage.getItem("projeto")}`,
        { file: "tasks/compress/nfe.zip" },
        {
          responseType: "blob",
          onDownloadProgress: (e) => {
            var progress_info = parseInt(
              Math.round((e.loaded * 100) / e.total)
            );

            setProgress(progress_info);
          },
        }
      )
      .then((res) => {
        fileDownload(res.data, "nfes.zip");
        setStatus("off");
      });
  }

  const estados = [
    { tipo: "compress_nfes", titulo: "Comprimindo notas fiscais" },
    { tipo: "transf_nfes", titulo: "Transferindo notas p/ compressão" },
  ];

  async function getTasks() {
    var check = true;
    while (check) {
      await timer(1000);
      if (status !== "downloading") {
        axios.get(`${uri}/tasks/${projeto}`).then((res) => {
          var result = res.data.filter((obj) =>
            ["compress_nfes", "transf_to_compress"].includes(obj.tipo)
          );
          check = result.some((obj) => obj.status !== "done");
          var task_find = result.find((obj) => obj.status === "doing");
          if (task_find !== undefined && task_find !== null) {
            setProgress(task_find.progress);
            setStatus(task_find.tipo);
            setTasks(result);
          }
        });
      } else {
        check = false;
      }
    }
    if (!check && status !== "downloading") {
      setStatus("downloading");
      setProgress(0);
    }
  }

  useEffect(() => {
    if (projeto !== undefined && status === "compressing") {
      getTasks();
    }
  }, [projeto, status]);

  useEffect(() => {
    if (status === "downloading") {
      downloadFiles();
    }
  }, [status]);

  return (
    <TaskContext.Provider
      value={{
        setStatus,
        status,
        setTasks,
        tasks,
        estados,
        progress,
        downloadFiles,
        startCompress,
      }}
    >
      {children}
    </TaskContext.Provider>
  );
}

export function useTask() {
  const context = useContext(TaskContext);
  const {
    setStatus,
    status,
    setTasks,
    tasks,
    estados,
    progress,
    downloadFiles,
    startCompress,
  } = context;
  return {
    setStatus,
    status,
    setTasks,
    tasks,
    estados,
    progress,
    downloadFiles,
    startCompress,
  };
}

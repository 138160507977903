import React from "react";
import { useEffect, useState } from "react";
import { CardContainer, Header, Content, Infos, Card } from "./style";
import {
  AiOutlineClose,
  AiOutlineCloudDownload,
  AiOutlineFileSearch,
} from "react-icons/ai";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import { HiDownload } from "react-icons/hi";
import { RiChatDeleteLine } from "react-icons/ri";
import { TiDeleteOutline } from "react-icons/ti";
import { BsCalendarDate, BsDownload } from "react-icons/bs";
import { RxEyeNone } from "react-icons/rx";
import axios from "axios";
import { useProjetos } from "../../../../hooks/projetos";
import ContentLoader from "react-content-loader";

const uri = process.env.REACT_APP_API_URI;

export default function InfosReport() {
  const [ano, setAno] = useState(new Date().getFullYear());
  const [mes, setMes] = useState(new Date().getMonth());
  const [dates, setDates] = useState([]);
  const [infos, setInfos] = useState();
  const [loading, setLoading] = useState(true);
  const { projeto } = useProjetos();

  useEffect(() => {
    axios
      .post(`${uri}/pro/reports/list/${projeto}`, { mes, ano })
      .then((res) => {
        setDates(res.data.data);
        setInfos(res.data.infos);
        setLoading(false);
      });
  }, [ano, mes]);

  useEffect(() => {
    console.log(dates);
  }, [dates]);

  function nextMes() {
    setLoading(true);
    if (mes + 1 !== 12) {
      setMes(mes + 1);
    } else {
      setMes(0);
      setAno(ano + 1);
    }
  }

  function prevMes() {
    setLoading(true);
    if (mes - 1 !== -1) {
      setMes(mes - 1);
    } else {
      setMes(11);
      setAno(ano - 1);
    }
  }

  function handleClose() {
    document.getElementById("blackscreen").classList.toggle("ativ");
  }

  function getDaysInMonthUTC(month, year) {
    var date = new Date(Date.UTC(year, month, 1));
    var days = [];
    while (date.getUTCMonth() === month) {
      days.push(new Date(date));
      date.setUTCDate(date.getUTCDate() + 1);
    }
    return days;
  }

  const meses = [
    { num: 0, nome: "JANEIRO" },
    { num: 1, nome: "FEVEREIRO" },
    { num: 2, nome: "MARÇO" },
    { num: 3, nome: "ABRIL" },
    { num: 4, nome: "MAIO" },
    { num: 5, nome: "JUNHO" },
    { num: 6, nome: "JULHO" },
    { num: 7, nome: "AGOSTO" },
    { num: 8, nome: "SETEMBRO" },
    { num: 9, nome: "OUTUBRO" },
    { num: 10, nome: "NOVEMBRO" },
    { num: 11, nome: "DEZEMBRO" },
  ];

  function DownloadButton() {
    const { projeto } = useProjetos();

    const handleDownload = async () => {
      try {
        const response = await axios.post(
          `${uri}/pro/reports/download/${projeto}`,
          { ano, mes },
          { responseType: "blob" }
        );
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `report-${mes}-${ano}.zip`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        console.error("Error downloading the report:", error);
      }
    };

    return <BsDownload onClick={handleDownload} />;
  }

  function randomInt(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  function Loader() {
    return (
      <CardContainer>
        <Header>
          <div className="btn-close" onClick={handleClose}>
            <AiOutlineClose />
          </div>
          <div className="header-text">
            <p> Emitidas</p>
          </div>
          <div className="btn-download"></div>
        </Header>
        <Infos>
          <Card>
            <div className="head">
              <AiOutlineFileSearch />
              <p>Notas</p>
            </div>
            <div className="content">
              <ContentLoader speed={2} width={680} height={35}>
                <rect
                  x="15"
                  y="10"
                  rx="3"
                  ry="3"
                  width={randomInt(40, 70)}
                  height="10"
                />
              </ContentLoader>
            </div>
          </Card>
          <Card>
            <div className="head">
              <AiOutlineCloudDownload />
              <p>Baixadas</p>
            </div>
            <div className="content">
              <ContentLoader speed={2} width={680} height={35}>
                <rect
                  x="15"
                  y="10"
                  rx="3"
                  ry="3"
                  width={randomInt(40, 70)}
                  height="10"
                />
              </ContentLoader>
            </div>
          </Card>
          <Card>
            <div className="head">
              <RxEyeNone />
              <p>Faltantes</p>
            </div>
            <div className="content">
              <ContentLoader speed={2} width={680} height={35}>
                <rect
                  x="15"
                  y="10"
                  rx="3"
                  ry="3"
                  width={randomInt(40, 70)}
                  height="10"
                />
              </ContentLoader>
            </div>
          </Card>
          <Card>
            <div className="head">
              <TiDeleteOutline />
              <p>Canceladas</p>
            </div>
            <div className="content">
              <ContentLoader speed={2} width={680} height={35}>
                <rect
                  x="15"
                  y="10"
                  rx="3"
                  ry="3"
                  width={randomInt(40, 70)}
                  height="10"
                />
              </ContentLoader>
            </div>
          </Card>
        </Infos>
        <Content>
          <div className="header">
            <div className="btn" onClick={prevMes}>
              <FaLongArrowAltLeft />
            </div>

            <ContentLoader speed={2} width={150} height={35}>
              <rect x="0" y="10" rx="3" ry="3" width="150" height="10" />
            </ContentLoader>

            <div className="btn" onClick={nextMes}>
              <FaLongArrowAltRight />
            </div>
          </div>

          <div className="table-header">
            <div className="table-item">
              <BsCalendarDate />
              <p>Data</p>
            </div>
            <div className="table-item">
              <AiOutlineFileSearch />
              <p>Notas</p>
            </div>
            <div className="table-item">
              <AiOutlineCloudDownload />
              <p>Baixadas</p>
            </div>
            <div className="table-item">
              <RxEyeNone />
              <p>Faltantes</p>
            </div>
            <div className="table-item">
              <TiDeleteOutline />
              <p>Canceladas</p>
            </div>
          </div>
          <div className="display">
            {Array.from({ length: 19 }, (_, i) => (
              <div className="items" key={i}>
                {Array.from({ length: 5 }, (_, i) => (
                  <div className="item" key={i}>
                    <ContentLoader speed={2} width={680} height={35}>
                      <rect
                        x="15"
                        y="14"
                        rx="3"
                        ry="3"
                        width={randomInt(30, 45)}
                        height="7"
                      />
                    </ContentLoader>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </Content>
      </CardContainer>
    );
  }

  return !loading && infos !== undefined && dates !== undefined ? (
    <CardContainer>
      <Header>
        <div className="btn-close" onClick={handleClose}>
          <AiOutlineClose />
        </div>
        <div className="header-text">
          <p> Emitidas</p>
        </div>
        <div className="btn-download">
          <DownloadButton />
        </div>
      </Header>
      <Infos>
        <Card>
          <div className="head">
            <AiOutlineFileSearch />
            <p>Notas</p>
          </div>
          <div className="content">
            <h2>{infos.total}</h2>
          </div>
        </Card>
        <Card>
          <div className="head">
            <AiOutlineCloudDownload />
            <p>Baixadas</p>
          </div>
          <div className="content">
            <h2>{infos.baixadas}</h2>
          </div>
        </Card>
        <Card>
          <div className="head">
            <RxEyeNone />
            <p>Faltantes</p>
          </div>
          <div className="content">
            <h2>{infos.faltantes}</h2>
          </div>
        </Card>
        <Card>
          <div className="head">
            <TiDeleteOutline />
            <p>Canceladas</p>
          </div>
          <div className="content">
            <h2>{infos.canceladas}</h2>
          </div>
        </Card>
      </Infos>
      <Content>
        <div className="header">
          {!(mes === 0 && ano === new Date().getFullYear()) && (
            <div className="btn" onClick={prevMes}>
              <FaLongArrowAltLeft />
            </div>
          )}
          <h2>{`${meses.find((obj) => obj.num === mes).nome} ${ano}`}</h2>
          {!(
            mes === new Date().getMonth() && ano === new Date().getFullYear()
          ) && (
            <div className="btn" onClick={nextMes}>
              <FaLongArrowAltRight />
            </div>
          )}
        </div>

        <div className="table-header">
          <div className="table-item">
            <BsCalendarDate />
            <p>Data</p>
          </div>
          <div className="table-item">
            <AiOutlineFileSearch />
            <p>Notas</p>
          </div>
          <div className="table-item">
            <AiOutlineCloudDownload />
            <p>Baixadas</p>
          </div>
          <div className="table-item">
            <RxEyeNone />
            <p>Faltantes</p>
          </div>
          <div className="table-item">
            <TiDeleteOutline />
            <p>Canceladas</p>
          </div>
        </div>
        <div className="display">
          {dates !== undefined &&
            dates.map((obj, index) => (
              <div className="items" key={index}>
                <div className="item">
                  <p>{obj.dia}</p>
                </div>
                <div className="item">
                  <p>{obj.total}</p>
                </div>
                <div className="item">
                  <p>{obj.baixadas}</p>
                </div>
                <div className="item">
                  {" "}
                  <p>{obj.faltantes}</p>
                </div>
                <div className="item">
                  <p>{obj.canceladas}</p>
                </div>
              </div>
            ))}
        </div>
      </Content>
    </CardContainer>
  ) : (
    <Loader />
  );
}

import { useEffect, useState } from "react";
import { useBlackScreen } from "../../hooks/blackscreen";
import { Container } from "./style";
import Infos from "./components/infos";
import Filtro from "./components/filtro";
import NewUpdate from "./components/updates";
import NewEmail from "./components/email";
import NFe from "./components/nfe";
import SpedsReport from "./components/SpedsReport";
import Revisor from "./components/Revisor";
import InfosReport from "./components/InfosReport";
import InfosViaNuvem from "./components/InfosViaNuvem";
import Download from "./components/download";
import CancelReport from "./components/canceladas";

import DownloadProvider from "../../../src/hooks/download";

export default function BlackScreen() {
  const { status } = useBlackScreen();

  const stages = [
    { tipo: "modal", component: <Infos /> },
    { tipo: "download", component: <Download /> },
    { tipo: "filtro", component: <Filtro /> },
    { tipo: "new_update", component: <NewUpdate /> },
    { tipo: "new_email", component: <NewEmail /> },
    { tipo: "nfe", component: <NFe /> },
    { tipo: "speds", component: <SpedsReport /> },
    { tipo: "revisor", component: <Revisor /> },
    { tipo: "report", component: <InfosReport /> },
    { tipo: "canceladas", component: <CancelReport /> },
    { tipo: "via_nuvem", component: <InfosViaNuvem /> },
  ];

  return (
    <Container id="blackscreen" className="">
      <DownloadProvider>
        {stages.find((obj) => obj.tipo === status).component}
      </DownloadProvider>
    </Container>
  );
}

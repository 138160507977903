import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 3em);
  display: flex;
  flex-direction: column;
  align-items: center;
  .content {
    overflow: overlay;
    width: 100%;
    height: max-content;
    max-height: calc(100% - 2em);
    display: flex;
    flex-wrap: wrap;
    margin-top: 3em;

    &.top {
      margin-top: 2em;
    }
  }
  .btn {
    cursor: pointer;
    display: flex;
    border-radius: 5px;
    border: 1px solid rgba(0, 100, 200, 0.3);
    position: fixed;
    left: 0;
    background-color: white;
    margin: 1em;
    padding: 5px 15px;
    padding-right: 25px;
    align-items: center;
    justify-content: center;

    p {
      margin-left: 10px;
      font-size: 0.9em;
    }
  }

  .tameplate {
    margin-left: 12em;
  }
`;

export const Block = styled.div`
  width: calc(100% / 6);
  height: 30em;
  font-size: 0.8em;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 1.5em;

  cursor: pointer;
`;
export const Image = styled.div`
  height: 10em;
  width: 100%;
  display: flex;
  background-size: cover;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;
export const Title = styled.div`
  display: flex;
  height: calc(100% - 7em);
  flex-direction: column;
  align-items: center;

  .content {
    margin-top: 10px;
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }
`;
export const Date = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 2.3em;
  width: 100%;
  bottom: 10px;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  p {
    font-size: 0.9em;
    margin: 10px;
    opacity: 0.5;
  }

  .btn-container {
    display: flex;

    .btns {
      width: 2.3em;
      height: 2.3em;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.5;
      color: red;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
      svg {
        font-size: 1.2em;
      }
    }

    .share {
      color: black;
    }
  }
`;

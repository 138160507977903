import styled from "styled-components";

export const Projetos = styled.div`
  width: 100%;
  height: 8em;
  max-height: max-content;
  display: flex;
  flex-direction: column;

  .head {
    width: 100%;
    height: 2em;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background-color: white;

    p {
      font-size: 0.8em;
      margin-left: 10px;
    }

    svg {
      margin-left: 10px;
      font-size: 0.8em;
    }
    .header {
      color: black;
      display: flex;
      height: 2em;
      align-items: center;
    }
    .reset {
      transform: translateX(-10px);
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      justify-content: center;
      align-items: center;
      height: 2em;
      min-width: 1.5em;
      display: flex;
      opacity: 0.7;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
      svg {
        font-size: 0.8;
      }
    }
  }

  .display {
    width: 100%;
    height: 10em;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    overflow: overlay;
    .item {
      cursor: pointer;

      width: 100%;
      height: 3em;
      display: flex;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      background-color: white;
      opacity: 0.5;
      transition: all 0.2s;
      &:hover,
      &.ativ {
        opacity: 1;
      }

      .thumb {
        width: 3em;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgba(0, 0, 0, 0.1);

        svg {
          opacity: 0.5;
        }
      }
    }
  }
  .work-areas {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .head {
      height: 1.5em;
      display: flex;
    }
  }
`;

export const Infos = styled.div`
  width: 100%;
  height: calc(100% - 16em);
  display: flex;
  flex-direction: column;

  .head {
    width: 100%;
    height: 1.5em;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background-color: white;
    &.btn {
      height: 1.5em;
      background-color: rgba(245, 245, 245);
      cursor: pointer;
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }

    p {
      font-size: 0.8em;
      margin-left: 10px;
    }

    svg {
      margin-left: 10px;
      font-size: 0.8em;
    }
  }

  .infos {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;

    .info {
      width: 100%;
      height: 3.5em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 0.5em;
      background-color: rgba(245, 245, 245);

      .content {
        width: 95%;
        height: 90%;
        display: flex;
        flex-direction: column;

        .tag {
          width: 100%;
          height: 1.5em;
          display: flex;
          align-items: center;

          p {
            font-size: 0.7em;
            opacity: 0.6;
            font-weight: 600;
          }
        }

        .input-container {
          width: 100%;
          height: calc(100% - 1.5em);
          display: flex;
          align-items: center;

          input {
            width: 90%;
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, 0.1);
          }
        }
      }
    }

    .send {
      width: 100%;
      height: 4em;
      display: flex;
      align-items: center;
      justify-content: center;

      .btn {
        width: max-content;
        height: max-content;
        background-color: rgba(15, 15, 15);
        color: white;
        border-radius: 5px;
        cursor: pointer;
        opacity: 0.8;

        &:hover {
          opacity: 1;
        }

        h2 {
          font-size: 1em;
          padding: 5px 40px;
        }
      }
    }
  }
`;

import styled from "styled-components";

export const UserContainer = styled.div`
  margin-top: 5.6em;
  background-color: rgba(245, 245, 245, 0.8);
  width: 23em;
  height: calc(100vh - 5.6em);
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(0, 0, 0, 0.03);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.2s;
  position: fixed;
  z-index: 2;
  background-color: white;
  right: 0;
  transform: translateX(26em);
  &.unset {
    margin-top: 3em;
    height: calc(100vh - 2.6em);
  }

  &.ativ {
    transform: translateX(0);
  }

  .header {
    width: 100%;
    min-height: 5em;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .btn {
      width: 1.5em;
      height: 1.5em;
      border: 1px solid rgba(0, 0, 0, 0.1);
      position: fixed;
      right: 0;
      background-color: white;
      cursor: pointer;
      opacity: 0.7;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        opacity: 1;
      }
    }

    .thumb {
      width: 5em;
      height: 100%;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;

      .circle {
        width: 3em;
        height: 3em;
        border-radius: 100%;
        border: 1px solid rgba(0, 150, 200, 0.3);
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .content {
    width: calc(100% - 5em);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;

    p {
      line-height: 20px;
      font-size: 0.9em;
      font-weight: 600;
      opacity: 0.5;

      &.cnpj {
        font-weight: 500;
        font-size: 0.8em;
      }
    }
  }
`;

export const Selector = styled.div`
  width: 100%;
  height: 1.5em;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  div {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s;
    opacity: 0.6;
    background-color: rgba(245, 245, 245);

    &.ativ {
      opacity: 1;
      border-bottom: 1px solid rgba(0, 200, 250, 0.2);
      background-color: white;
    }

    &:first-child {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
    }

    p {
      opacity: 0.8;
      font-size: 0.75em;
    }
  }
`;

export const Display = styled.div`
  width: 100%;
  max-height: calc(100% - 6.5em);
`;

import styled from "styled-components";

export const Filter = styled.div`
  width: 30em;
  height: 4em;
  display: flex;
  margin-left: 1em;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1em;
  overflow: hidden;

  .start {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;

    p {
      opacity: 0.6;
      margin-left: 30px;
      font-size: 0.8em;
      &:last-child {
        font-size: 1em;
        font-weight: 500;
      }
    }
  }
  .end {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;

    p {
      opacity: 0.6;
      margin-left: 30px;
      font-size: 0.8em;
      &:last-child {
        font-size: 1em;
        font-weight: 500;
      }
    }
  }
`;

export const Selector = styled.div`
  width: 30em;
  height: max-content;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  margin: 1em;
  margin-top: 0;

  .item {
    width: calc(100% / 6 - 1px);
    height: 2.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s;
    opacity: 0.7;

    &:nth-child(6n) {
      border-right: none;
    }
    &:nth-last-child(-n + 6) {
      border-bottom: none;
    }

    p {
      font-size: 0.85em;
      opacity: 0.8;
    }

    &:hover,
    &.ativ {
      opacity: 1;
      background-color: rgba(245, 245, 245, 1);
    }
  }
`;
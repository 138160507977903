import { useEffect, useState, useContext, useRef, useCallback } from "react";
import { Card, BodyBox, Content, Head, Infos, Header } from "./style";
import axios from "axios";
import { useBlackScreen } from "../../../../../../hooks/blackscreen";
import { useNfe } from "../../../../../../hooks/nfe";
import MyLoader from "../ContentLoader";
import Loading from "../../../Loading";
import img from "../../../Loading/image.png";
import { useProjetos } from "../../../../../../hooks/projetos";
import { BsCloudDownload } from "react-icons/bs";
import FileDownload from "js-file-download";
import reduce from "../../../../../../utils/reduce";

const uri = process.env.REACT_APP_API_URI;

export default function Canceladas() {
  const { openBlackScreen } = useBlackScreen();
  const {
    setNfes,
    setNfe,
    nfes,
    setInfos,
    dataValues,
    setLoading,
    loading,
    setPage,
    page,
    setInfo,
    infos,
    query,
    setHasmore,
    hasMore,
    getData,
    useNFeSearch,
  } = useNfe();

  const { projeto } = useProjetos();

  const observer = useRef();

  useEffect(() => {
    setNfes([]);
  }, [projeto]);

  const last_element = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (hasMore) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  function reduzir(str, n) {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  }

  function formatNum(num) {
    return `R$ ${parseFloat(num).toLocaleString("br")}`;
  }

  function openModal(item) {
    openBlackScreen("modal");
    setNfe(item.chv);
    setInfos(item);
  }

  function downloadFile(file) {
    axios
      .post(`${uri}/icms-download/${projeto}/${file}`, {
        responseType: "blob",
      })
      .then((res) => {
        FileDownload(res.data, `${file}.xml`);
      });
  }

  useNFeSearch(projeto, query);

  return (
    <>
      <BodyBox>
        {!loading ? (
          <>
            {" "}
            {nfes.map(
              (item) =>
                item !== null && (
                  <Card ref={last_element} key={item.chv}>
                    <Content>
                      <div className="content">
                        <Head>
                          <div className="infos">
                            <input type="checkbox"></input>
                            <h2>
                              {item.modelo === "55" ? "NFe" : "CTe"} -{" "}
                              {item.num}{" "}
                            </h2>
                            <div className="tag">
                              <p>Cancelada</p>
                            </div>
                          </div>
                          <div className="infos">
                            <div
                              className="btn"
                              onClick={() => downloadFile(item.chv)}
                            >
                              <BsCloudDownload />
                            </div>
                          </div>
                        </Head>
                        <Infos>
                          <div
                            className="thumb"
                            onClick={() => openModal(item)}
                          >
                            <img src={img}></img>
                          </div>
                          <div className="info">
                            <div className="tag">
                              <p>Emitente</p>
                            </div>
                            <div className="line">
                              <h5>ESTABELECIMENTO:</h5>
                              <p>{reduzir(item.emi.nome, 30)}</p>
                            </div>
                            <div className="line">
                              <h5>CNPJ:</h5>
                              <p>{item.emi.cnpj}</p>
                            </div>
                            <div className="line">
                              <h5>DATA EMISSÃO:</h5>
                              <p>
                                {new Date(item.data).toLocaleDateString(
                                  "pt-BR",
                                  {
                                    timeZone: "UTC",
                                  }
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="info">
                            <div className="tag">
                              <p>Destinatário</p>
                            </div>
                            <div className="line">
                              <h5>NOME:</h5>
                              <p>{item.dest.nome}</p>
                            </div>
                            <div className="line">
                              <h5>CNPJ:</h5>
                              <p>{item.dest.cnpj}</p>
                            </div>
                            <div className="line">
                              <h5>VALOR:</h5>
                              <p>{formatNum(item.valor)}</p>
                            </div>
                          </div>
                          <div className="info">
                            <div className="tag">
                              <p>Informações da nota</p>
                            </div>
                            <div className="line">
                              <h5>CHAVE:</h5>
                              <p>{item.chv}</p>
                            </div>
                            <div className="line">
                              <h5>QUANTIDADE DE ITENS:</h5>
                              <p>{item.itens}</p>
                            </div>
                            <div className="line">
                              <h5>CFOP:</h5>
                              <p>{item.cfop}</p>
                            </div>
                            <div className="line">
                              <h5>NAT. OP:</h5>
                              <p>{item.natOp}</p>
                            </div>
                          </div>
                          <div className="info small">
                            <div className="tag">
                              <p>Impostos</p>
                            </div>
                            <div className="line">
                              <h5>ICMS:</h5>
                              <p>{formatNum(item.impostos.icms)}</p>
                            </div>
                            <div className="line">
                              <h5>ICMS ST:</h5>
                              <p>{formatNum(item.impostos.st)}</p>
                            </div>
                            <div className="line">
                              <h5>PIS:</h5>
                              <p>{formatNum(item.impostos.ipi)}</p>
                            </div>
                            <div className="line">
                              <h5>COFINS:</h5>
                              <p>{formatNum(item.impostos.cofins)}</p>
                            </div>
                          </div>
                        </Infos>
                      </div>
                    </Content>
                  </Card>
                )
            )}
            {hasMore && <MyLoader load={2} />}
          </>
        ) : (
          <MyLoader />
        )}
      </BodyBox>
    </>
  );
}

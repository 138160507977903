import styled from "styled-components";

export const Container = styled.div`
  height: 2.5em;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 1px;
  justify-content: space-between;

  .infos {
    width: max-content;
    height: 100%;
    display: flex;
    align-items: center;

    .btn-filter {
      width: 2.5em;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid rgba(0, 180, 0);

      opacity: 0.5;
      cursor: pointer;

      &.yellow {
        border-color: #f2a102;
      }

      &.blue {
        border-color: #00d1f6;
      }

      &:hover,
      &.ativ {
        opacity: 1;
        background-color: white;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
    .btn-download {
      margin-right: 1em;
      width: 2.5em;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid rgba(0, 180, 0);

      opacity: 0.5;
      cursor: pointer;

      &.yellow {
        border-color: #f2a102;
      }

      &.blue {
        border-color: #00d1f6;
      }

      &:hover,
      &.ativ {
        opacity: 1;
        background-color: white;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }
    }

    .info {
      margin-right: 2em;
      font-size: 0.7em;
      font-weight: 600;
      color: #0f3d67;
    }
  }

  .btn {
    width: 15em;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(0, 180, 0);
    opacity: 0.5;
    cursor: pointer;
    
    &.yellow {
      border-color: #f2a102;
    }
    &.red {
      border-color: rgba(230, 0, 0);
    }

    &.blue {
      border-color: #00d1f6;
    }

    &:hover,
    &.ativ {
      opacity: 1;
      background-color: white;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .content {
      width: 100%;
      height: 1em;
      display: flex;
      align-items: center;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      justify-content: center;
      p {
        font-size: 0.7em;
      }
    }

    .thumb {
      width: max-content;

      display: flex;
      align-items: center;
      border-radius: 20px;
      background-color: rgba(0, 180, 0);
      color: white;
      margin-right: 10px;

      p {
        padding: 0 7px;
        font-weight: 600;
      }
    }
  }
`;

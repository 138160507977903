import styled from "styled-components";

export const Container = styled.div`
  width: 25em;
  height: max-content;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;

  .head {
    height: 2em;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    width: 100%;

    .btn {
      width: 2em;
      height: 2em;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      overflow: hidden;
      cursor: pointer;
      svg {
        font-size: 1.5em;
      }
    }

    .head-info {
      width: calc(100% - 3em);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      h2 {
        font-size: 0.8em;
      }
    }
  }

  .infos {
    width: 100%;
    height: calc(100% - 2em);
    display: flex;
    flex-direction: column;

    .info {
      width: 100%;
      min-height: 4em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 0.5em;

      .content {
        width: 95%;
        height: 90%;
        display: flex;
        flex-direction: column;

        .tag {
          width: 100%;
          height: 1.5em;
          display: flex;
          align-items: center;

          p {
            font-size: 0.7em;
            opacity: 0.8;
            font-weight: 600;
          }
        }

        .input-container {
          width: 100%;
          height: calc(100% - 1.5em);
          display: flex;
          align-items: center;

          input {
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, 0.1);
          }

          textarea {
            resize: none;
            width: 100%;
            height: 6em !important;
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            outline: none;
          }
        }
      }
    }
  }

  .send {
    width: 100%;
    height: 5em;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      width: max-content;
      height: max-content;
      background-color: rgba(15, 15, 15);
      color: white;
      border-radius: 5px;
      cursor: pointer;

      h2 {
        font-size: 1.1em;
        padding: 5px 40px;
      }
    }
  }
`;

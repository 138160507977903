import React from "react";
import ReactDOM from "react-dom/client";
import Rotas from "./routes";
import BlackScreenProvider from "./hooks/blackscreen";
import NfeProvider from "./hooks/nfe";
import ProjetoProvider from "./hooks/projetos";
import UserProvider from "./hooks/user";
import UpdateProvider from "./hooks/updates";

import axios from "axios";
import TaskProvider from "./hooks/tasks";
import ChatProvider from "./hooks/chat";
import FilesProvider from "./hooks/files";
axios.interceptors.request.use((request) => {
  request.headers.user = `${localStorage.getItem("user_id")}`;
  request.headers.app = "Pro";
  return request;
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ProjetoProvider>
      <ChatProvider>
        <UserProvider>
          <FilesProvider>
            <NfeProvider>
              <UpdateProvider>
                <BlackScreenProvider>
                  <TaskProvider>
                    <Rotas />
                  </TaskProvider>
                </BlackScreenProvider>
              </UpdateProvider>
            </NfeProvider>
          </FilesProvider>
        </UserProvider>
      </ChatProvider>
    </ProjetoProvider>
  </React.StrictMode>
);

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 3em);
  display: flex;
  justify-content: center;
  align-items: center;


`;

export const Post = styled.div`
  width: 80%;
  height: 90%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2em;

  .header {
    width: 100%;
    height: 2.5em;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .btn {
      width: 2.5em;
      height: 2.5em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
      svg {
        opacity: .5;
      }
    }

    .content {
      h2 {
        margin-left: 1em;
      }
    }
  }

  .display {
    width: 100%;
    height: calc(100% - 2.5em);
    overflow: overlay;
    display: flex;
    justify-content: center;

    .post-content {
      width: 90%;
      padding: 2em 0;
      
    }

  }
`;

import React, { useState, useEffect } from "react";
import axios from "axios";
import ContentLoader from "react-content-loader";
import {
  Header,
  Block,
  FalseContainer,
  Choice,
  Card,
  Info,
  Tabela,
  Grafico,
  Value,
  Container,
} from "./style";
import { MdClose } from "react-icons/md";
import { GrUpdate } from "react-icons/gr";
import { CiExport } from "react-icons/ci";
import { BiCube } from "react-icons/bi";
import { HiDownload } from "react-icons/hi";
import { BsTable } from "react-icons/bs";
import { AiOutlineFileSearch, AiOutlineFileExcel } from "react-icons/ai";
import { IoCubeOutline } from "react-icons/io5";
import { useProjetos } from "../../../../../../hooks/projetos";

const uri = process.env.REACT_APP_API_URI;

export default function Relatorio({ setUpdate, loading, setLoading }) {
  const { projeto } = useProjetos();

  const [data, setData] = useState([]);
  const [faltantesPorCNPJ, setFaltantesPorCNPJ] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [popUp, setPopUp] = useState(false);
  const [graphData, setGraphData] = useState({ activeTab: null, data: [] });
  const [showDownloadOptions, setShowDownloadOptions] = useState(false);

  const files = [
    {
      name: "Reports XLSX",
      url: `${uri}/pro/revisor/download`,
      icon: <AiOutlineFileExcel />,
      extension: "zip",
      type: "reports",
    },
  ];

  async function downloadFile(url, folder, type, extension) {
    const downloadUrl = `${url}/${projeto}?folder=${folder}&type=${type}`;
    try {
      const response = await fetch(downloadUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("app_token")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const fileURL = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", `${type}.${extension}`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(fileURL);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  }
  function togglePopUp() {
    setPopUp((prev) => !prev);
  }
  function handleDownload(file) {
    downloadFile(file.url, file.type, file.extension);
    setShowDownloadOptions(false);
  }
  function downloadReportsXlsx(folder) {
    downloadFile(files[0].url, folder, files[0].type, files[0].extension);
  }

  useEffect(() => {
    setLoading(true);
    axios
      .post(`${uri}/pro/revisor/report-sped/${projeto}`)
      .then((res) => {
        setData(res.data);
        setActiveTab(0);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Erro ao carregar dados:", error);
        setLoading(false);
      });
  }, [projeto]);

  useEffect(() => {
    if (data.length > 0 && data[activeTab]) {
      const faltantesData = data[activeTab].data || [];
      const sortedData = faltantesData
        .sort((a, b) => b.Faltantes - a.Faltantes)
        .slice(0, 4);
      setGraphData({ activeTab, data: sortedData });
    } else {
      setGraphData({ activeTab: null, data: [] });
    }
  }, [activeTab, data]);

  function handleChoice() {
    setPopUp((prev) => !prev);
  }

  function renderSped() {
    setLoading(true);
    setPopUp(false);

    axios
      .post(`${uri}/pro/revisor/report-sped/${projeto}`)
      .then((res) => {
        setData(res.data);
        setActiveTab(0);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Erro ao carregar dados:", error);
        setLoading(false);
      });
  }

  function renderReport() {
    setLoading(true);
    setPopUp(false);

    axios.post(`${uri}/pro/revisor/report-sped/${projeto}`).then((res) => {
      if (res.data && res.data.length !== 0) {
        setData(res.data);
        setActiveTab(0);
        setLoading(false);
      }
    });
  }

  function handleClose() {
    document.getElementById("blackscreen").classList.remove("ativ");
  }

  function handleChoose(obj) {
    console.log("Item chosen:", obj);
  }

  function calcPerc(faltante, total) {
    if (total === 0) return "0%";
    const result = parseFloat((faltante / total) * 100).toFixed(2);
    return `${result}%`;
  }
  function calcRest() {
    if (!data[activeTab] || !data[activeTab].infos) return 0;

    const totalFaltantes = data[activeTab].infos.faltantes;
    const sumGraphData = graphData.data.reduce(
      (sum, obj) => sum + obj.Faltantes,
      0
    );

    return Math.max(0, totalFaltantes - sumGraphData);
  }

  function getColor(index) {
    const colors = [
      "#46b000",
      "#2344b0",
      "#6723b0",
      "#b02359",
      "#23a0b0",
      "#9fde0d",
      "#de6b0d",
      "#0029b0",
      "#b00058",
      "#e9fa00",
      "#64cf00",
      "#64cf00",
      "#12bfde",
      "#46b000",
      "#2344b0",
      "#6723b0",
      "#b02359",
      "#23a0b0",
      "#9fde0d",
      "#de6b0d",
      "#0029b0",
      "#b00058",
      "#e9fa00",
      "#64cf00",
      "#64cf00",
      "#12bfde",
      "#46b000",
      "#2344b0",
      "#6723b0",
      "#b02359",
      "#23a0b0",
      "#9fde0d",
      "#de6b0d",
      "#0029b0",
      "#b00058",
      "#e9fa00",
      "#64cf00",
      "#64cf00",
      "#12bfde",
    ];

    return index >= colors.length
      ? colors[index % colors.length]
      : colors[index];
  }

  function Loader() {
    return (
      <Container>
        <Block className="table">
          <Header>
            <div className="close" onClick={handleClose}>
              <MdClose />
            </div>
            <div className="text">
              <p>Ferramentas</p>
            </div>
            <div className="export" style={{ opacity: "0" }}></div>
          </Header>
          <Value>
            <div className="toggle">
              <div id="reports" className="ativ" onClick={renderReport}>
                <BiCube />
                <p>Reports</p>
              </div>
            </div>
            <div className="update" style={{ opacity: "0" }}></div>
          </Value>
          <FalseContainer>
            <Info>
              <Card>
                <div className="head">
                  <AiOutlineFileSearch />
                  <p>Total</p>
                </div>
                <div className="content">
                  <ContentLoader speed={2} width={300} height={200}>
                    <rect x="20" y="90" rx="5" ry="5" width="80" height="15" />
                  </ContentLoader>
                </div>
              </Card>
              <Card>
                <div className="head">
                  <AiOutlineFileSearch />
                  <p>Faltantes</p>
                </div>
                <div className="content">
                  <ContentLoader speed={2} width={300} height={200}>
                    <rect x="20" y="90" rx="5" ry="5" width="80" height="15" />
                  </ContentLoader>
                </div>
              </Card>
              <Card>
                <div className="head">
                  <AiOutlineFileSearch />
                  <p>Encontradas</p>
                </div>
                <div className="content">
                  <ContentLoader speed={2} width={300} height={200}>
                    <rect x="20" y="90" rx="5" ry="5" width="80" height="15" />
                  </ContentLoader>
                </div>
              </Card>
            </Info>

            <div className="container">
              <Grafico>
                <div className="index">
                  <div className="head">
                    <p>Index</p>
                  </div>
                  <div className="display">
                    {graphData.data && graphData.data.length > 0 ? (
                      graphData.data.map((obj, index) => (
                        <div className="item" key={`${obj.Nome}-${index}`}>
                          <div className="thumb">
                            <IoCubeOutline
                              style={{
                                color: getColor(index),
                              }}
                            />
                          </div>
                          <div className="content">
                            <p>{obj.Nome}</p>
                            {obj.CNPJ !== undefined && (
                              <p className="cnpj">{obj.CNPJ}</p>
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>Nenhum dado disponível</p>
                    )}
                  </div>
                </div>
                {data[activeTab]?.infos?.faltantes && (
                  <div className="grafico" key={`grafico-${activeTab}`}>
                    <div className="head">
                      <p>Faltantes por CNPJ</p>
                    </div>
                    <div className="charts">
                      {graphData.data && graphData.data.length > 0 ? (
                        graphData.data.map((obj, index) => (
                          <div className="column" key={`${obj.Nome}-${index}`}>
                            <div
                              className="bar"
                              style={{
                                backgroundColor: getColor(index),
                                height: `calc(${calcPerc(
                                  obj.Faltantes,
                                  data[activeTab]?.infos?.faltantes || 1
                                )} - 1em)`,
                              }}
                            >
                              <div className="tag">
                                <p>
                                  {obj.Faltantes} |{" "}
                                  {calcPerc(
                                    obj.Faltantes,
                                    data[activeTab]?.infos?.faltantes || 1
                                  )}{" "}
                                  | {obj.Nome}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>Nenhum dado disponível para exibir no gráfico</p>
                      )}
                      {graphData.data && graphData.data.length < 5 && (
                        <div className="column" key="outros">
                          <div
                            className="bar"
                            style={{
                              backgroundColor: getColor(graphData.data.length),
                              height: `${calcPerc(
                                calcRest(),
                                data[activeTab]?.infos?.faltantes || 1
                              )}`,
                            }}
                          >
                            <div className="tag">
                              <p>
                                {calcRest()} |{" "}
                                {calcPerc(
                                  calcRest(),
                                  data[activeTab]?.infos?.faltantes || 1
                                )}
                                % | Outras
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </Grafico>
              <Tabela>
                <div className="head-table">
                  <div className="header-item name">
                    <p>Nome</p>
                  </div>
                  <div className="header-item cnpj">
                    <p>CNPJ</p>
                  </div>
                  <div className="header-item count">
                    <p>Faltantes</p>
                  </div>
                </div>
                <div className="display">
                  {graphData
                    .sort((a, b) => a.Nome.localeCompare(b.Nome))
                    .map((obj, index) => (
                      <div
                        className="items"
                        onClick={() => handleChoose(obj)}
                        key={index}
                      >
                        <div className="item name">
                          <div className="thumb">
                            <IoCubeOutline
                              style={{
                                color: getColor(index),
                              }}
                            />
                          </div>
                          <p>{obj.Nome}</p>
                        </div>
                        <div className="item cnpj">
                          <p>{obj.CNPJ}</p>
                        </div>
                        <div className="item count">
                          <p>{obj.Faltantes}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </Tabela>
            </div>
          </FalseContainer>
        </Block>
      </Container>
    );
  }

  return !loading ? (
    <Container>
      <Block className="table">
        <Header>
          <div className="close" onClick={handleClose}>
            <MdClose />
          </div>
          <div className="text">
            <p>Ferramentas</p>
          </div>
          <div className="export" onClick={togglePopUp}>
            <CiExport />
          </div>
        </Header>
        <Value>
          <div className="toggle">
            {data.map((fileData, index) => (
              <div
                key={index}
                id={`tab-${index}`}
                className={`${activeTab === index ? "ativ" : ""}`}
                onClick={() => setActiveTab(index)}
              >
                <BiCube />
                <p>{fileData.folderName}</p>
              </div>
            ))}
          </div>
          <div className="update" onClick={() => setUpdate("initial")}>
            <GrUpdate />
          </div>
        </Value>
        <FalseContainer>
          {data[activeTab] &&
            data[activeTab].infos &&
            data[activeTab].infos.faltantes && (
              <Info>
                <Card>
                  <div className="head">
                    <AiOutlineFileSearch />
                    <p>Total</p>
                  </div>
                  <div className="content">
                    <h2>{data[activeTab].infos.total}</h2>
                  </div>
                </Card>
                <Card>
                  <div className="head">
                    <AiOutlineFileSearch />
                    <p>Faltantes</p>
                  </div>
                  <div className="content">
                    <h2>{data[activeTab].infos.faltantes}</h2>
                  </div>
                </Card>
                <Card>
                  <div className="head">
                    <AiOutlineFileSearch />
                    <p>Encontradas</p>
                  </div>
                  <div className="content">
                    <h2>{data[activeTab].infos.encontradas}</h2>
                  </div>
                </Card>
              </Info>
            )}

          <div className="container">
            <Grafico>
              <div className="index">
                <div className="head">
                  <p>Index</p>
                </div>
                <div className="display">
                  {graphData.data && graphData.data.length > 0 ? (
                    graphData.data.map((obj, index) => (
                      <div className="item" key={obj.Nome}>
                        <div className="thumb">
                          <IoCubeOutline
                            style={{
                              color: getColor(index),
                            }}
                          />
                        </div>
                        <div className="content">
                          <p>{obj.Nome}</p>
                          {obj.CNPJ !== undefined && (
                            <p className="cnpj">{obj.CNPJ}</p>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>Nenhum dado disponível</p>
                  )}
                </div>
              </div>
              {data[activeTab]?.infos?.faltantes && (
                <div className="grafico">
                  <div className="head">
                    <p>Faltantes por CNPJ</p>
                  </div>
                  <div className="charts">
                    {graphData.data && graphData.data.length > 0 ? (
                      graphData.data.map((obj, index) => (
                        <div className="column" key={`${obj.Nome}-${index}`}>
                          <div
                            className="bar"
                            style={{
                              backgroundColor: getColor(index),
                              height: `calc(${calcPerc(
                                obj.Faltantes,
                                data[activeTab]?.infos?.faltantes || 1
                              )} - 1em)`,
                            }}
                          >
                            <div className="tag">
                              <p>
                                {obj.Faltantes} |{" "}
                                {calcPerc(
                                  obj.Faltantes,
                                  data[activeTab]?.infos?.faltantes || 1
                                )}{" "}
                                | {obj.Nome}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>Nenhum dado disponível para exibir no gráfico</p>
                    )}
                    {graphData.data && graphData.data.length < 5 && (
                      <div className="column" key="outros">
                        <div
                          className="bar"
                          style={{
                            backgroundColor: getColor(graphData.data.length),
                            height: `${calcPerc(
                              calcRest(),
                              data[activeTab]?.infos?.faltantes || 1
                            )}`,
                          }}
                        >
                          <div className="tag">
                            <p>
                              {calcRest()} |{" "}
                              {calcPerc(
                                calcRest(),
                                data[activeTab]?.infos?.faltantes || 1
                              )}
                              % | Outras
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Grafico>
            <Tabela>
              <div className="head-table">
                <div className="header-item name">
                  <p>Nome</p>
                </div>
                <div className="header-item cnpj">
                  <p>CNPJ</p>
                </div>
                <div className="header-item count">
                  <p>Faltantes</p>
                </div>
              </div>
              <div className="display">
                {data[activeTab]?.data && data[activeTab].data.length > 0 ? (
                  data[activeTab].data
                    .sort((a, b) => a.Nome.localeCompare(b.Nome))
                    .map((obj, index) => (
                      <div
                        className="items"
                        onClick={() => handleChoose(obj)}
                        key={`${obj.Nome}-${index}`}
                      >
                        <div className="item name">
                          <div className="thumb">
                            <IoCubeOutline
                              style={{
                                color: getColor(index),
                              }}
                            />
                          </div>
                          <p>{obj.Nome}</p>
                        </div>
                        <div className="item cnpj">
                          <p>{obj.CNPJ}</p>
                        </div>
                        <div className="item count">
                          <p>{obj.Faltantes}</p>
                        </div>
                      </div>
                    ))
                ) : (
                  <p>
                    Nenhum dado disponível para{" "}
                    {data[activeTab]?.folderName || "esta empresa"}
                  </p>
                )}
              </div>
            </Tabela>
          </div>
        </FalseContainer>
      </Block>
      {popUp && (
        <Choice>
          {files.map((file) => (
            <div className="item" key={file.name}>
              <div className="icon">{file.icon}</div>
              <div className="text">
                <p>{file.name}</p>
              </div>
              <div className="btn-download">
                <HiDownload
                  onClick={() =>
                    downloadFile(
                      file.url,
                      data[activeTab].folderName,
                      file.type,
                      file.extension
                    )
                  }
                />
              </div>
            </div>
          ))}
        </Choice>
      )}
    </Container>
  ) : (
    <Loader />
  );
}

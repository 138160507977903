import { useState, useEffect } from "react";
import { DaterContainer } from "./style";
import ContentLoader from "react-content-loader";
import getDates from "../../../../utils/getDates";
import { useProjetos } from "../../../../hooks/projetos";
import alerta from "../../../../utils/alerta";
import { toast } from "react-toastify";

export default function Dater({ setDate: setQuery }) {
  const { empresas } = useProjetos();

  const [mode, setMode] = useState("off");

  const meses = getDates().months;
  const anos = getDates().years;

  const [start, setStart] = useState({ mes: 0, ano: anos[anos.length - 1] });
  const [end, setEnd] = useState({ mes: 11, ano: new Date().getFullYear() });

  function checkClassDate(str) {
    if (str === mode) {
      return "ativ";
    }
  }

  function checkClassDateItem(value, str) {
    if (mode === "start") {
      if (str === "ano") {
        if (start.ano === parseInt(value)) {
          return "ativ";
        }
      }
      if (str === "mes") {
        if (start.mes === parseInt(value)) {
          return "ativ";
        }
      }
    }
    if (mode === "end") {
      if (str === "ano") {
        if (end.ano === parseInt(value)) {
          return "ativ";
        }
      }
      if (str === "mes") {
        if (end.mes === parseInt(value)) {
          return "ativ";
        }
      }
    }
  }

  function handleChoose(str, value) {
    var new_date;

    if (mode === "end") {
      if (str === "ano") new_date = { mes: end.mes, ano: value };
      if (str === "mes") new_date = { mes: value, ano: end.ano };
      if (
        new Date(new_date.ano, new_date.mes) < new Date(start.ano, start.mes)
      ) {
        toast.error("A data final nao pode ser menor que a inicial!");
      } else {
        setEnd(new_date);
      }
    }
    if (mode === "start") {
      if (str === "ano") new_date = { mes: start.mes, ano: value };
      if (str === "mes") new_date = { mes: value, ano: start.ano };
      if (new Date(new_date.ano, new_date.mes) > new Date(end.ano, end.mes)) {
        toast.error("A data inicial nao pode ser maior que a final!");
      } else {
        setStart(new_date);
      }
    }
  }

  useEffect(() => {
    setQuery({ date: { start, end } });
  }, [end, start]);

  function LoadPanel() {
    var x = 0;
    var result = [];

    while (x !== 6) {
      result.push(x);
      x++;
    }

    function LoadItem() {
      return (
        <div className="sped-item">
          <div className="content">
            <ContentLoader viewBox="0 0 380 70">
              {/* Only SVG shapes */}
              <rect x="20" y="25" rx="5" ry="2" width="30" height="30" />
              <rect x="80" y="25" rx="4" ry="4" width="340" height="10" />
              <rect x="80" y="45" rx="3" ry="3" width="250" height="10" />
            </ContentLoader>{" "}
          </div>
        </div>
      );
    }

    return (
      <div style={{ overflow: "hidden", height: "100%" }}>
        {result.map((obj) => (
          <LoadItem key={result.indexOf(obj)} />
        ))}
      </div>
    );
  }

  return (
    <DaterContainer>
      <div className="dater">
        <div className="dates">
          <div
            onClick={() => setMode("start")}
            className={`${checkClassDate("start")} date bar`}
          >
            <div className="content">
              <div className="infos">
                <p className="info">Início</p>
                <p className="data">
                  {start.mes !== null && meses[start.mes].str}{" "}
                  {start.ano !== null && start.ano}
                </p>
              </div>
            </div>
          </div>
          <div
            onClick={() => setMode("end")}
            className={`${checkClassDate("end")} date`}
          >
            <div className="content">
              <div className="infos">
                <p className="info">Final</p>
                <p className="data">
                  {end.mes !== null && meses[end.mes].str}{" "}
                  {end.ano !== null && end.ano}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {mode !== "off" && (
        <div className="date-picker">
          <div className="date-line">
            {anos.map((obj) => (
              <div
                key={anos.indexOf(obj)}
                className={`${checkClassDateItem(obj, "ano")} date-item`}
                onClick={() => handleChoose("ano", obj)}
              >
                <p>{obj}</p>
              </div>
            ))}
          </div>
          <div className="date-line">
            {meses.map((obj) => (
              <div
                key={meses.indexOf(obj)}
                className={`${checkClassDateItem(obj.month, "mes")} date-item`}
                onClick={() => handleChoose("mes", obj.month)}
              >
                <p>{obj.str}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </DaterContainer>
  );
}

import styled from "styled-components";
import DatePicker from "react-datepicker";

export const DateRangePickerContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
    padding: 0 1em;
    font-size: 1em;
    color: #333;
  }
`;

export const StyledDatePickerWrapper = styled.div`
  width: 10em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: rgba(0, 0, 0, 0.1) solid 1px;
  .react-datepicker__input-container input {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    height: 60%;
    font-size: 1em;
    width: 9em;
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  /* Estilos para o input */

  /* Estilos para o calendário */
  .react-datepicker {
    font-size: 1em;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }

  /* Estilos para os dias no calendário */
  .react-datepicker__day {
    width: 2em;
    height: 2em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
    margin: 0.2em;
    transition: background-color 0.2s ease-in-out;
  }

  /* Estilos para o dia selecionado */
  .react-datepicker__day--selected {
    background-color: #007bff;
    color: #fff;
  }

  /* Estilos para os dias desabilitados */
  .react-datepicker__day--disabled {
    color: #ccc;
    cursor: default;
  }

  /* Estilos para o cabeçalho do calendário */
  .react-datepicker__header {
    background-color: #f5f5f5;
    border-bottom: 1px solid #ccc;
    padding: 0.5em;
    text-align: center;
  }

  /* Estilos para os botões de navegação */
  .react-datepicker__navigation {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    color: #007bff;
    font-size: 1.2em;
    margin-top: 0.2em;
  }

  /* Estilos para os meses e anos no cabeçalho */
  .react-datepicker__current-month,
  .react-datepicker__year-read-view {
    font-weight: bold;
    color: #333;
    font-size: 1.2em;
  }
`;
export const Header = styled.div`
  width: 100%;
  height: 3em;
  display: flex;
  background-color: rgba(250, 250, 250, 1);
  align-items: center;

  .time-header {
    width: 10%;
    height: 100%;
    display: flex;
    align-items: center;
    p {
      font-size: 1em;
      margin-left: 1em;
    }
  }
  .time-filter {
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .btn {
    margin-left: 1em;
    height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(180, 180, 180);
    padding: 0.5em 1em;
    font-size: 1em;
    text-align: center;
    opacity: 0.7;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;

    &:hover {
      opacity: 1;
    }

    &:active {
      opacity: 1;
      background-color: rgb(200, 200, 200);
    }

    p {
      margin: 0;
      padding: 0;
      font-size: 1em;
      color: inherit;
    }
  }
`;
export const Box = styled.div``;

export const Container = styled.div`
  width: 100%;
  height: calc(100%-3em);
  display: flex;
  flex-direction: column;
  align-items: center;

  .text {
    width: 100%;
    height: 2em;
    display: flex;
    align-items: center;
    margin: 1em 0;
    p {
      font-size: 1.5em;
      margin-left: 1em;
    }
  }
  .infos {
    width: 98%;
    height: 8em;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 3em;
    justify-content: space-between;

    .infos-content {
      width: 20%;
      height: 100%;
      display: flex;
      background-color: white;
      flex-direction: column;
      border-radius: 5px;

      .icon {
        display: flex;
        width: 10%;
        justify-content: center;
        align-items: center;
      }
      .infos-text {
        display: flex;
        width: 100%;
        height: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          border-bottom: rgba(0, 0, 0, 0.1) solid 1px;
          display: flex;
          width: 90%;
          justify-content: center;
          font-size: 1.3em;
        }
      }
      .infos-value {
        display: flex;
        width: 100%;
        height: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          font-size: 1.7em;
          font-weight: 350;
        }
      }
    }
  }
  .charts {
    width: 98%;
    height: 20em;
    display: flex;
    margin-bottom: 3em;
    justify-content: space-between;

    .donut {
      width: 20%;
      height: 100%;
      background-color: white;
      border-radius: 5px;
      .chart-title {
        width: 100%;
        height: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          display: flex;
          width: 80%;
          align-items: center;
          justify-content: center;
          font-size: 1.3em;
          border-bottom: rgba(0, 0, 0, 0.1) solid 1px;
        }
      }
    }
    .chart {
      width: 38%;
      height: 100%;
      border: rgba(0, 0, 0, 0.1) solid 1px;
      border-radius: 5px;
      .chart-title {
        width: 100%;
        height: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          display: flex;
          width: 80%;
          align-items: center;
          justify-content: center;
          font-size: 1.3em;
          border-bottom: rgba(0, 0, 0, 0.1) solid 1px;
        }
      }
    }
  }
`;

import NFes from "./components/NFes";
import Charts from "./components/Charts";
import { useUsers } from "../../hooks/user";
import Report from "./components/Report";
import Updates from "./components/Updates";
import DashboardComponet from "./components/DashboardComponent";
import Admin from "./components/Admin";
import ChartsData from "./components/ChartsData";
import Sql from "./components/Sql";

import Chat from "./components/MiniChat/chat";

import MyLoader from "../../components/Dashboard/components/NFes/components/ContentLoader";
import { useEffect, useState } from "react";

export default function Dashboard() {
  const { status } = useUsers();
  const [loading, setLoading] = useState(true);
  const stages = [
    { tipo: "nfe", comp: <NFes /> },
    { tipo: "dashboard", comp: <DashboardComponet /> },
    { tipo: "nfe_filter", comp: <NFes /> },
    { tipo: "charts", comp: <Charts /> },
    { tipo: "report", comp: <Report /> },
    { tipo: "update", comp: <Updates /> },
    { tipo: "admin", comp: <Admin /> },
    { tipo: "charts-data", comp: <ChartsData /> },
    { tipo: "sql", comp: <Sql /> },
  ];

  return (
    <>
      {stages.find((obj) => obj.tipo === status).comp}

      <Chat />
    </>
  );
}

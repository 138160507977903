import React, { useState, useEffect } from "react";
import axios from "axios";
import io from "socket.io-client";
import { ChatStyle, OpenChat, Container } from "./style";
import ontaxwhite from "../../../../assets/ontax-white.png";
import message from "../../../../assets/message-icon.png";
import sendIcon from "../../../../assets/send-icon.png";
import { IoChevronUpSharp, IoChevronDownSharp } from "react-icons/io5";
import { IoSend } from "react-icons/io5";

import { useUsers } from "../../../../hooks/user";
import { useChat } from "../../../../hooks/chat";
import { useProjetos } from "../../../../hooks/projetos";

/* const socket = io("https://ontax.app"); */

export default function Chat() {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [chatPosition, setChatPosition] = useState(false);
  const [inputMessage, setInputMessage] = useState("");
  const [chatStarted, setChatStarted] = useState(false);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(false);
  const [nextMessage, setNextMessage] = useState(null);
  const [notification, setNotification] = useState({
    isShow: true,
    count: 2,
  });

  const { userPage, setUserPage } = useProjetos();
  const { user } = useUsers();
  const { chat, chatId, setChatId } = useChat();

  const uri = process.env.REACT_APP_API_URI;

  function getCurrentTime(dateString) {
    let now;
    if (!dateString || isNaN(Date.parse(dateString))) {
      now = new Date();
    } else {
      now = new Date(dateString);
    }
    let hours = now.getHours();
    let minutes = now.getMinutes();
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return `${hours}:${minutes}`;
  }

  function scrollToBottom() {
    const element = document.getElementById("chat-box");
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }

  const sendMessage = () => {
    if (inputMessage.trim()) {
      const newMessage = {
        chatId: chat?._id,
        from: user?._id,
        message: inputMessage,
        createdAt: new Date().toISOString(), // ISO string para compatibilidade
        sentByCurrentUser: true,
        tempId: Date.now(), // Identificador temporário
      };

      //socket.emit("chat-message", newMessage); // Envia a mensagem para o servidor via socket
      setMessages((prevMessages) => [...prevMessages, newMessage]); // Adiciona a mensagem ao estado local
      setInputMessage(""); // Limpa o campo de entrada
    }
  };

  useEffect(() => {
    // Carrega as mensagens ao abrir o chat ou alterar o usuário
    if (isOpen && user) {
      axios
        .get(`${uri}/pro/conversations/${chat._id}`)
        .then((response) => {
          if (response.status === 200) {
            setMessages(response.data.messages || []);
          }
        })
        .catch((error) =>
          console.error("Erro ao buscar mensagens do chat:", error)
        );
    }
  }, [isOpen, user, setMessages]);

  useEffect(() => {
    setChatPosition(userPage ? "25em" : "2em"); // Atualize a posição com base em userPage
  }, [userPage]);

  return (
    <Container style={{ display: "none" }}>
      {isOpen ? (
        <OpenChat style={{ right: chatPosition }}>
          <div className="header" onClick={() => setIsOpen(false)}>
            <img src={message} alt="Message Icon" />
            <IoChevronDownSharp className="icon-down" />
          </div>
          <div className="status">
            <div className="status-icon"></div>
            <h1>Estamos Online</h1>
          </div>
          <div className="chat" id="chat-box">
            {messages.map((msg) => (
              <div
                key={msg._id || msg.tempId} // Modifique esta linha
                className={`chat-align ${
                  msg.from._id === user ? "right" : "left"
                }`}
              >
                <div
                  className="message"
                  style={{
                    backgroundColor: `${
                      msg.from._id === user ? "white" : "rgba(230, 230, 230)"
                    }`,
                  }}
                >
                  <p className="msg">{msg.message}</p>
                  <div className="time">
                    <p>{getCurrentTime(new Date(msg.createdAt))}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="text">
            <input
              className="ipt"
              type="text"
              placeholder="Digite uma mensagem"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  sendMessage();
                }
              }}
            />
            <div className="icon-send" onClick={sendMessage}>
              <IoSend />
            </div>
          </div>
        </OpenChat>
      ) : (
        <ChatStyle
          onClick={() => setIsOpen(true)}
          style={{ right: chatPosition }}
        >
          <div className="head">
            {notification.isShow === true && (
              <div className="notification">
                <p>{notification.count}</p>
              </div>
            )}

            <div className="title">
              <img src={message} className="icon" alt="Message Icon" />
            </div>
            <div className="open" onClick={() => setIsOpen(true)}>
              <IoChevronUpSharp className="icon-open" />
            </div>
          </div>
        </ChatStyle>
      )}
    </Container>
  );
}

import styled from "styled-components";

export const Container = styled.div`
  width: 25em;
  height: max-content;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

export const InfoContainer = styled.div`
  width: 100%;
  height: max-content;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .progress-info {
    width: 100%;
    height: 4em;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .text {
      width: 100%;
      height: 75%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 0.85em;
        margin: 0 10px;
        opacity: 0.6;
      }
    }

    .progress-content {
      width: 100%;
      height: 25%;
      display: flex;
      align-items: flex-start;
      justify-content: center;

      .progress {
        width: 95%;
        height: 0.4em;
        background-color: rgba(0, 0, 0, 0.1);
        transform: translateY(-5px);
        border-radius: 5px;
        overflow: hidden;

        .bar {
          width: 0%;
          height: 100%;
          background-color: rgba(0, 210, 100, 0.8);
        }
      }
    }
  }

  .infos {
    width: 100%;
    height: max-content;

    .info-item {
      width: 100%;
      height: 2.5em;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .logo {
        width: 2.5em;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgba(0, 0, 0, 0.1);

        svg {
          opacity: 0.5;
        }
      }

      .text-content {
        width: calc(100% - 2.5em);
        height: 100%;
        display: flex;

        align-items: center;
        justify-content: space-between;
        p {
          font-size: 0.9em;
          margin: 0 10px;
          opacity: 0.8;
        }
      }
    }
  }

  .file-list {
    width: 100%;
    height: 4em;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .files-container {
    width: 100%;
    min-height: max-content;
    display: flex;
    flex-direction: column;
    .files-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      .file {
        width: 100%;
        height: 3em;
        display: flex;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        background-color: white;

        .thumb {
          width: 3em;
          height: 3em;
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: 1px solid rgba(0, 0, 0, 0.1);
          img {
            width: 1.2em;
          }
        }
        .text {
          width: calc(100% - 6em);
          height: 100%;
          display: flex;
          flex-direction: column;
          margin-left: 1em;
          justify-content: center;
          h2 {
            font-size: 0.9em;
            font-weight: 500;
          }
          p {
            font-size: 0.8em;
            opacity: 0.8;
            line-height: 15px;
          }
        }
      }
    }

    .progress-bar {
      width: 100%;
      height: 0.5em;
      background-color: rgba(0, 0, 0, 0.2);
      display: flex;

      .load {
        width: 0%;
        height: 100%;
        background-color: rgba(0, 180, 100);
      }
    }

    .download-btn {
      width: 100%;
      height: 4em;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1em 0;

      .btn {
        width: max-content;
        height: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(15, 15, 15);
        border-radius: 5px;
        cursor: pointer;
        opacity: 0.8;
        transition: all 0.2s;

        &:hover {
          opacity: 1;
        }

        &.loading {
          opacity: 0.2;
          cursor: auto;
          user-select: none;

          &:hover {
            opacity: 0.2;
          }
        }

        h2 {
          color: white;
          font-size: 1em;
          padding: 5px 30px;
        }
      }
    }

    .refresh {
      width: 2em;
      height: 2em;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      margin: 4px;
      margin-right: 6px;
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
      opacity: 0.8;
      transition: all 0.2s;
      position: fixed;
      transform: translateX(7em);

      &:hover {
        opacity: 1;
      }

      svg {
        font-size: 0.8em;
        opacity: 0.8;
      }
    }
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 2em;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(250, 250, 250);

  .close {
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &:hover {
      background-color: white;
    }

    svg {
      opacity: 0.5;
      font-size: 0.8em;
    }
  }

  .export {
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &:hover {
      background-color: white;
    }

    svg {
      opacity: 0.7;
      font-size: 0.8em;
    }
  }

  .text {
    width: calc(100% - 2em);
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-size: 0.8em;
      font-weight: 600;
      opacity: 0.6;
      transform: translateX(-1.8em);
    }
  }
`;

import styled from "styled-components";

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const WideBox = styled.div`
  height: 100%;
  width: 85%;
  display: flex;
  flex-direction: column;
`;

const TopInfo = styled.div`
  height: 8em;
  width: 100%;
  display: flex;
  overflow-y: overlay;
  flex-direction: row;
  align-items: center;
  border-bottom: 0.1em solid rgba(0, 0, 0, 0.1);
  .infos {
    min-width: 15em;
    width: max-content;
    display: flex;
    width: 15em;
    height: 5.5em;
    flex-direction: column;
    background-color: white;
    margin: 0em 1em !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03);
    .head {
      height: 1.8em;
      display: flex;
      width: 100%;
      align-items: center;
      align-content: center;
      border-bottom: 0.1em solid rgba(0, 0, 0, 0.1);
      .name {
        h1 {
          font-weight: 400;
          font-size: 0.8em;
          margin: 0 1.3em !important;
          margin-left: 1em;
        }
      }
      .info-thumb {
        width: 1em;
        height: 1em em;
        display: flex;
        cursor: pointer;
        &:hover {
          .btn-text {
            display: flex;
          }
        }
        .btn-text {
          display: none;
          position: absolute;
          z-index: 3;
          min-width: 5em;
          min-height: 2em;
          width: max-content;
          height: max-content;
          transform: translateX(1em) translateY(-0.5em);
          border: 1px solid rgba(0, 0, 0, 0.1);
          align-items: center;
          background-color: white;

          p {
            opacity: 0.5;
            padding: 10px 0px;
            max-width: 20em;
            line-height: 15px;

            font-size: 0.8em;

            margin: 0 1.3em !important;
            font-weight: 400;
          }
        }
      }
      .btn-thumb {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
        &:hover {
          opacity: 0.5;
        }
        svg {
          color: rgba(0, 100, 255, 0.3);
          font-size: 0.7em;
        }
      }
    }
    .value {
      height: calc(100% - 1.8em);
      display: flex;
      align-items: center;
      p {
        font-family: "Poppins";
        font-weight: 300;
        font-size: 1.4em;
        margin-left: 0.8em !important;
        margin-right: 2em !important;
      }
    }
  }
`;
const Graphs = styled.div`
  width: 100%;
  height: 20em;
  display: flex;
  align-items: center;
  overflow: hidden;
  overflow-x: overlay;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  .graph {
    margin-left: 1em;
    width: max-content;
    height: 90%;

    .head {
      display: flex;
      height: 1.8em;
      background-color: white;
      border-bottom: 0.1em solid rgba(0, 0, 0, 0.1);
      align-items: center;
      p {
        font-size: 0.8em;
        font-weight: 400;
        margin-left: 1em !important;
        line-height: 17px;
        font-family: "Montserrat", sans-serif;
      }
    }
    .bars {
      align-items: center;
      display: flex;
      height: calc(100% - 1.8em);
      width: 100%;
      flex-direction: row;
      overflow: hidden;
      .bar {
        min-width: 1.5em;
        max-width: 2em;
        display: flex;
        height: 100%;
        border-right: 1px solid rgba(0, 0, 0, 0.03);
        background-color: white;
        opacity: 1;
        align-items: flex-end;
        justify-content: center;
        cursor: pointer;
        opacity: 0.7;
        &:hover {
          opacity: 1;
        }

        .content-bar {
          z-index: 1;
          width: 50%;
          opacity: 0.7;
          display: flex;
          justify-content: center;
          &:hover {
            .tag {
              display: flex;
            }
          }
          .tag {
            width: max-content;
            height: 1.5em;
            z-index: 1;
            display: none;
            background-color: rgba(10, 10, 10);
            color: white;
            position: absolute;
            transform: translateY(-2.5em);
            border-radius: 5px;
            align-items: center;
            transition: all 0.2s;
            padding: 2px 20px;
          }
        }
      }
    }
  }
  .project {
    background-color: black;
    flex-direction: column;
    margin: 1em !important;
    min-width: 15em;
    width: 25em;
    height: 90%;
    background-color: white;
    .head {
      display: flex;
      height: 1.8em;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      align-items: center;
      .header {
        display: flex;
        height: 100%;
        width: 100%;
        background-color: white;

        p {
          font-size: 0.8em;
          opacity: 0.9;
          margin: 0 1.3em !important;
          font-weight: 400;
        }
      }
    }
    .items {
      overflow: hidden;
      overflow-y: overlay;
      width: 100%;
      height: calc(100% - 1.8em);
      display: flex;
      flex-direction: column;
      .item {
        width: 100%;
        height: 3.5em;
        font-size: 1em;
        display: flex;
        align-items: center;
        background-color: white;
        border-bottom: 1px solid rgba(0, 0, 0, 0.03);
        margin-top: 1px !important;
        opacity: 0.5;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
        .thumb {
          width: 3.5em;
          height: 3.5em;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 0.7em;
          }
        }
        .data {
          width: calc(100% - 2em);
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          p {
            font-size: 0.8em;
            font-weight: 400;
            margin-left: 1em !important;
            line-height: 17px;
          }
        }
      }
    }
  }
`;
const DetailedInfo = styled.div`
  height: calc(100% - 30.4em);
  width: 100%;
  display: flex;
  flex-direction: column;

  .item {
    height: 100%;
    display: flex;
    align-items: center;
    width: 25%;
    margin-top: 1px !important;

    border-right: 1px solid rgba(0, 0, 0, 0.03);

    p {
      font-size: 0.8em;
      opacity: 0.9;
      margin: 0 1.3em !important;
      font-weight: 400;
      font-family: "Montserrat", sans-serif;
    }
  }
  .header {
    display: flex;
    height: 1.8em;
    width: 100%;
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  .display {
    width: 100%;
    height: 23em;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.7);

    .items {
      flex-wrap: nowrap;
      width: 100%;
      height: 2em;
      display: flex;
      align-items: center;
      border-right: 1px solid rgba(0, 0, 0, 0.03);

      background-color: white;
      opacity: 0.6;
      transition: opacity 0.2s;
      cursor: pointer;
      &:hover {
        opacity: 1;
        border-bottom: 1px solid rgba(0, 200, 255, 0.3);
      }
    }
  }
`;

export { WideBox, TopInfo, DetailedInfo, Graphs, Container };

import React, { useState, useEffect } from "react";
import {
  Container,
  DropArea,
  Header,
  DropZone,
  Content,
  FilterHeader,
} from "./style";

import CancelInfos from "./components/cancelInfos";

import axios from "axios";
import Dropzone, { useDropzone } from "react-dropzone";

import { useProjetos } from "../../../../hooks/projetos";

import { CiWarning } from "react-icons/ci";
import { MdCheck, MdClose } from "react-icons/md";
import { BsFillTrashFill } from "react-icons/bs";
import { useDownload } from "../../../../hooks/download";

const uri = process.env.REACT_APP_API_URI;

export default function CancelReport() {
  const {
    cnpjs,
    setCnpjs,
    filterDate,
    setFilterDate,
    type,
    setType,
    filter,
    setFilter,
    infos,
    setInfos,
  } = useDownload();

  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [date, setDate] = useState(false);
  const [search, setSearch] = useState(false);

  const { projeto } = useProjetos();

  function formatBytes(a, b = 2) {
    if (!+a) return "0 Bytes";
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
      ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
    }`;
  }

  function handleDelete(fileToDelete) {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
  }

  function handleClose() {
    document.getElementById("blackscreen").classList.remove("ativ");
    setFilter("");
  }

  async function sendFile() {
    const data = new FormData();

    for (var i = 0; i < files.length; i++) {
      const file = files[i];
      data.append("file", file);
      console.log(file);
    }

    await axios
      .post(`${uri}/pro/upload-canceladas/${projeto}`, data, {
        onUploadProgress: (e) => {
          const prog = parseInt(Math.round((e.loaded * 100) / e.total));
          setProgress(prog);
        },
      })
      .then(async (res) => {
        axios.post(`${uri}/pro/process-canceladas/${projeto}`, {
          filter: type,
        });
        setOpen(true);
      });
  }

  function fixDropBox() {
    if (filter !== "") {
      console.log("Data");
      return { height: `calc(36em - ${files.length} * 3em)` };
    } else {
      console.log("sem data");
      return { height: `calc(18em - ${files.length} * 5em)` };
    }
  }

  function DropContainer(props) {
    const { getRootProps, getInputProps, isDragActive, isDragReject } =
      useDropzone({
        accept: {
          "text/plain": [],
        },
        onDropAccepted: (obj) => {
          setFiles(files.concat(obj));
          console.log(files);
        },
      });
    return (
      <div className="drop-box">
        <DropArea
          {...getRootProps()}
          isDragActive={isDragActive}
          isDragReject={isDragReject}
          style={filter && !date ? fixDropBox() : {}}
        >
          <input {...getInputProps()} type="file" name="file" multiple />
          {renderDragMessage(isDragActive, isDragReject)}
        </DropArea>
        {files !== undefined && (
          <div className="files-container">
            <div className="files-list">
              {files.map((file) => (
                <div className="file">
                  <div className="thumb">
                    <img
                      src="https://cdn-icons-png.flaticon.com/128/2991/2991108.png"
                      alt="documento"
                    />
                  </div>
                  <div className="text">
                    <h2>{file.path}</h2>
                    <p>{formatBytes(file.size)}</p>
                  </div>
                  {progress === 0 && (
                    <div
                      className="delete-btn"
                      onClick={() => handleDelete(file)}
                    >
                      <BsFillTrashFill />
                    </div>
                  )}
                </div>
              ))}
            </div>
            {progress > 0 && (
              <div className="progress-bar">
                <div className="load" style={{ width: `${progress}%` }}></div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  function renderDragMessage(isDragActive, isDragReject) {
    if (!isDragActive) {
      return (
        <div className="text-container">
          <div className="thumb">
            <div className="circle">
              <p>+</p>
            </div>
            <div className="text">
              <h2>Carregar Lista de Certidões</h2>
              <p>Arraste aqui os arquivos que deseja carregar</p>
            </div>
          </div>
        </div>
      );
    }
    if (isDragReject) {
      return (
        <div className="text-container">
          <div className="thumb">
            <CiWarning color="rgba(200, 0, 0)" size={"25px"} />
            <div className="text">
              <h2>Formato nao suportado</h2>
              <p>O arquivo deve estar no formato ".txt"</p>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="text-container">
        <div className="thumb">
          <div className="circle" style={{ borderColor: "rgba(0, 200, 100)" }}>
            <MdCheck color="rgba(0, 200, 100)" />
          </div>
          <div className="text">
            <h2>Solte o arquivo aqui</h2>
            <p>Formato suportado</p>
          </div>
        </div>
      </div>
    );
  }

  function handletype(name) {
    if (type === name) {
      setType("");
    } else {
      setType(name);
    }
  }

  return (
    <Container>
      <Header>
        <div className="close" onClick={handleClose}>
          <MdClose />
        </div>
        <div className="text">
          <p>
            {open ? "Informações Sobre os Arquivos" : "Relatório de Canceladas"}
          </p>
        </div>
        {/* <div
              className="arrow"
              onClick={() => progress === 100 && setOpen(true)}
            >
              <AiOutlineArrowRight />
            </div> */}
      </Header>
      {open ? (
        <CancelInfos
          setOpen={setOpen}
          setProgress={setProgress}
          progress={progress}
          open={open}
        />
      ) : (
        <Content>
          <div className="drop-filter">
            <FilterHeader>
              <div className="general-filter">
                <div
                  className="general-box"
                  onClick={() => type !== "" && setType("")}
                  style={
                    type !== ""
                      ? {
                          backgroundColor: "rgba(0, 110, 240, 0.4)",
                          border: "1px solid rgba(0, 110, 240, 0.5)",
                        }
                      : {}
                  }
                ></div>
                <div className="buttons">
                  <div
                    style={
                      type === "cte"
                        ? { backgroundColor: "rgb(240, 240, 240)" }
                        : {}
                    }
                    onClick={() => handletype("cte")}
                  >
                    <p>CTE</p>
                  </div>
                  <div
                    style={
                      type === "nfe"
                        ? { backgroundColor: "rgb(240, 240, 240)" }
                        : {}
                    }
                    onClick={() => handletype("nfe")}
                  >
                    <p>NFE</p>
                  </div>
                </div>
              </div>
            </FilterHeader>
            <DropContainer>
              <DropZone>
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragReject,
                }) => <DropContainer />}
              </DropZone>
            </DropContainer>
            <div className="send-files">
              <div
                className="btn"
                onClick={async () => await sendFile()}
                style={
                  files.length === 0 && type === ""
                    ? { opacity: "0.4", cursor: "auto", userSelect: "none" }
                    : {}
                }
              >
                <h2>ENVIAR</h2>
              </div>
            </div>
          </div>
        </Content>
      )}
    </Container>
  );
}

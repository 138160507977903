import React, { useState, useEffect } from "react";

import axios from "axios";

import { Projetos, Infos } from "./style";

import alerta from "../../../../utils/alerta";
import getColor from "../../../../utils/getColor";

import { BsCardList } from "react-icons/bs";
import { BiTransferAlt, BiUser } from "react-icons/bi";
import { GrPowerReset } from "react-icons/gr";
import { RxCube } from "react-icons/rx";
import { RiToolsLine } from "react-icons/ri";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { CgToolbox } from "react-icons/cg";
import { MdDashboard, MdOutlineAdminPanelSettings } from "react-icons/md";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { HiOutlineDocumentReport } from "react-icons/hi";

import { useUsers } from "../../../../hooks/user";
import { useProjetos } from "../../../../hooks/projetos";
import { useNfe } from "../../../../hooks/nfe";
import { useBlackScreen } from "../../../../hooks/blackscreen";

export default function InfosContent() {
  const { openBlackScreen } = useBlackScreen();
  const {
    setProjeto: setProjetoNfe,
    filial,
    setFilial,
    setFilialInfo,
    filial_info,
    setFilial_info,
    setSelect,
  } = useNfe();
  const { infos, editUser } = useUsers();
  const { filiais } = useProjetos();

  const { projeto, setProjeto, projetos } = useProjetos();
  const { setNfes } = useNfe();

  function handleEditUser() {
    var email = document.getElementById("user-email");
    var nome = document.getElementById("user-nome");
    var senha = document.getElementById("user-senha");

    var check = true;

    if (email.value === "") {
      alerta("Preencha o email do usuário", false, "err");
      check = false;
    }

    if (nome.value === "") {
      alerta("Preencha o nome do usuário", false, "err");
      check = false;
    }

    if (check) {
      email = email.value;
      senha = senha.value;
      nome = nome.value;

      var new_user = {
        email,
        senha,
        nome,
        ide: infos._id,
      };

      alerta(
        "Tem certeza que deseja alterar as informações do usuário",
        true,
        "err",
        editUser(new_user)
      );
    }
  }

  function chooseArea(str) {
    if (str === "admin") {
      window.location.href = `${
        process.env.REACT_APP_ADMIN_URI
      }/${localStorage.getItem("app_token")}/${localStorage.getItem(
        "user_id"
      )}/${projeto}`;
    }
    if (str === "manager") {
      window.location.href = `${process.env.REACT_APP_ONTAX}`;
    }
    if (str === "free") {
      window.location.href = `${
        process.env.REACT_APP_FREE
      }/${localStorage.getItem("app_token")}/${localStorage.getItem(
        "user_id"
      )}/${projeto}`;
    }
  }

  function resetFilial() {
    setProjeto(projeto);
    setProjetoNfe(projeto);
    setNfes([]);
    setFilial();
    setFilialInfo();
  }

  function chooseFilial(obj) {
    setFilial(obj.cnpj);
    setFilialInfo(obj);
    setNfes([]);
  }

  function handleChoose(ide) {
    if (ide !== projeto) {
      setProjeto(ide);
      setProjetoNfe(ide);
      setNfes([]);
      console.log("F_I", filial_info);
      setFilial();
      setFilialInfo();
    }

    console.log("projeto", projeto);
    console.log("ide", ide);
  }

  return (
    <>
      <Projetos>
        <div className="head">
          <BsCardList />
          <p>Projetos</p>
        </div>
        <div className="display">
          {projetos.length > 0 &&
            projetos.map((obj) => (
              <div
                className={`item ${obj._id === projeto && "ativ"}`}
                onClick={() => handleChoose(obj._id)}
              >
                <div className="thumb">
                  <RxCube color={getColor(projetos.indexOf(obj))} />
                </div>

                <div className="content">
                  <p>{obj.nome}</p>
                  <p className="cnpj">{obj.cnpj}</p>
                </div>
              </div>
            ))}
        </div>
      </Projetos>
      <Projetos>
        <div className="head">
          <div className="header">
            <HiOutlineBuildingOffice2 />
            <p>Filiais</p>
          </div>
          <div className="reset" onClick={resetFilial}>
            <GrPowerReset />
          </div>
        </div>
        <div className="display">
          {filiais.length > 0 &&
            filiais.map((obj) => (
              <div
                className={`item ${obj.cnpj === filial && "ativ"}`}
                onClick={() => {
                  chooseFilial(obj);
                  setSelect("filial_filter");
                }}
              >
                <div className="thumb">
                  <RxCube color={obj.color} />
                </div>

                <div className="content">
                  <p>{obj.nome}</p>
                  <p className="cnpj">{obj.cnpj}</p>
                </div>
              </div>
            ))}
        </div>
      </Projetos>
      <Infos>
        <div className="head">
          <RiToolsLine />
          <p>Ferramentas</p>
        </div>
        <div className="display">
          <div className="head btn" onClick={() => openBlackScreen("speds")}>
            <RxCube />
            <p>Speds</p>
          </div>
          <div className="head btn" onClick={() => openBlackScreen("revisor")}>
            <RxCube />
            <p>Revisor</p>
          </div>
          <div className="head btn" onClick={() => openBlackScreen("download")}>
            <AiOutlineCloudDownload />
            <p>Downloads</p>
          </div>
          <div
            className="head btn"
            onClick={() => openBlackScreen("canceladas")}
          >
            <HiOutlineDocumentReport />
            <p>Relatório de Canceladas</p>
          </div>
        </div>
        {localStorage.getItem("user_id") === "638ebde9254c88f79b7ef9fd" && (
          <div className="work-areas">
            <div className="head">
              <MdDashboard />

              <p>Áreas de trabalho</p>
            </div>
            <div className="container">
              <div className="head btn" onClick={() => chooseArea("manager")}>
                <CgToolbox />
                <p>Manager</p>
              </div>
              <div className="head btn" onClick={() => chooseArea("admin")}>
                <MdOutlineAdminPanelSettings />
                <p>Admin</p>
              </div>
              <div className="head btn" onClick={() => chooseArea("free")}>
                <BiTransferAlt />
                <p>Free</p>
              </div>
            </div>
          </div>
        )}
        <div className="head">
          <BiUser />
          <p>Informações da conta</p>
        </div>
        <div className="infos">
          <div className="info">
            <div className="content">
              <div className="tag">
                <p>Nome</p>
              </div>
              <div className="input-container">
                <input
                  id="user-nome"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleEditUser();
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="info">
            <div className="content">
              <div className="tag">
                <p>Email</p>
              </div>
              <div className="input-container">
                <input
                  id="user-email"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleEditUser();
                    }
                  }}
                />
              </div>
            </div>
          </div>

          <div className="info">
            <div className="content">
              <div className="tag">
                <p>Senha</p>
              </div>
              <div className="input-container">
                <input
                  id="user-senha"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleEditUser();
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="send">
            <div className="btn" onClick={handleEditUser}>
              <h2>EDITAR</h2>
            </div>
          </div>
        </div>
      </Infos>
    </>
  );
}

import styled from "styled-components";

export const Container = styled.div`
  width: max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;

  .head {
    height: 2em;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    width: 100%;

    .btn {
      width: 2em;
      height: 2em;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      overflow: hidden;
      cursor: pointer;
      svg {
        font-size: 1.5em;
      }

      &:last-child {
        border-right: none;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        opacity: 0.6;
      }
    }

    .head-info {
      width: calc(100% - 3em);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      h2 {
        font-size: 0.8em;
      }
    }
  }

  .template-btn {
    width: 100%;
    height: 5em;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn-confirm {
      width: 12em;
      height: 3em;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: black;
      border-radius: 10px;
      cursor: pointer;
      opacity: 0.8;
      transition: all 0.2s;

      &hover {
        opacity: 1;
      }

      p {
        color: white;
        font-size: 0.9em;
        font-weight: 600;
      }
    }

    .reload {
      width: 3em;
      height: 3em;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      position: absolute;
      transform: translateX(8em);
      cursor: pointer;
      opacity: 0.8;
      transition: all 0.2s;

      &hover {
        opacity: 1;
      }

      svg {
        font-size: 1.5em;
      }
    }
  }

  .infos {
    width: 35em;
    height: calc(100% - 2em);
    display: flex;
    flex-direction: column;

    .info {
      width: 100%;
      min-height: 4em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 0.5em;

      .content {
        width: 95%;
        height: 90%;
        display: flex;
        flex-direction: column;

        .tag {
          width: 100%;
          height: 1.5em;
          display: flex;
          align-items: center;

          p {
            font-size: 0.7em;
            opacity: 0.8;
            font-weight: 600;
          }
        }

        .input-container {
          width: 100%;
          height: calc(100% - 1.5em);
          display: flex;
          align-items: center;

          input {
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, 0.1);
          }

          textarea {
            resize: none;
            width: 100%;
            height: 14em !important;
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            outline: none;
            padding: 15px;
          }
        }
      }
    }
  }

  .send {
    width: 100%;
    height: 5em;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      width: max-content;
      height: max-content;
      background-color: rgba(15, 15, 15);
      color: white;
      border-radius: 5px;
      cursor: pointer;

      h2 {
        font-size: 1.1em;
        padding: 5px 40px;
      }
    }
  }
`;

export const Black = styled.div`
  width: 500vw;
  height: 500vh;
  background-color: black;
  opacity: 0.4;
  position: absolute;
  display: flex;
  top: 0;
`;

export const WindowContent = styled.div`
  width: 30em;
  height: 10em;
  background-color: white;
  opacity: 10;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: calc(50% - 5em);
  right: calc(50% - 15em);
  border-radius: 10px;

  .header {
    width: 100%;
    height: 2em;
    display: flex;
    justify-content: flex-end;

    .btn {
      width: 2em;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      cursor: pointer;
      svg {
        font-size: 1.5em;
      }
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 2em);

    .description {
      width: 100%;
      height: 2em;

      p {
        margin-left: 20px;
      }
    }

    input {
      width: 80%;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 7px;
      margin-left: 20px;
    }

    .btn-container {
      width: 100%;
      height: 3em;
      display: flex;
      justify-content: center;
      align-items: center;

      .btn {
        width: 7em;
        height: 2.5em;
        background-color: black;
        border-radius: 10px;
        opacity: 0.8;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1.6em;
        transition: all 0.2s;
        cursor: pointer;

        p {
          font-size: 0.9em;
          color: white;
          font-weight: 600;
        }
      }
    }
  }
`;

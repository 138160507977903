import React from "react";
import { Header, Block, Container, Display } from "./style";
import { useBlackScreen } from "../../../../hooks/blackscreen";

import { MdClose } from "react-icons/md";
import { IoCubeOutline } from "react-icons/io5";
import { useState } from "react";
import { useProjetos } from "../../../../hooks/projetos";
import { useEffect } from "react";
import axios from "axios";
import getDates from "../../../../utils/getDates";
import ContentLoader from "react-content-loader";

const uri = process.env.REACT_APP_API_URI;

export default function SpedsReport() {
  const [infos, setInfos] = useState([]);
  const [ativ, setAtiv] = useState();
  const { openBlackScreen } = useBlackScreen();

  const { projeto } = useProjetos();

  useEffect(() => {
    if (projeto !== undefined) {
      axios.get(`${uri}/info/${projeto}`).then((res) => {
        const sortedInfos = res.data.sort((a, b) => {
          // Assegurar que janeiro (1) venha antes de dezembro (12)
          const yearA = a.periodo.start.ano;
          const yearB = b.periodo.start.ano;
          const monthA = a.periodo.start.mes;
          const monthB = b.periodo.start.mes;

          if (yearA !== yearB) {
            return yearA - yearB; // Ordena primeiro pelo ano
          } else {
            return monthA - monthB; // Se o ano for o mesmo, ordena pelo mês
          }
        });
        setInfos(sortedInfos);
      });
    }
  }, [projeto]);

  function handleClose() {
    document.getElementById("blackscreen").classList.remove("ativ");
  }

  function checkClass(str) {
    if (str === ativ) {
      return "ativ";
    }
  }

  function handleChoose(str) {
    if (str === ativ) {
      setAtiv();
    } else {
      setAtiv(str);
    }
  }

  function getMonth(mes) {
    if (mes < 0) {
      mes = 1;
    }

    try {
      return getDates().months.find((data) => mes === data.month).str;
    } catch (e) {
      return mes;
    }
  }

  function Loader() {
    return (
      <>
        {Array.from({ length: 12 }, (_, i) => (
          <div className="item-cont">
            <div className="item" key={i}>
              <ContentLoader speed={2} width={1550} height={40}>
                <rect x="50" y="7" rx="5" ry="5" width="150" height="12" />
                <rect x="50" y="25" rx="5" ry="5" width="100" height="8" />
                <rect x="470" y="15" rx="5" ry="5" width="80" height="10" />
                <rect x="590" y="15" rx="5" ry="5" width="80" height="10" />
                <rect x="708" y="15" rx="5" ry="5" width="80" height="10" />
                <rect x="825" y="15" rx="5" ry="5" width="80" height="10" />
                <rect x="940" y="15" rx="5" ry="5" width="80" height="10" />
              </ContentLoader>
            </div>
          </div>
        ))}
      </>
    );
  }

  return (
    <Block>
      <Header>
        <div className="close" onClick={handleClose}>
          <MdClose />
        </div>
        <div className="text">
          <p>Speds</p>
        </div>
      </Header>
      <Display>
        <div className="head">
          <div className="thumb"></div>
          <div className="data empresa">
            <p>EMPRESA</p>
          </div>
          <div className="data">
            <p>INÍCIO</p>
          </div>

          <div className="data">
            <p>FIM</p>
          </div>

          <div className="data">
            <p>SPED's</p>
          </div>

          <div className="data">
            <p>ENVIADOS</p>
          </div>

          <div className="data">
            <p>FALTANTES</p>
          </div>
        </div>
        <div className="display">
          {infos.length === 0 ? (
            <Loader />
          ) : (
            infos.map((obj) => (
              <div className="item-cont">
                <div
                  className="item"
                  onClick={() => handleChoose(obj.empresa.cnpj)}
                >
                  <div className="thumb">
                    <IoCubeOutline style={{ color: obj.empresa.color }} />
                  </div>
                  <div className="info">
                    <p>{obj.empresa.nome}</p>
                    <p className="cnpj">{obj.empresa.cnpj}</p>
                  </div>
                  <div className="data">
                    <p>
                      {getMonth(obj.periodo.start.mes)} {obj.periodo.start.ano}
                    </p>
                  </div>
                  <div className="data">
                    <p>
                      {" "}
                      {getMonth(obj.periodo.end.mes)} {obj.periodo.end.ano}
                    </p>
                  </div>
                  <div className="data">
                    <p>{obj.speds.length + obj.faltantes.length}</p>
                  </div>
                  <div className="data">
                    <p>{obj.speds.length}</p>
                  </div>
                  <div className="data">
                    <p>{obj.faltantes.length}</p>
                  </div>
                </div>
                <div className={`info-box ${checkClass(obj.empresa.cnpj)}`}>
                  <div className="info-header">
                    <div className="item-content thumb"></div>

                    <div className="item-content arquivo">
                      <p>ARQUIVO</p>
                    </div>

                    <div className="item-content">
                      <p>PERIODO</p>
                    </div>
                    <div className="item-content">
                      <p>NFe's</p>
                    </div>
                    <div className="item-content">
                      <p>ENCONTRADAS</p>
                    </div>
                    <div className="item-content">
                      <p>FALTANTES</p>
                    </div>
                  </div>
                  <div className="info-display">
                    {obj.faltantes.map((faltante) => (
                      <div className="info-item death">
                        <div className="thumb-info">
                          <IoCubeOutline />
                        </div>
                        <div className="item-content arquivo">
                          <h2>{obj.empresa.cnpj}</h2>
                        </div>
                        <div className="item-content">
                          <h2>
                            {String(faltante.mes).length === 1
                              ? `0${faltante.mes}`
                              : faltante.mes}{" "}
                            / {faltante.ano}
                          </h2>
                        </div>

                        <div className="item-content">
                          <h2>-</h2>
                        </div>

                        <div className="item-content">
                          <h2>-</h2>
                        </div>

                        <div className="item-content">
                          <h2>-</h2>
                        </div>
                      </div>
                    ))}

                    {obj.speds.map((obj) => (
                      <div className="info-item">
                        <div className="thumb-info">
                          <IoCubeOutline style={{ color: obj.color }} />
                        </div>
                        <div className="item-content arquivo">
                          <h2>{(obj.arquivo, 65)}</h2>
                        </div>
                        <div className="item-content">
                          <h2>
                            {String(obj.mes + 1).length === 1
                              ? `0${obj.mes + 1}`
                              : obj.mes + 1}{" "}
                            / {obj.ano}
                          </h2>
                        </div>

                        <div className="item-content">
                          <h2>{obj.nfes}</h2>
                        </div>

                        <div className="item-content">
                          <h2>{obj.nfes - obj.faltantes}</h2>
                        </div>

                        <div className="item-content">
                          <h2>{obj.faltantes}</h2>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </Display>
    </Block>
  );
}

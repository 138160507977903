import React, { createContext, useState, useContext } from "react";

const DownloadContext = createContext();
export default function DownloadProvider({ children }) {
  const [cnpjs, setCnpjs] = useState([]);
  const [type, setType] = useState("");
  const [filter, setFilter] = useState("");
  const [infos, setInfos] = useState();

  const [filterDate, setFilterDate] = useState({
    start: { mes: 0, ano: 2013 },
    end: { mes: new Date().getMonth(), ano: new Date().getFullYear() },
  });

  return (
    <DownloadContext.Provider
      value={{
        cnpjs,
        setCnpjs,
        filterDate,
        setFilterDate,
        type,
        setType,
        filter,
        setFilter,
        infos,
        setInfos,
      }}
    >
      {children}
    </DownloadContext.Provider>
  );
}

export function useDownload() {
  const context = useContext(DownloadContext);
  const {
    cnpjs,
    setCnpjs,
    filterDate,
    setFilterDate,
    type,
    setType,
    filter,
    setFilter,
    infos,
    setInfos,
  } = context;
  return {
    cnpjs,
    setCnpjs,
    filterDate,
    setFilterDate,
    type,
    setType,
    filter,
    setFilter,
    infos,
    setInfos,
  };
}

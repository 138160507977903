import React, { useEffect, useState, useCallback, useRef } from "react";
import { CardContainer, Header, Content, Infos, Card } from "./style";
import {
  AiOutlineClose,
  AiOutlineCloudDownload,
  AiOutlineFileSearch,
} from "react-icons/ai";
import { BsCalendarDate, BsDownload } from "react-icons/bs";
import { RxEyeNone } from "react-icons/rx";
import axios from "axios";
import { useProjetos } from "../../../../hooks/projetos";
import ContentLoader from "react-content-loader";
import { GiCircle, GiPlainCircle } from "react-icons/gi";

const uri = process.env.REACT_APP_API_URI;

export default function InfosViaNuvem() {
  const { projeto } = useProjetos();
  const [infos, setInfos] = useState({});
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [update, setUpdate] = useState(true);
  const [limit, setLimit] = useState(100);
  const [filter, setFilter] = useState("todas");

  const observer = useRef();
  const listRef = useRef(null);
  const scrollPositionRef = useRef(0);

  const last_element = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && !isFetching && !loading) {
          setIsFetching(true);
          setUpdate(true);
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, isFetching, loading]
  );

  useEffect(() => {
    axios
      .get(`${uri}/pro/reports/infos-vianuvem/${projeto}`)
      .then((res) => {
        setInfos(res.data); // Atualiza o estado 'infos' com os dados recebidos
      })
      .catch((error) => {
        console.error("Error fetching infos:", error);
      });
  }, [projeto, filter]);

  useEffect(() => {
    const fetchData = async () => {
      if (update) {
        setLoading(true);
        scrollPositionRef.current = listRef.current.scrollTop;
        try {
          const res = await axios.post(
            `${uri}/pro/reports-infonuvem/${projeto}`,
            { page, limit, filter }
          );
          const newReports = res.data.data.data;
          setReports((prevReports) => [...prevReports, ...newReports]);
          setHasMore(res.data.data.next !== null && newReports.length > 0);
          setUpdate(false);
        } catch (error) {
          console.error("Error fetching reports:", error);
        } finally {
          setLoading(false);
          setIsFetching(false);
          setTimeout(() => {
            listRef.current.scrollTop = scrollPositionRef.current;
          }, 0);
        }
      }
    };

    fetchData();
  }, [projeto, page, update, filter]);

  function handleClose() {
    document.getElementById("blackscreen").classList.toggle("ativ");
  }

  function DownloadButton() {
    const handleDownload = async () => {
      try {
        const response = await axios.post(
          `${uri}/pro/reports/download/${projeto}`,
          { ano: new Date().getFullYear(), mes: new Date().getMonth() },
          { responseType: "blob" }
        );
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute(
          "download",
          `report-${new Date().getMonth()}-${new Date().getFullYear()}.zip`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        console.error("Error downloading the report:", error);
      }
    };

    return <BsDownload onClick={handleDownload} />;
  }

  function Loader() {
    return (
      <CardContainer>
        <Header>
          <div className="btn-close" onClick={handleClose}>
            <AiOutlineClose />
          </div>
          <div className="header-text">
            <p>Notas ViaNuvem</p>
          </div>
        </Header>
        <Infos>
          <Card>
            <div className="head">
              <AiOutlineFileSearch />
              <p>Total</p>
            </div>
            <div className="content">
              <ContentLoader speed={2} width={680} height={35}>
                <rect x="15" y="10" rx="3" ry="3" width="70%" height="10" />
              </ContentLoader>
            </div>
          </Card>
          <Card>
            <div className="head">
              <AiOutlineCloudDownload />
              <p>Enviadas</p>
            </div>
            <div className="content">
              <ContentLoader speed={2} width={680} height={35}>
                <rect x="15" y="10" rx="3" ry="3" width="70%" height="10" />
              </ContentLoader>
            </div>
          </Card>
          <Card>
            <div className="head">
              <RxEyeNone />
              <p>Faltantes</p>
            </div>
            <div className="content">
              <ContentLoader speed={2} width={680} height={35}>
                <rect x="15" y="10" rx="3" ry="3" width="70%" height="10" />
              </ContentLoader>
            </div>
          </Card>
        </Infos>
        <Content>
          <div className="header">
            <div className="filter-header">
              <div
                id="todas"
                className={`filter-item ${checkClass(filter, "todas")}`}
                onClick={() => handleFilterChange("todas")}
              >
                <p>Total</p>
              </div>
              <div
                id="faltantes"
                className={`filter-item ${checkClass(filter, "faltantes")}`}
                onClick={() => handleFilterChange("faltantes")}
              >
                <p>Faltantes</p>
              </div>
            </div>
          </div>
          <div className="table-header">
            <div className="status-icon">
              <GiCircle />
            </div>
            <div className="data">
              <BsCalendarDate />
              <p>Data</p>
            </div>
            <div className="cnpj">
              <AiOutlineFileSearch />
              <p>CNPJ</p>
            </div>
            <div className="key">
              <AiOutlineCloudDownload />
              <p>Notas</p>
            </div>
          </div>
          <div className="display" ref={listRef}>
            {reports.map((report, index) => (
              <div
                className="items"
                key={index}
                ref={index === reports.length - 1 ? last_element : null}
              >
                <div className="status-icon">
                  {report.nuvem === true ? (
                    <GiPlainCircle color="rgba(0, 200, 50)" />
                  ) : (
                    <GiPlainCircle style={{ opacity: 0.5 }} />
                  )}
                </div>
                <div className="data">
                  <p>
                    {new Date(report.dhEvento).toLocaleString("pt-BR", {
                      timeZone: "America/Sao_Paulo",
                      dateStyle: "short",
                      timeStyle: "medium",
                    })}
                  </p>
                </div>
                <div className="cnpj">
                  <p>
                    {report.cnpj === undefined
                      ? projeto === "639903d1e193693c0d03e01f"
                        ? "71444475000115"
                        : "CNPJ não disponível"
                      : report.cnpj}
                  </p>
                </div>
                <div className="key">
                  <p>{report.nfe}</p>
                </div>
              </div>
            ))}
          </div>
        </Content>
      </CardContainer>
    );
  }

  function handleFilterChange(filter) {
    setFilter(filter);
    setUpdate(true);
    setPage(1);
    setReports([]);

    axios
      .get(`${uri}/pro/reports/infos-vianuvem/${projeto}`)
      .then((res) => {
        setInfos(res.data); // Atualiza o estado 'infos' com os dados recebidos
      })
      .catch((error) => {
        console.error("Error fetching infos:", error);
      });
  }

  function checkClass(currentType, type) {
    return currentType === type ? "selected" : "";
  }

  return loading ? (
    <Loader />
  ) : (
    <CardContainer>
      <Header>
        <div className="btn-close" onClick={handleClose}>
          <AiOutlineClose />
        </div>
        <div className="header-text">
          <p>Notas ViaNuvem</p>
        </div>
      </Header>
      <Infos>
        <Card>
          <div className="head">
            <AiOutlineFileSearch />
            <p>Total</p>
          </div>
          <div className="content">
            <h2>{infos.total}</h2>
          </div>
        </Card>
        <Card>
          <div className="head">
            <AiOutlineCloudDownload />
            <p>Enviadas</p>
          </div>
          <div className="content">
            <h2>{infos.enviadas}</h2>
          </div>
        </Card>
        <Card>
          <div className="head">
            <RxEyeNone />
            <p>Faltantes</p>
          </div>
          <div className="content">
            <h2>{infos.faltantes}</h2>
          </div>
        </Card>
      </Infos>
      <Content>
        <div className="header">
          <div className="filter-header">
            <div
              id="todas"
              className={`filter-item ${checkClass(filter, "todas")}`}
              onClick={() => handleFilterChange("todas")}
            >
              <p>Total</p>
            </div>
            <div
              id="faltantes"
              className={`filter-item ${checkClass(filter, "faltantes")}`}
              onClick={() => handleFilterChange("faltantes")}
            >
              <p>Faltantes</p>
            </div>
          </div>
        </div>
        <div className="table-header">
          <div className="status-icon">
            <GiCircle />
          </div>
          <div className="data">
            <BsCalendarDate />
            <p>Data</p>
          </div>
          <div className="cnpj">
            <AiOutlineFileSearch />
            <p>CNPJ</p>
          </div>
          <div className="key">
            <AiOutlineCloudDownload />
            <p>Notas</p>
          </div>
        </div>
        <div className="display" ref={listRef}>
          {reports.map((report, index) => (
            <div
              className="items"
              key={index}
              ref={index === reports.length - 1 ? last_element : null}
            >
              <div className="status-icon">
                {report.nuvem === true ? (
                  <GiPlainCircle color="rgba(0, 200, 50)" />
                ) : (
                  <GiPlainCircle style={{ opacity: 0.5 }} />
                )}
              </div>
              <div className="data">
                <p>
                  {new Date(report.dhEvento).toLocaleString("pt-BR", {
                    timeZone: "America/Sao_Paulo",
                    dateStyle: "short",
                    timeStyle: "medium",
                  })}
                </p>
              </div>
              <div className="cnpj">
                <p>
                  {report.cnpj === undefined
                    ? projeto === "639903d1e193693c0d03e01f"
                      ? "71444475000115"
                      : "CNPJ não disponível"
                    : report.cnpj}
                </p>
              </div>
              <div className="key">
                <p>{report.nfe}</p>
              </div>
            </div>
          ))}
        </div>
      </Content>
    </CardContainer>
  );
}

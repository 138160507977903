import styled from 'styled-components'


export const Container = styled.div`
  height: 7em;
  width: 30em;
  background-color: white;
  display: flex;
  position: fixed;
  flex-direction: column;
  right: 10px;
  bottom: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  z-index: 2;

  .header {
    width: 100%;
    height: 2em;
    display: flex;
    align-items: center;

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    h1 {
      margin-left: 20px;
      font-size: 0.9em;
    }
  }
  .content {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - 2em);
    display: flex;
    flex-direction: column;
    .loadContainer {
      height: 3em;
      width: 90%;
      .loadTag {
        width: 100%;
        height: 1.8em;
        display: flex;
        justify-content: space-between;
        p {
          font-size: 0.8em;
          opacity: 0.6;
        }
      }
      .loadBar {
        width: 100%;
        height: 1em;
        border-radius: 5px;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.1);
        .progress {
          width: 0%;
          height: 100%;
          background-color: rgba(0, 200, 100, 1);
        }
      }
    }
  }
`;
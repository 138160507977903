import { Filter, Minidiv, OuterBox, PinBar, SearchBar } from "./style";
import Dater from "./components/dater";
import ParticipantesBox from "./components/participantes";
import CFOPBox from "./components/cfop";
import { useNfe } from "../../hooks/nfe";
import { useState } from "react";
import { AiOutlineReload } from "react-icons/ai";

export default function Filer() {
  const [emit, setEmit] = useState();
  const [dest, setDest] = useState();
  const [cfop, setCfop] = useState();
  const [date, setDate] = useState();
  const { filter, setFilter, setPage, setNfes, setQuery, setFilterStatus } = useNfe();

  function handleFilter() {
    setNfes([]);
    setPage(1);
    setFilter({
      download: false,
      emi: emit !== undefined ? emit.doc : emit,
      dest: dest !== undefined ? dest.doc : dest,
      cfop: cfop !== undefined ? cfop.cod : cfop,
      data: date.date,
    });
    setFilterStatus(true);
  }
  function resetFilter() {
    window.location.reload()
  }

  return (
    <Filter id="filter" style={{ display: "none" }}>
      <Dater date={date} setDate={setDate} />
      <ParticipantesBox
        emit={emit}
        setEmit={setEmit}
        dest={dest}
        setDest={setDest}
      />
      <CFOPBox cfop={cfop} setCfop={setCfop} />
      <div className="btn-container" onClick={handleFilter}>
        <div className="btns">
          <AiOutlineReload onClick={() => resetFilter()} />
          <div className="btn-apply">
            <p>Filtrar</p>
          </div>
        </div>
      </div>
    </Filter>
  );
}

import React, { useEffect, useState } from "react";
import MyLoader from "./components/ContentLoader";
import Document from "./components/Regulares";
import Irregulares from "./components/Irregulares";
import InfoBar from "./components/InfoBar/InfoBar";
import { Container } from "./style";
import { useNfe } from "../../../../hooks/nfe";
import DownPop from "./components/Downpop";
import Chat from "../MiniChat/chat";
import Canceladas from "./components/Canceladas";


export default function NFes() {
  const { status } = useNfe();

  const stages = [
    { nome: "regular", comp: <Document /> },
    {
      nome: "irregular",
      comp: <Irregulares />,
    },
    { nome: "canceladas", comp: <Canceladas /> },
  ];

  return (
    <Container>
      <InfoBar />
      {stages.find((obj) => status === obj.nome).comp}
      <DownPop />
    </Container>
  );
}

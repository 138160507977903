import styled from "styled-components";

export const Container = styled.div`
  width: max-content;
  height: max-content;
  max-height: 68em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white;
  overflow: hidden;

  .drop-box {
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;

    .files-container {
      width: 100%;
      min-height: max-content;
      display: flex;
      flex-direction: column;

      .files-list {
        width: 100%;
        display: flex;
        max-height: 12em;
        overflow: overlay;
        flex-direction: column;

        .file {
          width: 100%;
          height: 3em;
          display: flex;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          background-color: white;

          .thumb {
            width: 3em;
            height: 3em;
            display: flex;
            justify-content: center;
            align-items: center;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            img {
              width: 1.2em;
            }
          }
          .text {
            width: calc(100% - 6em);
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 1em;
            h2 {
              font-size: 0.9em;
              font-weight: 500;
            }
            p {
              font-size: 0.8em;
              opacity: 0.8;
              line-height: 15px;
            }
          }
          .delete-btn {
            width: 3em;
            height: 3em;
            display: flex;
            justify-content: center;
            align-items: center;
            border-left: 1px solid rgba(0, 0, 0, 0.1);
            opacity: 0.5;

            cursor: pointer;
            &:hover {
              opacity: 0.8;
            }
            svg {
              color: red;
            }
          }
        }
      }
      .progress-bar {
        width: 100%;
        height: 0.5em;
        background-color: rgba(0, 0, 0, 0.2);
        .load {
          width: 0%;
          height: 100%;
          background-color: rgba(0, 180, 100);
        }
      }
    }
  }

  .send-files {
    width: 100%;
    height: 4em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0em;

    .btn {
      width: max-content;
      height: 2em;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(15, 15, 15);
      border-radius: 5px;
      cursor: pointer;
      opacity: 0.8;
      transition: all 0.2s;

      &:hover {
        opacity: 1;
      }

      h2 {
        color: white;
        font-size: 1em;
        padding: 5px 30px;
      }
    }
  }
`;

export const Header = styled.div`
  width: 100%;
  min-height: 2em;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(250, 250, 250);

  .close {
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &:hover {
      background-color: white;
    }

    svg {
      opacity: 0.5;
      font-size: 0.8em;
    }
  }

  .arrow {
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &:hover {
      background-color: white;
    }

    svg {
      opacity: 0.5;
      font-size: 0.8em;
    }
  }

  .export {
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &:hover {
      background-color: white;
    }

    svg {
      opacity: 0.7;
      font-size: 0.8em;
    }
  }

  .text {
    width: calc(100% - 2em);
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-size: 0.8em;
      font-weight: 600;
      opacity: 0.6;
      transform: translateX(-1.8em);
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  height: calc(100% - 2em);
  display: flex;
  justify-content: center;

  .drop-filter {
    width: max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
  }
`;

export const FilterHeader = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 1em 0;

  .toggle-filter {
    width: max-content;
    height: 1.7em;
    background-color: rgb(250, 250, 250);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 1em;
    padding: 0 0.5em;

    .check-box {
      width: 0.5em;
      height: 0.5em;
      border: 1px solid rgba(0, 0, 0, 0.2);
      margin-right: 5px;
      background-color: white;
      border-radius: 100%;
    }

    .text {
      width: max-content;
      height: 100%;
      display: flex;
      align-items: center;

      p {
        font-size: 0.85em;
        opacity: 0.8;
      }
    }
  }

  .general-filter {
    width: max-content;
    height: max-content;
    display: flex;
    align-items: center;
    margin-left: 60px;

    .general-box {
      width: 0.5em;
      height: 0.5em;
      border-radius: 100%;
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.2);
      margin-right: 10px;
      cursor: pointer;
    }

    .buttons {
      width: max-content;
      height: 1.7em;
      border: 1px solid rgba(0, 0, 0, 0.1);
      margin-right: 1em;
      border-radius: 10px;
      display: flex;
      user-select: none;
      align-items: center;

      div {
        width: 3em;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0.6;

        p {
          font-size: 0.9em;
          opacity: 0.6;
          font-weight: 500;
        }

        &:last-child {
          border-left: 1px solid rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
`;

export const DropZone = styled.div`
  width: 30em;
  height: max-content;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em;
  margin-top: 0;
  cursor: pointer;
`;

export const DropArea = styled.div`
  width: 95%;
  height: max-content;
  min-height: 18em;
  border-radius: 10px;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: rgba(250, 250, 250);

  .text-container {
    transform: translateX(2em);
    width: 30em;
    height: max-content;
    display: flex;
    justify-content: center;
    .thumb {
      height: 4em;
      display: flex;
      justify-content: center;
      align-items: center;

      .circle {
        height: 1.5em;
        width: 1.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(0, 150, 200, 0.8);
        border-radius: 100%;
        p {
          color: rgba(0, 150, 200, 0.8);
        }
      }
    }
    .text {
      margin-left: 10px;
      width: calc(100%);
      display: flex;
      flex-direction: column;
      h2 {
        font-weight: 500;
        font-size: 1.1em;
      }
      p {
        width: 70%;
        margin-top: 7px;
        font-size: 0.8em;
        opacity: 0.8;
        line-height: 15px;
      }
    }
  }
`;

import styled from "styled-components";

export const BodyBox = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 6em);
  flex-direction: column;

  overflow: overlay;
  overflow-x: hidden;
`;
export const Card = styled.div`
  width: 97%;
  min-height: 14.5em;
  border-radius: 10px;
  margin-top: 1.5em;
  border-left: 0.5em solid green;
  margin-left: 1rem;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  &:first-child {
    margin-top: 0.5em;
  }

  &:last-child {
    margin-bottom: 2em;
  }
`;

export const Header = styled.div`
  width: 100%;
  min-height: 2em;
  margin-top: 1em;

  display: flex;
  justify-content: space-between;

  .infos {
    width: max-content;
    height: 100%;
    display: flex;
    margin: 0 2em;
    align-items: center;

    p {
      color: #0f3d7d;
      font-size: 0.8em;
      font-weight: 600;
    }

    .btn {
      width: max-content;
      height: 2em;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      margin-left: 30px;
      border-radius: 50px;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
      cursor: pointer;

      &.yellow {
      }

      p {
        color: black;
        padding: 0 30px;
        font-weight: 500;
      }
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .content {
    width: 95%;
    height: 90%;
    display: flex;
    flex-direction: column;
  }
`;

export const Infos = styled.div`
  width: 100%;
  height: calc(100% - 3em);
  display: flex;
  align-items: center;

  .thumb {
    width: 6em;
    height: 6em;
    display: flex;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    opacity: 0.5;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .info {
    width: 28em;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: 1em;

    &.small {
      width: 10em;
    }

    &:last-child {
      border: none;
    }

    .tag {
      width: 100%;
      height: 1.5em;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 1em;
      p {
        font-size: 0.7em;
        opacity: 0.5;
      }
    }

    .line {
      width: 100%;
      height: 1.5em;
      display: flex;
      align-items: center;

      h5 {
        font-size: 0.7em;
        margin-right: 10px;
      }
      p {
        font-size: 0.8em;
      }
    }
  }
`;

export const Head = styled.div`
  width: 100%;
  height: 3em;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: space-between;

  input {
    width: 1em;

    border: 1px solid rgba(0, 0, 0, 0.5);
  }

  .infos {
    width: max-content;
    height: 100%;
    display: flex;
    align-items: center;

    .tag {
      width: max-content;
      height: max-content;
      border-radius: 50px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      opacity: 0.7;
      font-size: 0.8em;

      margin-left: 2em;
      p {
        font-size: 1em;
        padding: 0px 25px;
      }
    }

    h2 {
      font-size: 1em;
      margin-left: 1em;
    }

    .btn {
      width: 3em;
      height: 3em;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      opacity: 0.5;
      transition: all 0.2s;

      &:hover {
        opacity: 1;
      }
    }
  }
`;

export const Section = styled.div`
  display: flex;
`;
export const Titles = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 100%;
  overflow: hidden;
  align-items: flex-start;
  justify-content: space-around;

  h1 {
    margin-left: 2rem;
    margin-top: 0.4em;
    margin-bottom: 2rem;
    font-size: 1.4rem;
  }

  h2 {
    margin-left: 1.5rem;
    margin-top: 0.8em;
    font-size: 1rem;
  }

  h3 {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 0.8em;
    margin-left: 0.3rem;
  }
`;
export const ReactLoader = styled.div`
  backgroundcolor: #ffffff;
`;

export const Line = styled.div`
  display: flex;
`;

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Status = styled.div`
  width: 100%;
  height: max-content;

  .header {
    width: 100%;
    min-height: 1.5em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(245, 245, 245);

    p {
      font-size: 0.75em;
      opacity: 0.6;
    }
  }

  .display {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;

    .item {
      width: 100%;
      height: 2.5em;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      svg {
        margin: 0 7px;
        opacity: 0.8;
        font-size: 0.8em;
      }

      p {
        font-size: 0.75em;
        opacity: 0.8;
      }
    }
  }
`;

export const Items = styled.div`
  width: 100%;
  min-height: max-content;
  display: flex;
  flex-direction: column;
  align-items: start;
  overflow: overlay;
  overflow-x: hidden;

  .header {
    width: 100%;
    min-height: 1.5em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(245, 245, 245);

    p {
      font-size: 0.75em;
      opacity: 0.6;
    }
  }

  .item {
    width: 100%;
    min-height: 4em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;

    &.todo {
      opacity: 0.6;
    }

    .check {
      width: 3.5em;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .content {
      width: calc(100% - 3.5em);
      height: 100%;
      display: flex;
      align-items: start;
      justify-content: center;

      .text {
        width: 95%;
        height: 1em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          width: 100%;
          font-size: 0.8em;
          line-height: 12px;
          opacity: 0.6;

          &:last-child {
            width: 12em;
            font-size: 0.7em;
            transform: translateX(-1em);
            display: flex;
            justify-content: flex-end;
          }
        }
      }

      .progress-content {
        width: 95%;
        height: 1.2em;
        display: flex;
        align-items: center;
        justify-content: start;

        .progressbar {
          width: 100%;
          height: 3px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          transform: translateY(5px);
          .progress {
            max-width: 100%;
            height: 100%;
            background-color: rgba(0, 150, 200, 0.4);
          }
        }
      }
    }
  }
`;

export const Reset = styled.div`
  min-height: 5em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .btn {
    width: 8em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: rgba(15, 15, 15);
    opacity: 0.8;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      opacity: 1;
    }

    p {
      color: white;
      font-weight: 600;
    }
  }
`;

export const Loaded = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 0.9em;
    opacity: 0.8;
    margin-top: 2em;
  }
`;

import { useEffect } from "react";
import { Container, Danfe } from "./style";
import { IoIosClose } from "react-icons/io";
import { useBlackScreen } from "../../../../hooks/blackscreen";
import axios from "axios";

import alerta from "../../../../utils/alerta";
const uri = process.env.REACT_APP_API_URI;

export default function NewUpdate() {
  const { openBlackScreen } = useBlackScreen();

  function sendNewUpdate() {
    var titulo = document.getElementById("update_titulo").value;
    var desc = document.getElementById("update_desc").value;
    var imagem = document.getElementById("update_imagem").value;
    var page = document.getElementById("update_page").value;
    var check = true;

    if (titulo === "") {
      alerta("O título não pode ser vazio", false, "err");
      check = false;
    }

    if (desc === "") {
      alerta("A descrição não pode ser vazia", false, "err");
      check = false;
    }

    if (imagem === "") {
      alerta("O link da imagem não pode ser vazio", false, "err");
      check = false;
    }

    if (page === "") {
      alerta("O ID da página não pode ser vazio", false, "err");
      check = false;
    }

    if (check === true) {
      axios
        .post("https://www.ontax.app/pro/update", {
          titulo,
          desc,
          imagem,
          page,
        })
        .then((res) => {
          alerta(res.data.msg, false, "success");
        });
    }
  }

  return (
    <Container className="modal">
      <div className="head">
        <div className="btn" onClick={() => openBlackScreen("new_update")}>
          <IoIosClose />
        </div>
        <div className="head-info">
          <h2>NOVO UPDATE</h2>
        </div>
      </div>
      <div className="infos">
        <div className="info">
          <div className="content">
            <div className="tag">
              <p>TITULO</p>
            </div>
            <div className="input-container">
              <input id="update_titulo" />
            </div>
          </div>
        </div>
        <div className="info">
          <div className="content">
            <div className="tag">
              <p>IMAGEM</p>
            </div>
            <div className="input-container">
              <input id="update_imagem" />
            </div>
          </div>
        </div>

        <div className="info">
          <div className="content">
            <div className="tag">
              <p>DESCRIÇÃO</p>
            </div>
            <div className="input-container">
              <input id="update_desc" />
            </div>
          </div>
        </div>
        <div className="info">
          <div className="content">
            <div className="tag">
              <p>PAGE ID</p>
            </div>
            <div className="input-container">
              <input id="update_page" />
            </div>
          </div>
        </div>
      </div>
      <div className="send">
        <div className="btn" onClick={sendNewUpdate}>
          <h2>ENVIAR</h2>
        </div>
      </div>
    </Container>
  );
}

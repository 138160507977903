import { useState, useEffect, useRef, useCallback } from "react";
import { Filter, Minidiv, OuterBox, PinBar, SearchBar } from "../../style";
import ContentLoader from "react-content-loader";

import { AiOutlineSearch } from "react-icons/ai";
import { RxCube } from "react-icons/rx";
import { IoMdClose } from "react-icons/io";

import axios from "axios";
import reduzir from "../../../../utils/reduzirStr";
import { useProjetos } from "../../../../hooks/projetos";
const uri = process.env.REACT_APP_API_URI;

export default function CFOPBox({ setCfop: setItem, cfop: item }) {
  const [cfop, setCfop] = useState([]);
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [search, setSearch] = useState();

  const observer = useRef();
  const last_element = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (hasMore) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      }
    });
    if (node) observer.current.observe(node);
  }, []);
  useEffect(() => {
    axios
      .post(`${uri}/pro/cfops?page=${page}&limit=${limit}`, { search })
      .then((res) => {
        if (res.data.data.length < limit) {
          setHasMore(false);
        }
        if (search !== undefined || search !== "") {
          setCfop(res.data.data);
        } else {
          var result = cfop.concat(res.data.data);
          setCfop(result);
        }
      });
  }, [page, search]);

  function LoadItem() {
    return (
      <PinBar>
        <div className="thumb">
          <ContentLoader speed={2} width={400} height={150}>
            <circle cx="25" cy="75" r="8" />
          </ContentLoader>
        </div>
        <OuterBox>
          <ContentLoader speed={2} width={400} height={150}>
            <rect x="3" y="60" rx="5" ry="5" width="45" height="6" />

            <rect x="3" y="75" rx="5" ry="5" width="139" height="6" />
          </ContentLoader>
        </OuterBox>
      </PinBar>
    );
  }

  function handleSearch(value) {
    setSearch(value);
    setPage(1);
  }

  function Part(obj) {
    return (
      <PinBar>
        <div className="thumb">
          <RxCube />
        </div>
        <OuterBox>
          <h2>{obj.cod}</h2>
          <p>{reduzir(obj.desc, 35)}</p>
        </OuterBox>
        <div className="thumb btn" onClick={() => setItem()}>
          <IoMdClose />
        </div>
      </PinBar>
    );
  }

  return (
    <>
      <Minidiv>
        <p>CFOPS</p>
      </Minidiv>
      {item !== undefined && Part(item)}
      <SearchBar>
        <input
          id="update_emitente"
          placeholder="Buscar CFOP"
          onChange={(e) => handleSearch(e.target.value)}
        />
        <AiOutlineSearch />
      </SearchBar>
      <div className="display">
        {cfop.length > 0 &&
          cfop.map((obj) => (
            <PinBar ref={last_element} onClick={() => setItem(obj)}>
              <div className="thumb">
                <RxCube />
              </div>
              <OuterBox>
                <h2>{obj.cod}</h2>
                <p>{reduzir(obj.desc, 35)}</p>
              </OuterBox>
            </PinBar>
          ))}
        {hasMore && <LoadItem />}
      </div>
    </>
  );
}

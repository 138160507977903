import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { useProjetos } from "../../../../hooks/projetos";
import {
  RevContainer,
  RevBlock,
  RevHeader,
  RevValue,
  RevFalseContainer,
  RevInfo,
  RevCard,
  RevGrafico,
  RevTabela,
} from "./style";
import ContentLoader from "react-content-loader";
import { MdClose, MdCheck } from "react-icons/md";
import { BiCube } from "react-icons/bi";
import { AiOutlineFileSearch, AiOutlineArrowRight } from "react-icons/ai";
import { CiWarning } from "react-icons/ci";
import FileDownload from "js-file-download";
import Relatorio from "./components/relatorio";
import { Block, Header, DropArea } from "./style";
import Dropzone, { useDropzone } from "react-dropzone";
import { BsFillTrashFill } from "react-icons/bs";
import { v4 as uuidv4 } from "uuid";

const uri = process.env.REACT_APP_API_URI;

export default function Revisor() {
  const [infos, setInfos] = useState();
  const [status, setStatus] = useState("initial"); // Ajuste do estado para refletir os diferentes estados do processo
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const { projeto } = useProjetos();
  const [report, setReport] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [popUp, setPopUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const taskId = useRef(uuidv4());

  const observer = useRef();
  const last_element = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage((prevPageNumber) => prevPageNumber + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  useEffect(() => {
    if (projeto && status === "completed") {
      axios
        .post(`${uri}/pro/revisor/report/${projeto}`, {
          page,
          limit,
        })
        .then((res) => {
          setReport((prevReport) => prevReport.concat(res.data.data));
          setInfos(res.data.infos);
          console.log(res.data);
        });
    }
  }, [status, page, projeto, limit]);

  function openRelatorio() {
    axios
      .post(`${uri}/pro/revisor/report/${projeto}`, {
        page,
        limit,
      })
      .then((res) => {
        if (res.data.infos !== undefined) {
          setStatus("completed");
        }
      });
  }

  function formatBytes(a, b = 2) {
    if (!+a) return "0 Bytes";
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
      ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
    }`;
  }

  function handleClose() {
    document.getElementById("blackscreen").classList.remove("ativ");
  }

  function handleDelete() {
    setFiles([]);
  }

  function handleChoice() {
    setPopUp(true);
  }

  function downloadFile(type) {
    axios
      .post(
        `${uri}/pro/revisor/download/${projeto}`,
        { type },
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        FileDownload(res.data, `faltantes.${type}`);
      });
  }

  function LoaderRevisor() {
    return (
      <RevContainer>
        <RevBlock className="table">
          <RevHeader>
            <div className="close" onClick={handleClose}>
              <MdClose />
            </div>
            <div className="text">
              <p>Ferramentas</p>
            </div>
            <div className="export" style={{ opacity: "0" }}></div>
          </RevHeader>
          <RevValue>
            <div className="toggle">
              <div id="speds" className="ativ">
                <BiCube />
                <p>Speds X Reports</p>
              </div>
              <div id="reports">
                <BiCube />
                <p>Reports X Speds</p>
              </div>
            </div>
            <div className="update" style={{ opacity: "0" }}></div>
          </RevValue>
          <RevFalseContainer>
            <RevInfo>
              <RevCard>
                <div className="head">
                  <AiOutlineFileSearch />
                  <p>Total</p>
                </div>
                <div className="content">
                  <ContentLoader speed={2} width={300} height={200}>
                    <rect x="20" y="90" rx="5" ry="5" width="80" height="15" />
                  </ContentLoader>
                </div>
              </RevCard>
              <RevCard>
                <div className="head">
                  <AiOutlineFileSearch />
                  <p>Faltantes</p>
                </div>
                <div className="content">
                  <ContentLoader speed={2} width={300} height={200}>
                    <rect x="20" y="90" rx="5" ry="5" width="80" height="15" />
                  </ContentLoader>
                </div>
              </RevCard>
              <RevCard>
                <div className="head">
                  <AiOutlineFileSearch />
                  <p>Encontradas</p>
                </div>
                <div className="content">
                  <ContentLoader speed={2} width={300} height={200}>
                    <rect x="20" y="90" rx="5" ry="5" width="80" height="15" />
                  </ContentLoader>
                </div>
              </RevCard>
            </RevInfo>

            <div className="container">
              <RevGrafico>
                <div className="index">
                  <div className="head">
                    <p>Index</p>
                  </div>
                  <div className="display">
                    {Array.from({ length: 3 }, (_, i) => (
                      <ContentLoader key={i} speed={2} width={600} height={58}>
                        <rect
                          x="20"
                          y="15"
                          rx="5"
                          ry="5"
                          width="300"
                          height="15"
                        />
                        <rect
                          x="20"
                          y="35"
                          rx="5"
                          ry="5"
                          width="120"
                          height="10"
                        />
                      </ContentLoader>
                    ))}
                  </div>
                </div>
              </RevGrafico>
              <RevTabela>
                <div className="head-table">
                  <div className="header-item">
                    <p>Nome</p>
                  </div>
                  <div className="header-item">
                    <p>CNPJ</p>
                  </div>
                  <div className="header-item">
                    <p>Faltantes</p>
                  </div>
                </div>
                <div className="display">
                  {Array.from({ length: 9 }, (_, i) => (
                    <ContentLoader key={i} speed={2} width={680} height={27}>
                      <rect
                        x="10"
                        y="15"
                        rx="5"
                        ry="5"
                        width="300"
                        height="10"
                      />
                      <rect
                        x="370"
                        y="15"
                        rx="5"
                        ry="5"
                        width="100"
                        height="10"
                      />
                      <rect
                        x="510"
                        y="15"
                        rx="5"
                        ry="5"
                        width="50"
                        height="10"
                      />
                    </ContentLoader>
                  ))}
                </div>
              </RevTabela>
            </div>
          </RevFalseContainer>
        </RevBlock>
      </RevContainer>
    );
  }

  function DropContainer(props) {
    const { getRootProps, getInputProps, isDragActive, isDragReject } =
      useDropzone({
        accept: {
          "text/plain": [],
        },
        onDropAccepted: (obj) => {
          setFiles((prevFiles) => prevFiles.concat(obj));
          console.log(files);
        },
      });

    return (
      <div className="drop-box">
        <DropArea
          {...getRootProps()}
          isDragActive={isDragActive}
          isDragReject={isDragReject}
        >
          <input {...getInputProps()} type="file" name="file" multiple />
          {renderDragMessage(isDragActive, isDragReject)}
        </DropArea>
        {files.length > 0 && (
          <div className="files-container">
            <div className="files-list">
              {files.map((file) => (
                <div className="file" key={file.path}>
                  <div className="thumb">
                    <img
                      src="https://cdn-icons-png.flaticon.com/128/2991/2991108.png"
                      alt="documento"
                    />
                  </div>
                  <div className="text">
                    <h2>{file.path}</h2>
                    <p>{formatBytes(file.size)}</p>
                  </div>
                  {progress === 0 && (
                    <div className="delete-btn" onClick={handleDelete}>
                      <BsFillTrashFill />
                    </div>
                  )}
                </div>
              ))}
            </div>
            {progress > 0 && (
              <div className="progress-bar">
                <div className="load" style={{ width: `${progress}%` }}></div>
              </div>
            )}
            <div className="send-files">
              <div className="btn" onClick={sendFile}>
                <h2>ENVIAR</h2>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  function sendFile() {
    const data = new FormData();
    files.forEach((file) => data.append("files", file));

    axios
      .post(`${uri}/pro/revisor/upload/${projeto}`, data, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      })
      .then(() => {
        setLoading(true);
        return axios.get(`${uri}/pro/revisor/create-report/${projeto}`);
      })
      .then((res) => {
        taskId.current = res.data.taskId; // Salva o taskId retornado pelo servidor
        // Após iniciar o processamento, começa o polling do status
        checkProcessingStatus(res.data.taskId);
      })
      .catch((error) => {
        console.error("Erro no processo:", error);
        setLoading(false);
      });
  }

  function checkProcessingStatus(taskId) {
    setLoading(true);
    const intervalId = setInterval(() => {
      axios
        .get(`${uri}/pro/revisor/check-status/${projeto}?taskId=${taskId}`)
        .then((response) => {
          const { status } = response.data;

          if (status === "completed") {
            clearInterval(intervalId);
            setStatus("completed"); // Isso deve atualizar a UI para refletir que o processamento foi concluído
            setLoading(false);
            // Ação adicional para após a conclusão do processamento, por exemplo, buscar os dados processados
          } else {
            setStatus("processing");
          }
        })
        .catch((error) => {
          console.error("Erro ao verificar o status:", error);
          clearInterval(intervalId); // Em caso de erro, interrompe o polling
          setLoading(false);
        });
    }, 5000); // Verifica o status a cada 5 segundos
  }

  function renderDragMessage(isDragActive, isDragReject) {
    if (!isDragActive) {
      return (
        <div className="text-container">
          <div className="thumb">
            <div className="circle">
              <p>+</p>
            </div>
            <div className="text">
              <h2>Carregar SPED's</h2>
              <p>Arraste aqui os arquivos que deseja carregar</p>
            </div>
          </div>
        </div>
      );
    }
    if (isDragReject) {
      return (
        <div className="text-container">
          <div className="thumb">
            <CiWarning color="rgba(200, 0, 0)" size={"25px"} />
            <div className="text">
              <h2>Formato não suportado</h2>
              <p>O arquivo deve estar no formato ".txt"</p>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="text-container">
        <div className="thumb">
          <div className="circle" style={{ borderColor: "rgba(0, 200, 100)" }}>
            <MdCheck color="rgba(0, 200, 100)" />
          </div>
          <div className="text">
            <h2>Solte o arquivo aqui</h2>
            <p>Formato suportado</p>
          </div>
        </div>
      </div>
    );
  }

  if (loading) {
    return <LoaderRevisor />;
  }

  if (status === "initial" || status === "processing") {
    return (
      <Block>
        <Header>
          <div className="close" onClick={handleClose}>
            <MdClose />
          </div>
          <div className="text">
            <p>Ferramentas</p>
          </div>
          <div className="arrow" onClick={openRelatorio}>
            <AiOutlineArrowRight />
          </div>
        </Header>
        <DropContainer />
      </Block>
    );
  } else if (status === "completed") {
    return (
      <Relatorio
        setUpdate={setStatus}
        loading={loading}
        setLoading={setLoading}
      />
    );
  }
}

import React, { useState, useEffect } from "react";
import axios from "axios";

import { Container, Items, Reset, Loaded, Status } from "./style.js";

import ContentLoader from "react-content-loader";

import alerta from "../../../../utils/alerta";
import Loading from "../../../../utils/loading/index.js";

import {
  IoCheckmarkOutline,
  IoCubeOutline,
  IoTimerOutline,
  IoDocumentOutline,
} from "react-icons/io5";
import { CiClock1 } from "react-icons/ci";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { GiConvergenceTarget } from "react-icons/gi";

import { useProjetos } from "../../../../hooks/projetos.js";
import { useUsers } from "../../../../hooks/user.js";

const uri = process.env.REACT_APP_API_URI;

export default function Task() {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState();
  const { projeto, getEmpresas } = useProjetos();
  const { user } = useUsers();

  const itemStatus = [
    { prop: "", text: "Início", icon: <IoTimerOutline /> },
    { prop: "", text: "Agora", icon: <CiClock1 /> },
    { prop: "", text: "Total faltantes", icon: <GiConvergenceTarget /> },
    {
      prop: "",
      text: "Progresso de notas baixadas",
      icon: <AiOutlineLoading3Quarters />,
    },
    { prop: "", text: "NFE's", icon: <IoDocumentOutline /> },
  ];

  useEffect(() => {
    let isMounted = true; // Flag para controlar o ciclo de vida do componente
    let intervalId; // Identificador do intervalo para limpeza

    const fetchTasks = async () => {
      try {
        const res = await axios.get(`${uri}/tasks/${projeto}`);
        setLoading(false);
        if (res.status === 200) {
          const updatedData = res.data.map((task) => {
            if (task.tipo === "upload_nfes") {
              return {
                ...task,
                goal: formatSize(task.goal),
                progress: formatSize(task.progress),
              };
            }
            return task;
          });

          // Verifica se todos os itens têm status 'done' ou se o array está vazio
          if (
            (updatedData.length !== 0 ||
              updatedData.every((task) => task.status === "done")) &&
            isMounted
          ) {
            setTasks(updatedData);
          }
        }
      } catch (error) {
        console.error("Erro ao buscar tarefas:", error);
      }
    };

    // Configura um intervalo para a função fetchTasks
    intervalId = setInterval(fetchTasks, 2000); // Intervalo de 2 segundos

    // Função de limpeza
    return () => {
      isMounted = false; // Indica que o componente foi desmontado
      clearInterval(intervalId); // Limpa o intervalo
    };
  }, [uri, projeto]);

  function percent(total, num) {
    var perc = total / 100;

    return parseFloat(num / perc).toFixed(2);
  }

  function findStage(task, type) {
    const stages = [
      {
        tipo: "upload_nfes",
        text: {
          base: "Enviando arquivos para o servidor",
          success: "Arquivos enviados para o servidor",
          todo: "Enviar arquivos para o servidor",
        },
      },
      {
        tipo: "google_xml",
        text: {
          base: "Procurando XML's na conta Gmail",
          success: "Arquivos encontrados",
          todo: "Procurar XML's na conta Gmail",
        },
      },
      {
        tipo: "download_google",
        text: {
          base: "Fazendo download dos XML's encontrados",
          success: "Arquivos enviados para o servidor",
          todo: "Baixar XML's encontrados",
        },
      },
      {
        tipo: "unzip",
        text: {
          base: "Extraindo arquivos",
          success: "Arquivos extraídos",
          todo: "Extrair arquivos comprimidos",
        },
      },
      {
        tipo: "count_xml",
        text: {
          base: "Procurando XMLS",
          success: "XMLS encontrados",
          todo: "Procurar XMLS nos arquivos enviados",
        },
      },
      {
        tipo: "transf_xml",
        text: {
          base: "Extraindo NFes",
          success: "NFes transferidas",
          todo: "Transferir XMLS para o banco de dados",
        },
      },

      {
        tipo: "checklist",
        text: {
          base: "Processando NFe's",
          success: "NFe's processadas",
          todo: "Processas NFe's enviadas",
        },
      },
      {
        tipo: "upload",
        text: {
          base: "Enviando arquivos para o servidor",
          success: "Arquivos enviados para o servidor",
          todo: "Enviar NFe's para o servidor",
        },
      },
      {
        tipo: "process_speds",
        text: {
          base: "Processando SPED's enviados",
          success: "SPED's processados",
          todo: "Processar SPED's enviados",
        },
      },

      {
        tipo: "process_empresas",
        text: {
          base: "Cadastrando empresas",
          success: "Empresas cadastradas",
          todo: "Cadastrar empresas",
        },
      },

      {
        tipo: "compress_speds",
        text: {
          base: "Comprimindo SPED's para download",
          success: "Arquivos prontos para download",
          todo: "Preparar SPED's para download",
        },
      },
      {
        tipo: "transf_to_compress",
        text: {
          base: "Transferindo NFe's filtradas para compressão",
          success: "NFe's transferidas",
          todo: "Separar NFe's filtradas para download",
        },
      },

      {
        tipo: "compress_nfes",
        text: {
          base: "Comprimindo notas fiscas para download",
          success: "Arquivos prontos para download",
          todo: "Preparar NFe's para download",
        },
      },

      {
        tipo: "process_nfes",
        text: {
          base: "Processando notas",
          success: "Notas fiscais processadas",
          todo: "Processar informações das notas fiscais",
        },
      },
    ];

    try {
      const stage = stages.find((obj) => obj.tipo === task.tipo);

      if (task.status === "doing") {
        return stage.text.base;
      }

      if (task.status === "todo") {
        return stage.text.todo;
      }

      return stage.text.success;
    } catch (e) {
      console.log(e);
      return "";
    }
  }

  function handleReset() {
    function start() {
      axios.get(`${uri}/projetos/reset/${projeto}`).then((res) => {
        if (res.status === 200) {
          alerta(res.data.msg, false, "success");
        }
      });
    }

    alerta(
      "Tem certeza que deseja cancelar as tarefas atuais?",
      true,
      "err",
      start
    );
  }

  function EmptyTasks() {
    return (
      <Loaded>
        <p>Nenhuma tarefa em execução</p>
      </Loaded>
    );
  }

  function formatSize(bytes) {
    if (bytes < 1024) {
      return `${bytes} Bytes`;
    } else if (bytes < Math.pow(1024, 2)) {
      return `${(bytes / 1024).toFixed(2)} KB`;
    } else if (bytes < Math.pow(1024, 3)) {
      return `${(bytes / Math.pow(1024, 2)).toFixed(2)} MB`;
    } else {
      return `${(bytes / Math.pow(1024, 3)).toFixed(2)} GB`;
    }
  }

  function LoadingContainer() {
    return (
      <>
        {Array.from({ length: 6 }, (_, i) => (
          <div className="item" key={i}>
            <ContentLoader speed={2} width={400} height={80}>
              <rect x="15" y="25" rx="5" ry="5" width="35" height="35" />
              <rect x="65" y="32" rx="5" ry="5" width="150" height="8" />
              <rect x="65" y="47" rx="5" ry="5" width="220" height="8" />
            </ContentLoader>
          </div>
        ))}
      </>
    );
  }

  return (
    <Container>
      <Status style={{ display: "none" }}>
        <div className="header">
          <p>status</p>
        </div>
        <div className="display">
          {itemStatus.map((obj) => (
            <div className="item">
              {obj.icon}
              <p>{` ${obj.text}: `}</p>
            </div>
          ))}
        </div>
      </Status>
      <Items>
        <div className="header">
          <p>downloads</p>
        </div>
        {!loading && tasks.length !== 0 ? (
          tasks.map((obj) => (
            <div className={`item ${obj.status}`}>
              <div className="check">
                {obj.status === "doing" && Loading(40)}
                {obj.status === "done" && (
                  <IoCheckmarkOutline size={15} color="green" />
                )}
                {obj.status === "todo" && (
                  <IoCubeOutline size={15} color="rgba(0, 150, 100, 0.8)" />
                )}
              </div>
              <div className="content">
                <div className="text">
                  <p>{findStage(obj)}</p>
                  <p className="number">
                    {obj.status !== "done"
                      ? `${obj.progress} / ${obj.goal}`
                      : obj.goal}
                  </p>
                </div>
                {obj.status === "doing" && (
                  <div className="progress-content">
                    <div className="progressbar">
                      <div
                        className={`progress ${obj.status}`}
                        style={{
                          width: `${percent(obj.goal, obj.progress)}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <Items>{loading ? <LoadingContainer /> : <EmptyTasks />}</Items>
        )}
      </Items>
      {tasks.length !== 0 && (
        <Reset>
          <div className="btn" onClick={handleReset}>
            <p>Cancelar</p>
          </div>
        </Reset>
      )}
    </Container>
  );
}

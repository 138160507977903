import React, { useState, useEffect } from "react";
import { Container, Header, InfoContainer } from "./style";
import axios from "axios";

import ContentLoader from "react-content-loader";

import { GrUpdate } from "react-icons/gr";
import { BiFileFind, BiFileBlank, BiFolderPlus, BiFile } from "react-icons/bi";

import { useProjetos } from "../../../../../../hooks/projetos";
import { useDownload } from "../../../../../../hooks/download";

export default function CancelInfos({ setOpen, setProgress }) {
  const [download, setDownload] = useState(0);

  const { projeto } = useProjetos();
  const { infos, setInfos } = useDownload();

  const uri = process.env.REACT_APP_API_URI;

  function randomInt(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  function formatBytes(a, b = 2) {
    if (!+a) return "0 Bytes";
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
      ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
    }`;
  }

  async function downloadCanceladas() {
    await axios({
      url: `${uri}/pro/download-canceladas/${projeto}`,
      method: "GET",
      responseType: "blob",
      onDownloadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setDownload(percentCompleted); // Atualiza o estado de progresso
      },
    })
      .then((response) => {
        // Restante do código para processar o download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Relatorio_nfes.xlsx"); // Nome do arquivo
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error(error));
  }

  function update() {
    setOpen(false);
    setInfos();
    setProgress(0);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${uri}/pro/infos-canceladas/${projeto}`
        );
        setInfos(response.data.info);
        console.log("infos", infos);
      } catch (error) {
        console.error("Erro ao buscar informações: ", error);
      }
    };

    fetchData();

    // Limpeza do useEffect
    return () => {
      // Se precisar fazer alguma limpeza, pode ser feita aqui
    };
  }, []);

  function Loader() {
    return (
      <div className="infos">
        {Array.from({ length: 4 }, (_, i) => (
          <div className="info-item">
            <ContentLoader key={i} speed={2} width={600} height={58}>
              <rect
                x="10"
                y="22"
                rx="5"
                ry="5"
                width={randomInt(180, 210)}
                height="15"
              />
            </ContentLoader>
          </div>
        ))}
      </div>
    );
  }

  function Loader2() {
    return (
      <div className="files-container">
        <div className="download-btn">
          <div className="btn loading">
            <h2 style={{ opacity: "0.4", cursor: "auto", userSelect: "none" }}>
              DOWNLOAD
            </h2>
          </div>
          <div className="refresh">
            <ContentLoader speed={2} width={600} height={58}>
              <rect x="5" y="18" rx="5" ry="5" width="22" height="20" />
            </ContentLoader>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Container>
      <InfoContainer>
        {/* {infos !== undefined &&
          infos.task !== undefined &&
          infos.task !== null &&
          infos.task.options !== undefined && (
            <div className="progress-info">
              <div className="text">
                <p>
                  {infos.task.options.stage === "search"
                    ? "Procurando arquivos filtrados"
                    : "Comprimindo os arquivos encontrados"}
                </p>
                <p>{`${infos.task.progress} / 100`}</p>
              </div>
              <div className="progress-content">
                <div className="progress">
                  <div
                    className="bar"
                    style={{ width: `${infos.task.progress}%` }}
                  ></div>
                </div>
              </div>
            </div>
          )} */}
        {infos !== null && infos !== undefined ? (
          <div className="infos">
            <>
              <div className="info-item">
                <div className="logo">
                  <BiFolderPlus />
                </div>
                <div className="text-content">
                  <p>NFE's no Arquivo .txt:</p>
                  <p>{infos.nfes_txt.toLocaleString()}</p>
                </div>
              </div>
              <div className="info-item">
                <div className="logo">
                  <BiFileFind />
                </div>
                <div className="text-content">
                  <p>NFE's no Report:</p>
                  <p>{infos.nfes_report.toLocaleString()}</p>
                </div>
              </div>
            </>

            <div className="info-item">
              <div className="logo">
                <BiFileBlank />
              </div>
              <div className="text-content">
                <p>NFE's Canceladas:</p>
                <p>{infos.nfes_canceladas.toLocaleString()}</p>
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
        {infos !== undefined ? (
          <div className="files-container">
            {download > 0 && download < 100 && (
              <div className="progress-bar">
                <div className="load" style={{ width: `${download}%` }}></div>
              </div>
            )}
            <div className="download-btn">
              <div className="btn" onClick={downloadCanceladas}>
                <h2>DOWNLOAD</h2>
              </div>
              {infos !== null && (
                <div className="refresh" onClick={update}>
                  <GrUpdate />
                </div>
              )}
            </div>
          </div>
        ) : (
          <Loader2 />
        )}
      </InfoContainer>
    </Container>
  );
}
